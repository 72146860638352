<template>
	<div>
		<div :class="['fillin_banner',status==2?'payPage':'']">
			<div>
				<p v-if="status==2">{{this.$route.query.type==7?"诊断报告":'估值报告'}}</p>
				<p v-else>填写页面</p>
				<div v-if="status==1">
					<div class="uploading" style="display: inline-block;">
						<span class="fillinUpFile" data-type="2" data-stage="2" @click="upfile">
							{{this.$route.query.type==7?'上传诊断数据':'上传估值数据'}}
						</span>
					</div>
					<a v-if="this.$route.query.type==2" class="exam" href="/data/专精特新企业价值量化.xlsx">下载估值模板</a>
					<a v-else-if="this.$route.query.type==7" class="exam" href="/data/专精特新企业价值诊断.xlsx">下载诊断模板</a>
					<a v-else-if="this.$route.query.type==5" class="exam"
						:href="'/data/专精特新企业价值追踪'+this.$route.query.year+'.xlsx'">下载估值模板</a>
				</div>
			</div>
		</div>
		<Modal v-model="upStatus" class-name="vertical-center-modal upFile" :mask-closable="false">
			<p slot="header">
				<span class="tipLeft">上传文件</span>
			</p>
			<div class="file-container">
				<span class="up-container">
					<input type="file" name="file" ref="fileInput" @change="fillinUpFile(1)">
					<font>选择上传文件</font>
				</span>
				<p class="up-file">支持excel格式文件，填写财务数据时，<span class="red">请勿使用剪切（Ctrl+X）功能</span>，会导致表格公式出现错误。</p>
			</div>
			<div slot="footer"></div>
		</Modal>
		<Modal v-model="fileOK" class-name="vertical-center-modal upFile" :mask-closable="false">
			<p slot="header">
				<span class="tipLeft">上传</span>
			</p>
			<div class="file-container">
				<img src="../../assets/img/up-success@2x.png">
				<p>报表上传成功</p>
			</div>
			<div slot="footer" class="ivu-sure" @click="submitDialog">确定</div>
		</Modal>
		<Modal v-model="fileNo" class-name="vertical-center-modal upFile" :mask-closable="false">
			<p slot="header">
				<span class="tipLeft">上传</span>
			</p>
			<div class="file-container">
				<img src="../../assets/img/remind-01@2x.png">
				<p>{{fileOKMessage}}</p>
				<span class="up-container">
					<input type="file" name="file" ref="reupload" @change="fillinUpFile(2)">
					<font>重新上传</font>
				</span>
			</div>
			<div slot="footer"></div>
		</Modal>
	</div>
</template>

<script>
	import qs from 'qs';
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		data() {
			return {
				status: 1, // 1:填写页面   2：估值报告
				upStatus: false,
				fileOK: false,
				fileNo: false,
				fileOKMessage: '',
				reportId: ''
			}
		},
		watch: {
			$route(to, from) {
				location.reload(true)
			}
		},
		mounted: function() {
			if (this.$route.path == '/submitReport') {
				this.status = 2;
			}
		},
		methods: {
			upfile: function() {
				this.upStatus = true;
			},
			fillinUpFile: function(i) {
				var this_ = this;
				if (i == 1) {
					// console.log('第一次上传')
					var fileInput = this.$refs.fileInput;
					var file = fileInput.files[0];
				} else if (i == 2) {
					// console.log('重新上传')
					var fileInput = this.$refs.reupload;
					var file = fileInput.files[0];
				}

				var formData = new FormData()
				formData.append('file', file);
				formData.append("stockCode", '0000');
				formData.append('reportId', this.$route.query.reportId ? this.$route.query.reportId : '');
				formData.append("stage", sessionStorage.stage);
				formData.append("reportType", sessionStorage.reportType);
				formData.append("versions", sessionStorage.versions ? sessionStorage.versions : 1);

				if (this.$route.query.type == 2) {
					var url = this.domain.valuationProject + 'professionUpload/uploadFile';
				} else if (this.$route.query.type == 7) {
					var url = this.domain.diagnoseProject + 'professionUpload/uploadDiagnoseFile3';
				} else if (this.$route.query.type == 5) {
					formData.append("year", this.$route.query.year);
					var url = this.domain.traceProject + 'professionUpload/uploadFile';
				}
				this.$Spin.show(); //全局加载中

				// setTimeout(function() {
				// 	console.log(99)
				// 	this_.fileNo = true
				// 	this_.upStatus = false
				// 	this_.$Spin.hide();
				// }, 3000)


				this.$post(url, formData)
					.then((res) => {
						this_.$Spin.hide();
						this_.upStatus = false;
						console.log(res.data)
						console.log(88)
						if (!res.data.content.message) { // 上传报表成功
							this_.fileOK = true;
							this_.fileNo = false;
							this.reportId = res.data.content.reportId;
						} else {
							this_.fileNo = true;
							this_.fileOKMessage = res.data.content.message;
							this.$refs.fileInput.value = null;
							this.$refs.reupload.value = null;
						}

					})


			},
			submitDialog: function() {
				this.fileOK = false;
				if (!this.$route.query.reportId) {
					if (window.location.href.indexOf('?') != -1) {
						var url = window.location.href + '&reportId=' + this.reportId;
						window.location.href = url
					} else {
						var url = window.location.href + '?reportId=' + this.reportId;
						window.location.href = url
					}
				} else {
					location.reload(true)
				}
			}

		}
	}
</script>

<style scoped>
	.fillin_banner:not(.payPage) {
		width: 100%;
		height: 380px;
		background: url(../../assets/img/fillin/fillinBanner.jpg) center center no-repeat;
		background-size: cover;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		background-size: cover;
	}

	.payPage {
		width: 100%;
		height: 380px;
		background: url(../../assets/img/fillin/payBanner.jpg) center center no-repeat;
		background-size: cover;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.fillin_banner>div,
	.payPage>div {
		width: 1200px;
		margin: 0 auto;
	}

	.fillin_banner>div p,
	.payPage>div p {
		font-size: 41px;
		font-family: 'Microsoft YaHei';
		font-weight: bold;
		color: #282828;
	}

	.fillin_banner>div>p::after,
	.fillin_banner.flowInformation>div p::after {
		content: '';
		width: 56px;
		height: 4px;
		background: #282828;
		margin-top: 29px;
		display: block;
	}

	.fillin_banner>div .exam,
	.fillin_banner>div span {
		width: 140px;
		height: 40px;
		line-height: 40px;
		font-family: 'Microsoft YaHei';
		font-size: 14px;
		font-weight: 400;
		text-align: center;
		border: 1px solid #004DE1;
		margin-top: 50px;
		cursor: pointer;
	}

	.fillin_banner>div span {
		background: #004DE1;
		color: #FFFFFF;
		margin-right: 40px;
	}

	.fillin_banner>div .exam {
		color: #004DE1;
	}

	@media screen and (max-width: 750px) {
		.fillin_banner {
			display: none !important;
		}
	}
</style>
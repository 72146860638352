<template>
        <div :id="id"></div>
</template>
<script>
import * as echarts from 'echarts/lib/echarts.js'
import formatCurrency from '../../assets/js/numberToCurrency'


export default ({
    props: [
        'chartData', 'id', 'chartName', 'chartValue'
    ],
    data() {
        return {
        }
    },
    mounted(){
        this.initChart()
    },
    methods: {
        initChart: function(){
                this.chart = echarts.init(document.getElementById(this.id));
                this.setOptions(this.id, this.chartValue, this.chartName, this.chartData)
        },
        setOptions: function(id, data, name, data_){
            var axisArrOne = [];
            var axisArrTwo = [];
            var dataListOne = [];
            var dataListTwo = [];
            for (var i = 0; i < data_.length; i++) {
                axisArrOne.unshift(data_[i].name);
                axisArrTwo.push(data_[i].name);
                dataListOne.unshift(data_[i].value);
                dataListTwo.push(data_[i].value);
            }
            var option = {
                // grid: { left: '33%', right: '5%', bottom: '8%', containLabel: true,show:false },
                series: [

                    {
                        radius: "90%",
                        type: 'gauge',
                        center: ["50%", "60%"],
                        startAngle: 200,
                        endAngle: -20,
                        min: 0,
                        max: 1,
                        splitNumber: 10,
                        itemStyle: {
                            color: '#a1bfff'
                        },
                        progress: {
                            show: true,
                            width: 16
                        },

                        pointer: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                width: 30
                            }
                        },
                        axisTick: {
                            show: false,
                            distance: -45,
                            splitNumber: 5,
                            lineStyle: {
                                width: 2,
                                color: '#999'
                            }
                        },
                        splitLine: {
                            show: false,
                            distance: -52,
                            length: 14,
                            lineStyle: {
                                width: 3,
                                color: '#999'
                            }
                        },
                        axisLabel: {
                            show: false,
                            distance: -20,
                            color: '#999',
                            fontSize: 20
                        },
                        anchor: {
                            show: false
                        },
                        title: {
                            show: false
                        },
                        detail: {
                            show: false,
                            valueAnimation: false,
                            width: '60%',
                            lineHeight: 40,
                            height: '15%',
                            borderRadius: 8,
                            offsetCenter: [0, '-15%'],
                            fontSize: 60,
                            fontWeight: 'bolder',
                            formatter: '{value}',
                            color: '#000'
                        },
                        data: [{
                            value: 1
                        }]
                    },

                    {
                        radius: "90%",
                        type: 'gauge',
                        center: ["50%", "60%"],
                        startAngle: 200,
                        endAngle: -20,
                        min: 0,
                        max: 100,
                        itemStyle: {
                            color: '#4B84FF',
                        },
                        progress: {
                            show: true,
                            width: 5,
                        },

                        pointer: {
                            show: false
                        },
                        // axisLine: {
                        //     show: false
                        // },
                        // axisTick: {
                        //     show: false
                        // },
                        // splitLine: {
                        //     show: false
                        // },
                        axisLine: {
                            lineStyle: {
                                width: 10
                            }
                        },
                        axisTick: {
                            distance: -20,
                            splitNumber: 5,
                            lineStyle: {
                                width: 1,
                                color: '#ccc'
                            }
                        },
                        splitLine: {
                            // show:false,
                            distance: -25,
                            length: 10,
                            lineStyle: {
                                width: 2,
                                color: '#ccc'
                            }
                        },
                        axisLabel: {
                            // show:false,
                            distance: -20,
                            color: '#ccc',
                            fontSize: 14
                        },
                        title: {
                            offsetCenter: ["0", '30%'],
                            fontSize: 20
                        },
                        detail: {
                            valueAnimation: true,
                            width: '60%',
                            lineHeight: 40,
                            height: '15%',
                            borderRadius: 8,
                            offsetCenter: [0, '-15%'],
                            fontSize: 30,
                            fontWeight: 'bolder',
                            formatter: function(p) {
                                return formatCurrency.formateNum(p.toString(),2);
                            },
                            color: '#4B84FF'
                        },
                        data: [{
                            value: data,
                            name: name + "得分",
                        }]

                    }
                ],
            };
			var myChart = echarts.getInstanceByDom(document.getElementById(id));
			if (myChart == undefined) {
				myChart = echarts.init(document.getElementById(id));
			}
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        },
    }
})
</script>
<style scoped>
</style>

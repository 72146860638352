<template>
        <div :id="id"></div>
</template>
<script>
import * as echarts from 'echarts/lib/echarts.js'
import formatCurrency from '../../assets/js/numberToCurrency'


export default ({
    props: [
        'chartData','id', 'unit'
    ],
    data() {
        return {
        }
    },
    mounted(){
        if(this.chartData){
            this.initChart()
        };
    },
    methods: {
        initChart: function(){
                this.chart = echarts.init(document.getElementById(this.id));
                this.setOptions(this.id, this.chartData)
        },
        setOptions: function(id, data){
            var cutoffrule = echarts.init(document.getElementById(id));
            var option = {
                legend: {
                    data: data.bottomtitle,
                    left: 'center',
                    bottom: 10,
                    itemWidth: 25,
                    itemHeight: 10
                },
                color: ["#0578FB", "#73E6E7", "#1B5BE1"],
                tooltip: {},
                xAxis: {
                    type: 'category',
                    data: data.xdata,
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        interval: 0,
                        lineStyle: {
                            type: 'dotted',
                            color: '#000'
                        }
                    },
                    axisLabel: {
                        interval: 0
                    }
                },
                yAxis: {
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: true
                    },
                    axisTick: {
                        show: true
                    }
                },
                series: data.data
            };
            cutoffrule.setOption(option);
        }
    }
})
</script>
<style scoped>
</style>

<template>
	<div>
		<Header />
		<navigation v-if="this.$route.query.reportId" />
		<div id="main">
			<div class="comprehensiveMark" v-if="!this.$route.query.reportId">
				<p class="maskTitle">
					<img class="mask_guide" src="../../assets/img/guzhi/mask_guide.png" alt="估值引导-心流">
					<span>{{this.$route.query.type==7?'企业价值诊断指引':'估值引导'}}</span>
				</p>
				<div :style="{opacity:this.$route.query.type==7?'0':'1'}">
					<p class="description">
						股权估值反映企业发展潜力和未来盈利能力，已经广泛应用于企业股权融资、股权并购及企业价值分析和投资分析中，股权估值正在成为衡量一个企业成功与否和投资价值高低的重要指标。</p>
					<p class="perfect">请点击下一步填写估值信息。</p>
				</div>
				<span class="goTo" :style="{top:this.$route.query.type==7?'170px':'0'}" @click="nextStep">
					<span>下一步</span>
					<img class="mouse_remind" src="../../assets/img/guzhi/mouse_remind.png" alt="下一步-心流">
				</span>
				<div class="inforFooter" :style="{'margin-top':this.$route.query.type==7?'-200px':'0'}">
					<p class="line"></p>
					<ul>
						<li>
							<label>
								<span>1. 基本信息填写</span>
								<img src="../../assets/img/guzhi/baseWhite.png" alt="基本信息填写-心流">
							</label>
							<p>需填写与企业相关的真实基本信息</p>
						</li>
						<li>
							<label>
								<span>2. 软性指标</span>
								<img src="../../assets/img/guzhi/softnessWhite.png" alt="软性指标-心流">
							</label>
							<p>需根据企业自身情况选择每项指标相对应的问题答案</p>
						</li>
						<li>
							<label>
								<span>3. 财务指标</span>
								<img src="../../assets/img/guzhi/financeWhite.png" alt="财务指标-心流">
							</label>
							<p>需根据企业财务状况完成相关财务信息填写</p>
						</li>
						<li>
							<label>
								<span>4. {{this.$route.query.type==7?'诊断报告':'估值报告'}}</span>
								<img src="../../assets/img/guzhi/reportWhite.png" alt="估值报告-心流">
							</label>
							<p>心流根据以上信息自动生成对企业的价值{{this.$route.query.type==7?'诊断报告':'评估报告'}}</p>
						</li>
					</ul>
				</div>
			</div>
			<div class="information" v-if="this.$route.query.reportId">
				<p class="title">
					<img src="../../assets/img/guzhi/comprehensiveSele.png" alt="心流">
					<span>企业综合展示</span>
				</p>
				<div class="synthesisScore" v-if="this.$route.query.type==7">
					<p class="companyName">综合得分</p>
					<div class="synthesisScorePic">
						<p>0.00</p>
					</div>
				</div>
				<div class="enterpriseInformation">
					<p class="tagTitle">
						<img src="../../assets/img/guzhi/tagging.png" alt="企业综合展示_企业信息-心流">
						<span>企业信息</span>
					</p>
					<ul :style="{width:baseInfo.companyName?'55%':'100%'}">
						<li>
							<span class="firstSpan">企业名称</span>
							<span class="secondSpan"
								id="companyName">{{baseInfo.companyName?baseInfo.companyName:''}}</span>
							<span class="thirdSpan">成立日期</span>
							<span class="fourSpan"
								id="establishDate">{{baseInfo.establishDate?baseInfo.establishDate:''}}</span>
						</li>
						<li>
							<span class="firstSpan">邮箱</span>
							<span class="secondSpan" id="email">{{baseInfo.email?baseInfo.email:''}}</span>
							<span class="thirdSpan">法定代表人</span>
							<span class="fourSpan"
								id="legalPeople">{{baseInfo.legalPeople?baseInfo.legalPeople:''}}</span>
						</li>
						<li>
							<span class="firstSpan">统一社会信用代码</span>
							<span class="secondSpan"
								id="creditCode">{{baseInfo.creditCode?baseInfo.creditCode:''}}</span>
							<span class="thirdSpan">所在地区</span>
							<span class="fourSpan" id="area">{{baseInfo.area?baseInfo.area:''}}</span>
						</li>
						<li>
							<span class="firstSpan">注册资本</span>
							<span class="secondSpan"
								id="regCapital">{{baseInfo.regCapital?baseInfo.regCapital+'万元':''}}</span>
							<span class="thirdSpan">所处阶段</span>
							<span class="fourSpan" id="period">{{baseInfo.period?baseInfo.period:''}}</span>
						</li>
						<li>
							<span class="firstSpan">所属行业</span>
							<span class="secondSpan"
								id="industryChildName">{{baseInfo.industryChildName?baseInfo.industryChildName:''}}</span>
							<span class="thirdSpan">出让股权</span>
							<span class="fourSpan" id="ratio">{{baseInfo.ratio?baseInfo.ratio+'%':''}}</span>
						</li>
						<li>
							<span class="firstSpan">融资额度</span>
							<span class="secondSpan" id="finance">{{baseInfo.finance?baseInfo.finance+'万元':''}}</span>
						</li>
						<li>
							<span class="firstSpan">主营业务</span>
							<label class="businessDescription"
								id="bussinessDesc">{{baseInfo.bussinessDesc?baseInfo.bussinessDesc:''}}</label>
						</li>
						<li>
							<span class="firstSpan">企业价值(自评)</span>
							<span class="secondSpan"
								id="selfValue">{{baseInfo.selfValue?baseInfo.selfValue+'万元':''}}</span>
						</li>
					</ul>
					<div class="borderDiv" v-if="baseInfo.companyName"></div>
					<div id="ownershipStructure" v-if="baseInfo.companyName"></div>
				</div>
				<div class="state">
					<div class="operatState">
						<p class="tagTitle">
							<img src="../../assets/img/guzhi/tagging.png" alt="企业综合展示_经营状况-心流">
							<span>经营状况</span>
						</p>
						<div id="operatState" style="width: 100%;height:300px;">
							<div class="professionalUnpaidNone">
								<img class="imgDefault" src="../../assets/img/specialization/operaStateNo.png"
									alt="线上估值-心流">
								<div class="payLook">
									<span class="spanBtn" @click="payCheck">支付查看</span>
								</div>
							</div>
						</div>
					</div>
					<div class="manageState">
						<p class="tagTitle">
							<img src="../../assets/img/guzhi/tagging.png" alt="线上估值_企业综合展示_财务状况-心流">
							<span>财务状况</span>
						</p>
						<div id="manageState" style="width: 100%;height:300px;">
							<div class="professionalUnpaidNone professionalUnpaidBlock">
								<img class="imgDefault" src="../../assets/img/guzhi/manageStateNo.png" alt="线上估值-心流">
								<div class="payLook">
									<span class="spanBtn" @click="payCheck">支付查看</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="valuationAnalysis" v-if="this.$route.query.type!=7">
					<p class="tagTitle">
						<img src="../../assets/img/guzhi/tagging.png" alt="企业综合展示_估值分析-心流">
						<span>估值分析</span>
					</p>
					<div>
						<div class="fastUnpaid professionalUnpaidBlock">
							<img class="imgDefault" src="../../assets/img/guzhi/professionalUnpaidNo.png" alt="线上估值-心流">
							<div class="payLook payLookLeft">
								<img src="../../assets/img/guzhi/lockBig.png" alt="线上估值-心流">
								<p>可查看相对估值结果</p>
								<span class="spanBtn" @click="payCheck">支付查看</span>
							</div>
						</div>
					</div>
				</div>
				<div class="profitForecast" style="display: none;">
					<p class="tagTitle">
						<img src="../../assets/img/guzhi/tagging.png" alt="企业综合展示_估值分析-心流">
						<span>盈利预测</span>
					</p>
					<div>
						<div class="professionalUnpaidBlock">
							<img class="imgDefault" src="../../assets/img/guzhi/profitForecastNo2.png" alt="线上估值-心流">
							<ul>
								<li v-for="(item, index) in profitYearList">{{item}}年</li>
							</ul>
							<div class="payLook">
								<img src="../../assets/img/guzhi/lockBig.png" alt="线上估值-心流">
								<p>可查看公司未来盈利预测</p>
								<span class="spanBtn" @click="payCheck">支付查看</span>
							</div>
							<div class="labelName">
								<p style="color:#37c">
									<span style="background:#37c"></span> 营业收入
								</p>
								<p style="color:#f90">
									<span style="background:#f90"></span> 净利润
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="valueTrack">
					<div class="valueTracking" v-if="this.$route.query.type!=7">
						<p class="tagTitle">
							<img src="../../assets/img/guzhi/tagging.png" alt="企业综合展示_价值追踪-心流">
							<span>价值追踪</span>
						</p>
						<div>
							<div class="professionalUnpaidBlock">
								<img class="imgDefault" src="../../assets/img/guzhi/valueTrackingNo2.png" alt="线上估值-心流">
								<ul>
									<li v-for="(item, index) in yearlist">{{item}}年</li>
								</ul>
								<div class="payLook">
									<img src="../../assets/img/guzhi/lockBig.png" alt="线上估值-心流">
									<p>暂时无法查看</p>
								</div>
								<div class="labelName">
									<p style="color:#37c">
										<span style="background:#37c"></span> 企业估值
									</p>
									<p style="color:#f90">
										<span style="background:#f90"></span> 增长率
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="lookReport">
						<img src="../../assets/img/guzhi/reportBook.png" alt="线上估值-心流">
						<span @click="payCheck">{{this.$route.query.type==7?'查看诊断报告':'查看估值报告'}}</span>
						<p>查看企业详细{{this.$route.query.type==7?'诊断分析':'估值分析及价值诊断'}}<br>请下载完整{{this.$route.query.type==7?'诊断报告':'估值报告'}}
						</p>
					</div>
				</div>

			</div>


		</div>

		<Footer />
		<router-view />
	</div>
</template>

<script>
	import Header from '../header.vue';
	import Footer from '../footer.vue';
	import navigation from './navigation.vue';
	import qs from 'qs';
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';
	import * as echarts from 'echarts'

	export default {
		data() {
			return {
				yearlist: [],
				profitYearList: [],
				baseInfo: {},
				equityStructure: []
			}
		},
		components: {
			Header,
			Footer,
			navigation
		},
		mounted() {
			this.pageModify('false');
			if (this.$route.query.reportId) {
				this.getInfo();
				this.getYear();
			}

		},
		methods: {
			...mapMutations(['pageModify']),
			nextStep: function() {
				if (this.$route.query.reportId) {
					this.$router.push({
						path: '/basicInformation',
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type,
							reportId: this.$route.query.reportId,
						}
					})
				} else {
					this.$router.push({
						path: '/basicInformation',
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type
						}
					})
				}
			},
			getInfo: function() {
				this.$post('flow/generalInfoShow/qryInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : ''
					}))
					.then((res) => {
						console.log(res.data)
						var data = res.data.content;
						if (data.baseInfo) {
							this.baseInfo = data.baseInfo;
							this.$set(this.baseInfo, this.baseInfo, data.baseInfo)
							if (data.baseInfo.firstHolderRatio && !data.baseInfo.secHolderRatio && !data.baseInfo
								.thirdHolderRatio && !data.baseInfo.fourHolderRatio) {
								this.equityStructure = [{
									value: data.baseInfo.firstHolderRatio,
									name: '第一股东'
								}, {
									value: data.baseInfo.otherHolderRatio,
									name: '其他股东'
								}]
							} else if (data.baseInfo.firstHolderRatio && data.baseInfo.secHolderRatio && !data
								.baseInfo.thirdHolderRatio && !data.baseInfo.fourHolderRatio) {
								this.equityStructure = [{
									value: data.baseInfo.firstHolderRatio,
									name: '第一股东'
								}, {
									value: data.baseInfo.secHolderRatio,
									name: '第二股东'
								}, {
									value: data.baseInfo.otherHolderRatio,
									name: '其他股东'
								}]
							} else if (data.baseInfo.firstHolderRatio && data.baseInfo.secHolderRatio && data
								.baseInfo.thirdHolderRatio && !data.baseInfo.fourHolderRatio) {
								this.equityStructure = [{
									value: data.baseInfo.firstHolderRatio,
									name: '第一股东'
								}, {
									value: data.baseInfo.secHolderRatio,
									name: '第二股东'
								}, {
									value: data.baseInfo.thirdHolderRatio,
									name: '第三股东'
								}, {
									value: data.baseInfo.otherHolderRatio,
									name: '其他股东'
								}]
							} else if (data.baseInfo.firstHolderRatio && data.baseInfo.secHolderRatio && data
								.baseInfo.thirdHolderRatio && data.baseInfo.fourHolderRatio) {
								this.equityStructure = [{
									value: data.baseInfo.firstHolderRatio,
									name: '第一股东'
								}, {
									value: data.baseInfo.secHolderRatio,
									name: '第二股东'
								}, {
									value: data.baseInfo.thirdHolderRatio,
									name: '第三股东'
								}, {
									value: data.baseInfo.fourHolderRatio,
									name: '第四股东'
								}, {
									value: data.baseInfo.otherHolderRatio,
									name: '其他股东'
								}]
							} else if (data.baseInfo.firstHolderRatio && data.baseInfo.secHolderRatio && data
								.baseInfo.thirdHolderRatio && data.baseInfo.fourHolderRatio) {
								this.equityStructure = [{
									value: data.baseInfo.firstHolderRatio,
									name: '第一股东'
								}, {
									value: data.baseInfo.secHolderRatio,
									name: '第二股东'
								}, {
									value: data.baseInfo.thirdHolderRatio,
									name: '第三股东'
								}, {
									value: data.baseInfo.fourHolderRatio,
									name: '第四股东'
								}, {
									value: data.baseInfo.otherHolderRatio,
									name: '其他股东'
								}]
							}
							this.pie()

						}


					});
			},
			getYear: function() {
				var newDate = new Date().getFullYear();
				this.yearlist = [(newDate - 3), (newDate - 2), (newDate - 1), newDate];
				this.profitYearList = [(newDate - 3), (newDate - 2), (newDate - 1), newDate, (newDate + 1), (newDate +
					2), (newDate + 3), (newDate + 4)];
			},
			payCheck: function() {
				this.$router.push({
					path: '/submitReport',
					query: {
						stage: this.$route.query.stage,
						type: this.$route.query.type,
						reportId: this.$route.query.reportId
					}
				})
			},
			perfectFinancial: function() {
				this.$router.push({
					path: '/balanceSheet',
					query: {
						stage: this.$route.query.stage,
						type: this.$route.query.type,
						reportId: this.$route.query.reportId
					}
				})
			},
			pie: function() { //股权结构
				// 基于准备好的dom，初始化echarts实例
				var shareholderName = [];
				for (var i = 0; i < this.equityStructure.length; i++) {
					shareholderName.push(this.equityStructure[i].name)
				}
				let myChart = echarts.init(document.getElementById('ownershipStructure'))
				// 绘制图表
				myChart.setOption({
					tooltip: {
						trigger: 'item',
						formatter: "{b} : {d}%"
					},
					legend: {
						icon: 'roundRect',
						orient: 'horizontal',
						width: '300px',
						bottom: "10%",
						right: "0%",
						selectedMode: false,
						data: shareholderName
					},
					series: [{
						name: '访问来源',
						type: 'pie',
						radius: '45%',
						center: ['50%', '35%'],
						data: this.equityStructure,
						itemStyle: {
							emphasis: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}],
					color: ["#92d5f4", "#47b5e7", "#096894", "#054868", "#c3e3f2"]
				});
			}









		}


	}
</script>

<style scoped>
	* {
		box-sizing: content-box !important;
	}

	.comprehensiveMark {
		position: relative;
		width: 1200px;
		height: 800px;
		margin: 0 auto;
		padding-bottom: 50px;
		background: url(../../assets/img/guzhi/comprehensive_mask.png) 0 0 no-repeat;
		background-size: 100%;
		z-index: 90;
	}

	.comprehensiveMark .maskTitle {
		width: 64%;
		height: 34px;
		margin: 0 auto;
		margin-top: 60px;
		margin-left: 20%;
		padding-top: 60px;
		color: #282828;
		font-size: 24px;
		line-height: 34px;
	}

	.comprehensiveMark .maskTitle>img {
		float: left;
		display: inline-block;
		width: 28px;
		height: 32px;
		margin-left: 6px;
	}

	.comprehensiveMark .maskTitle>span {
		display: block;
		font-size: 24px;
		line-height: 34px;
	}

	.comprehensiveMark .description {
		width: 64%;
		margin: 60px auto 0;
		margin-left: 20%;
		color: #282828;
		font-size: 14px;
		line-height: 36px;
	}

	.comprehensiveMark .perfect {
		width: 64%;
		margin: 0 auto;
		margin-top: 30px;
		margin-left: 20%;
		color: #282828;
		font-size: 14px;
		line-height: 36px;
	}

	.comprehensiveMark .goTo {
		display: block;
		position: relative;
		width: 250px;
		height: 60px;
		text-align: center;
		line-height: 60px;
		border-radius: 30px;
		color: #FDFDFD;
		font-size: 18px;
		font-weight: bold;
		background: #4393FF;
		cursor: pointer;
		margin: 85px auto 0;
	}

	img.mouse_remind {
		position: absolute;
		right: 34px;
		top: 17px;
	}

	.comprehensiveMark .inforFooter {
		position: relative;
		height: 107px;
	}

	.comprehensiveMark .inforFooter .line {
		width: 53%;
		border-bottom: 1px dashed #282828;
		margin-top: 136px;
		margin-left: 22%;
		opacity: 0.8;
	}

	.comprehensiveMark ul {
		width: 70%;
		left: 20%;
		position: absolute;
		top: -35px;
	}

	.comprehensiveMark ul li {
		display: inline-block;
		position: relative;
		width: 24%;
		color: #FEFEFE;
	}

	.comprehensiveMark ul li label {
		position: relative;
		display: block;
		width: 68px;
		height: 68px;
		margin-left: 25px;
		border-radius: 50%;
		text-align: center;
	}

	.comprehensiveMark ul li label {
		background: #4393FF;
	}

	.comprehensiveMark ul li label span {
		width: 130px;
		position: absolute;
		top: -10px;
		left: 60px;
		color: #282828;
		font-size: 18px;
		text-align: left;
	}

	.comprehensiveMark ul li label img {
		padding-top: 12px;
	}

	.comprehensiveMark ul li p {
		width: 180px;
		text-align: center;
		font-size: 14px;
		color: #282828;
		position: absolute;
		left: 0;
		top: 90px;
		bottom: -60px;
	}

	.enterpriseInformation,
	.valuationAnalysis,
	.profitForecast {
		margin: 18px 18px 0 18px;
		background: #fff;
		-webkit-box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		-moz-box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		-ms-box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		-o-box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		box-sizing: content-box;
	}

	.tagTitle {
		margin-left: 40px;
		padding-top: 20px;
		font-size: 0;
		height: 18px;
	}

	.tagTitle img {
		vertical-align: middle;
		margin-right: 6px;
	}

	.tagTitle span {
		font-size: 16px;
		color: #282828;
		font-weight: bold;
	}

	.enterpriseInformation ul {
		display: inline-block;
		width: 100%;
		margin: 35px 0 0 4%;
		vertical-align: top;
	}

	.enterpriseInformation li {
		font-size: 0;
		margin-bottom: 20px;
	}

	.enterpriseInformation li span {
		font-size: 14px;
		color: #282828;
		vertical-align: top;
		margin-right: 2%;
	}

	.enterpriseInformation li .firstSpan {
		width: 22%;
	}

	.enterpriseInformation li .secondSpan {
		width: 32%;
	}

	.enterpriseInformation li .thirdSpan {
		width: 15%;
	}

	.enterpriseInformation li .fourSpan {
		width: 22%;
	}

	.businessDescription {
		display: inline-block;
		width: 72%;
		font-size: 14px;
	}

	.borderDiv {
		display: inline-block;
		border-left: 1px solid #E5E5E5;
		height: 253px;
	}

	.state {
		height: 388px;
		margin: 18px;
	}

	.state>div {
		display: inline-block;
		background: #fff;
		width: 49%;
		height: 387px;
		vertical-align: top;
		-webkit-box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		-moz-box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		-ms-box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		-o-box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
	}

	.state>div.operatState {
		margin-right: 2%;
	}

	#ownershipStructure {
		display: inline-block;
		width: 40%;
		height: 350px;
		vertical-align: top;
	}

	.professionalUnpaidNone,
	.professionalUnpaidBlock {
		position: relative;
		text-align: center;
		height: 100%;
	}

	.imgDefault {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
	}

	.payLook {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
	}

	.professionalUnpaidNone .payLook .spanBtn {
		width: 140px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		border-radius: 6px;
		background: #0478fc;
		color: #fff;
		font-size: 16px;
		cursor: pointer;
		vertical-align: top;
	}

	.valuationAnalysis {
		height: 466px;
	}

	.valuationAnalysis>div,
	.profitForecast>div {
		width: 800px;
		height: 400px;
		margin: 20px auto 0;
	}

	.payLook img {
		display: block;
		width: 62px;
		margin: 0 auto 22px;
	}

	.professionalUnpaidBlock .payLook p {
		text-align: center;
		color: #7DCBEF;
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 20px;
	}

	.professionalUnpaidBlock .payLook .spanBtn {
		width: 140px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		border-radius: 6px;
		background: #0478fc;
		color: #fff;
		font-size: 16px;
		cursor: pointer;
		vertical-align: top;
	}

	.payLook.payLookRight {
		left: 83%;
		top: 58%;
	}

	.payLook.payLookRight p {
		width: 84px;
		margin-left: 15px;
		text-align: center;
	}

	.payLook.payLookRight .spanBtn {
		width: 110px;
	}

	.valueTrack {
		margin: 18px 18px 30px 18px;
		height: 482px;
		font-size: 0;
		box-sizing: content-box;
	}

	.valueTrack .valueTracking {
		margin-right: 2%;
		display: inline-block;
		background: #fff;
		width: 64%;
		height: 480px;
		vertical-align: top;
		-webkit-box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		-moz-box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		-ms-box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		-o-box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
	}

	.valueTrack .valueTracking>div {
		width: 100%;
		height: 420px;
	}

	.valueTrack .valueTracking>div ul {
		position: absolute;
		width: 412px;
		height: 30px;
		bottom: 14px;
		left: 135px;
		display: flex;
		justify-content: space-between;
	}

	.valueTrack .valueTracking>div ul li {
		color: #666;
		font-size: 14px;
	}

	.labelName {
		width: 240px;
		height: 30px;
		display: flex;
		justify-content: space-between;
		position: absolute;
		bottom: -15px;
		left: 210px;
	}

	.labelName p {
		font-size: 14px;
	}

	.labelName p span {
		width: 30px;
		height: 14px;
		border-radius: 6px;
	}

	.lookReport {
		display: inline-block;
		background: #fff;
		width: 34%;
		height: 482px;
		vertical-align: top;
		/*margin-left: 2%;*/
		font-size: 16px;
		-webkit-box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		-moz-box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		-ms-box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		-o-box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
		box-shadow: 0 0 18px rgba(179, 179, 179, 0.28);
	}

	.lookReport img {
		display: block;
		width: 113px;
		height: 98px;
		margin: 109px auto 0;
	}

	.lookReport span {
		display: block;
		width: 200px;
		height: 50px;
		background: #0478fc;
		margin: 29px auto 0;
		border-radius: 10px;
		color: #fff;
		text-align: center;
		line-height: 50px;
		cursor: pointer;
	}

	.lookReport p {
		width: 240px;
		height: 60px;
		margin: 21px auto 0;
		color: #282828;
		text-align: center;
	}

	.profitForecast {
		height: 475px;
	}

	.profitForecast ul {
		position: absolute;
		width: 604px;
		height: 30px;
		bottom: 14px;
		left: 135px;
		display: flex;
		justify-content: space-between;
	}

	.profitForecast .labelName {
		width: 300px;
		height: 30px;
		display: flex;
		justify-content: space-between;
		position: absolute;
		bottom: -15px;
		left: 250px;
	}

	.profitForecast .labelName p {
		font-size: 14px;
	}

	.profitForecast .labelName p span {
		width: 40px;
		height: 3px;
		border-radius: 2px;
	}

	.synthesisScore {
		margin-top: 24px;
	}

	.synthesisScore .companyName {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: rgba(40, 40, 40, 1);
		text-align: center;
		margin: 61px 0 24px;
	}

	#companyScore {
		width: 342px;
		height: 312px;
		margin: 0 auto;
	}

	.indexScore {
		width: 70%;
		margin: 60px auto 54px;
		overflow: hidden;
	}

	.indexScore>li {
		float: left;
		width: 100px;
		margin-right: 69px;
	}

	.indexScore>li:last-child {
		margin-right: 0;
	}

	.indexScore p {
		font-size: 18px;
		font-family: MicrosoftYaHei;
		color: rgba(40, 40, 40, 1);
		line-height: 30px;
		text-align: center;
		margin-top: 28px;
	}

	.synthesisScorePic {
		background: url(../../assets/img/specialization/specializationZongdefen.jpg)no-repeat center center;
		width: 910px;
		height: 813px;
		background-size: cover;
		margin: 110px auto;
		overflow: hidden;
	}

	.synthesisScorePic p {
		width: 100%;
		text-align: center;
		font-size: 80px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #0478FC;
		line-height: 100%;
		margin-top: 296px;
	}

	.synthesisScore .scoreCont p {
		width: auto;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #060001;
		line-height: 28px;
	}

	.synthesisScore ul.ulActive li {
		width: 300px;
		margin-right: 52px;
	}
</style>

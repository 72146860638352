<template>
        <div :id="id"></div>
</template>
<script>
import * as echarts from 'echarts/lib/echarts.js'
import formatCurrency from '../../assets/js/numberToCurrency'


export default ({
    props: [
        'chartData','id', 'unit'
    ],
    data() {
        return {
            chartName:[],
            chartValue:[]
        }
    },
    mounted(){
        if(this.chartData){
            this.chartName = this.chartData.name;
            this.chartValue = this.chartData.value;
            this.initChart()
        }
    },
    methods: {
        initChart: function(){
            this.chart = echarts.init(document.getElementById(this.id));
            this.setOptions(this.id, this.chartName, this.chartValue)
        },
        setOptions: function(id, dataxAxis, datayAxis){
            var companyValue = datayAxis[0];
            var averageIndex;
            var fontSize = 14;
            for (var i = 0; i < dataxAxis.length; i++) {
                if (dataxAxis[i] == "行业均值") {
                    averageIndex = i
                }
            }
            var markLineValue = companyValue - datayAxis[averageIndex],
                labelObj = {},
                normalObj = {};
            switch (id) {
                case "liabilitiesRatio":
                    var titleText = "资产负债率";
                    var yAxisName = "%";
                    break;
                case "flowRation":
                    var titleText = "流动比率";
                    var yAxisName = "";
                    break;
                case "ebitMultiple":
                    var titleText = "EBIT利息保障倍数";
                    var yAxisName = "";
                    break;
                case "grossProfitRatio":
                    var titleText = "毛利率";
                    var yAxisName = "%";
                    break;
                case "netProfiltRatio":
                    var titleText = "净利润率";
                    var yAxisName = "%";
                    break;
                case "mainIncomeRatio":
                    var titleText = "主营业务收入增长率";
                    var yAxisName = "%";
                    break;
                case "netAssetRatio":
                    var titleText = "净资产收益率";
                    var yAxisName = "%";
                    break;
                case "receivablesTurnoverRatio":
                    var titleText = "应收账款周转率";
                    //			var yAxisName = "%";
                    break;
                case "inventoryTurnoverRatio":
                    var titleText = "存货周转率";
                    var yAxisName = "";
                    break;
            }
            var xAxis = [],
                yAxis = [],
                position;
            if (id == "grossProfitRatio" || id == "netProfiltRatio" || id == "inventoryTurnoverRatio" || id == "receivablesTurnoverRatio") { //x轴纵
                var xObj = {
                        axisLine: { show: true },
                        axisTick: { show: true },

                    },
                    yObj = {
                        axisLabel: {
                            textStyle: {
                                fontSize: fontSize
                            }
                        },
                        axisLine: { show: true }
                    },
                    splitLine = {},
                    axisTick = {},
                    axisLine = {
                        show: true
                    },
                    data = [],
                    axisOne = {},
                    axisTwo = {},
                    coordOne = [],
                    coordTwo = [],
                    nameTextStyle = {};
                xObj.type = "value";
                xObj.name = yAxisName;
                xObj.axisLine = axisLine;
                xObj.position = "top";
                xObj.splitLine = splitLine;
                splitLine.show = false;
                xObj.axisTick = {
                    show: true
                };
                xObj.nameTextStyle = nameTextStyle;
                nameTextStyle.lineHeight = 16;
                xAxis.push(xObj);

                yObj.type = "category";
                yObj.data = dataxAxis;
                yObj.inverse = true;
                axisTick.show = false;
                yObj.axisTick = axisTick;
                yAxis.push(yObj);

                position = "right";
                axisOne.xAxis = datayAxis[0];
                axisOne.symbolSize = [0, 0];
                axisTwo.xAxis = datayAxis[averageIndex];
                axisTwo.symbolSize = [0, 0];
                coordOne = [companyValue, 6];
                coordTwo = [datayAxis[averageIndex], 6];

            } else {
                var xObj = {},
                    yObj = {
                        axisLabel: {
                            textStyle: {
                                fontSize: fontSize
                            }
                        },
                        axisLine: { show: true }

                    },
                    splitLine = {},
                    axisTick = { alignWithLabel: true },
                    data = [],
                    axisOne = {},
                    axisTwo = {},
                    coordOne = [],
                    coordTwo = [],
                    axisLabel = { interval: 0 },
                    textStyle = { fontSize: fontSize };
                xObj.type = "category";
                xObj.data = dataxAxis;
                xObj.axisTick = axisTick;
                xObj.axisLabel = axisLabel;
                axisTick.show = false;
                axisLabel.formatter = formatCurrency.formatter;
                axisLabel.textStyle = textStyle;
                xAxis.push(xObj);

                yObj.type = "value";
                yObj.name = yAxisName;
                yObj.splitLine = splitLine;
                splitLine.show = false;
                yObj.axisTick = {
                    show: true
                }
                yAxis.push(yObj);

                position = "top";
                axisOne.yAxis = datayAxis[0];
                axisOne.symbolSize = [0, 0];
                axisTwo.yAxis = datayAxis[averageIndex];
                axisTwo.symbolSize = [0, 0];
                coordOne = [6, companyValue];
                coordTwo = [6, datayAxis[averageIndex]];
            }
            normalObj.show = true;
            normalObj.position = position;
            var textObj = {};
            textObj.color = "black";
            normalObj.textStyle = textObj;
            normalObj.formatter = '{c}';
            labelObj.normal = normalObj;
            var option = {
                title: {
                    show: true,
                    text: titleText,
                    textStyle: {
                        color: '#282828',
                        align: 'center',
                        fontSize: 16
                    },
                    left: 'center',
                    top: 'top'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: { // 控制图的大小，调整下面这些值就可以，
                    left: '2%',
                    right: '8%',
                    bottom: '10%',
                    top: '12%',
                    containLabel: true
                },
                xAxis: xAxis,
                yAxis: yAxis,

                series: [{
                    data: datayAxis,
                    type: 'bar',
                    barMaxWidth: 40,
                    markLine: {
                        lineStyle: {
                            color: '#282828',
                        },
                        label: {
                            show: false,
                        },
                        data: [
                            [{
                                    coord: coordOne,
                                    lineStyle: {
                                        color: '#282828',
                                        type: 'solid'
                                    },
                                    symbolSize: [0, 0],
                                },
                                {
                                    coord: coordTwo,
                                    lineStyle: {
                                        color: '#282828',
                                        type: 'solid'
                                    },
                                    // 箭头标识
                                    label: {
                                        show: true,
                                        position: 'middle',
                                        formatter: function(params) {
                                            var value = markLineValue.toFixed(2);
                                            return value;
                                        }
                                    },
                                },
                            ],

                        ],
                    },
                    // markPoint: {
                    //     symbol: 'pin',
                    //     data: [
                    //       {coord: ['Mon', '822'], name: '最小值'},
                    //       {type: 'max', name: '最大值'}
                    //     ]
                    // },
                    label: labelObj,
                    itemStyle: {
                        normal: {
                            color: function(params) {
                                var colorList = ['#0487FC', '#0271C1', '#38B6F9', '#C0DFF7', '#A1E9EF', '#0F58FE', 'rgba(0,0,0,0)'];
                                var colorList_ = ['#5FB3FF', '#5FB3FF', '#9DDDFF', '#D3ECFF', '#CBFAF9', '#087CFF', 'rgba(0,0,0,0)'];
                                if (params.dataIndex == 6) {
                                    return colorList_[params.dataIndex];
                                } else {
                                    return new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: colorList_[params.dataIndex]
                                    }, {
                                        offset: 1,
                                        color: colorList[params.dataIndex]
                                    }]);
                                }
                            },
                        },
                    }
                }, {
                    type: 'line',
                    markLine: {
                        symbolSize: [0, 0],
                        silent: true,
                        lineStyle: {
                            color: '#282828',
                        },
                        label: {
                            show: false
                        },
                        data: [
                            axisOne,
                            axisTwo,
                        ]
                    }
                }]
            };
            var myChart = echarts.init(document.getElementById(id));
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        }
    }
})
</script>
<style scoped>
</style>

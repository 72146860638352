<template>
        <div :id="id"></div>
</template>
<script>
import * as echarts from 'echarts/lib/echarts.js'
import formatCurrency from '../../assets/js/numberToCurrency'

export default ({
    props: [
        'chartData','id'
    ],
    data() {
        return {
        }
    },
    mounted(){
        this.initChart();
    },
    methods: {
        initChart: function(){
            this.chart = echarts.init(document.getElementById(this.id));
            this.setOptions(this.id, this.chartData, function(data) {
                if (data.value && data.value.toString().indexOf(",") == -1) {
                    data.value = formatCurrency.formateNum(data.value.toString(), 2);
                }
                return data.name + ":" + data.value + "%"
            })
        },
        setOptions: function(id, data, formatter){
            var option = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c} ({d}%)'
                },
                color: ["#3d98fd", "#017fff", "#0376f8", "#066adf", "#0259bb", "#0259bb"],
                series: [{
                    // name: '访问来源',
                    type: 'pie',
                    radius: "70%",
                    // roseType: 'radius',
                    center: ['50%', '50%'],
                    data: data.sort(function(a, b) { return a.value - b.value; }),
                    // hoverAnimation:false,
                    label: {
                        color: '#131313',
                        formatter: formatter,
                        fontSize: 14,
                        // lineHeight:"24px"
                    },
                    labelLine: {
                        lineStyle: {
                            color: '#131313',
                        },
                        smooth: 0,
                        length: 10,
                        length2: 30
                    },
                    animationType: 'scale',
                    animationEasing: 'elasticOut',
                    animationDelay: function(idx) {
                        return Math.random() * 200;
                    }
                }]
            };
            var myBarChart = echarts.init(document.getElementById(id));
            // 使用刚指定的配置项和数据显示图表。
            myBarChart.setOption(option);
        }
    }
})
</script>
<style scoped>
</style>

<template>
	<div id="">
		<div class="advice-one" @mouseover="mouseover" @mouseout="mouseout"> <img src="../assets/img/zixun.svg" v-if="zixunImg"> <img src="../assets/img/zixun2.svg" v-if="!zixunImg">
			<p>联系我们</p>
			<div class="advice-content">
				<div class="tel"> <img src="../assets/img/dianhua.svg">
					<p> <span style="color:#999999;letter-spacing: 1.2px;">24小时客服电话</span> <span
							style="color:#0478FC;">010-8444-6599</span> <span style="color:#0478FC;">17610076778</span>
					</p>
				</div> <img src="../assets/img/erweima.png"
					style="width:134px;height:134px;margin: 0 auto;display: block;">
				<p>扫码咨询 随时答疑</p>
			</div>
		</div>
		<div class="top advice-item" id="goTop" @click="goTop" v-if="top">
			<img src="../assets/img/top@2x.png">
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				top:false,
				zixunImg:true
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
		},
		methods: {
			handleScroll() {
				var h = document.documentElement.clientHeight;
				var scrollTop = document.documentElement.scrollTop; // 滚动条偏移量
				if(scrollTop>h){
					this.top=true;
				}else{
					this.top=false;
				}
			},
			goTop(){
				document.documentElement.scrollTop = document.body.scrollTop = 0;
			},
			mouseover(){
				this.zixunImg=false;
			},
			mouseout(){
				this.zixunImg=true;
			}
		}
	}
</script>

<style scoped>
	/* 咨询建议*/
	.advice-container {
		width: 50px;
		position: fixed;
		right: 0;
		top: 50%;
		margin-top: -250px;
		z-index: 98;
	}

	.advice-one {
		position: fixed;
		right: 10px;
		bottom: 250px;
		z-index: 1500;
		width: 42px;
		height: 130px;
		border: 1px solid #0478FC;
		background: #fff;
		cursor: pointer;
	}

	.advice-one:hover {
		background: #0478FC;
	}

	.advice-one:hover>p {
		color: #fff;
	}

	.advice-one>img {
		width: 24px;
		height: 20px;
		margin: 11px auto 0;
		display: block;
	}

	.advice-one>p {
		font-size: 14px;
		color: #0478FC;
		writing-mode: tb-rl;
		-webkit-writing-mode: tb-rl;
		-ms-writing-mode: tb-rl;
		margin: 7px auto 0;
		letter-spacing: 7px;
		text-align: center;
		position: relative;
		left: 50%;
		margin-left: -12px;
		height: 84px;
	}



	.advice-item {
		margin-bottom: 2px;
		overflow: hidden;
		cursor: pointer;
		display: block;
		position: fixed;
		right: 10px;
		bottom: 153px;
		z-index: 1000;
	}

	.advice>.advice-item:nth-child(2) {
		display: none;
	}

	.advice-item>img {
		display: block;
		width: 26px;
		height: 24px;
		margin: 12px auto 8px;
	}

	.advice-item>span {
		display: block;
		font-size: 14px;
		color: #FEFEFE;
		text-align: center;
	}

	.top {
		margin-top: 252px;
		width: 40px;
		height: 40px;
		background: #fff;
		border: 1px solid #0478FC;
	}

	.top>img {
		width: 18px;
		height: 23px;
		margin: 7.5px auto;
	}

	.advice-content {
		width: 213px;
		height: 290px;
		background: #FDFDFD;
		box-sizing: border-box;
		position: absolute;
		top: 0;
		right: 66px;
		box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08);
		background: #fff;
		display: none;
	}

	.advice-content>div {
		height: 18px;
	}

	.advice-content>div:first-of-type {
		margin-bottom: 61px;
	}

	.advice-content>div>img {
		width: 18px;
		height: 18px;
		margin-right: 10px;
		float: left;
	}

	.advice-content>div>span {
		height: 18px;
		line-height: 18px;
		font-size: 12px;
		color: #fff;
	}

	.advice-content>.tel {
		width: 149px;
		margin: 29px auto 0;
	}

	.advice-content>.tel img {
		width: 24px;
		height: 24px;
	}

	.advice-content>.tel>p {
		margin-left: 38px;
		font-size: 14px;
		line-height: 22px;
	}

	.advice-content>p:last-child {
		color: #999999;
		font-size: 14px;
		margin-top: 10px;
		text-align: center;
	}



	div.advice-one:hover .advice-content {
		display: block;
	}

	button.show-more {
		width: 150px;
		height: 50px;
	}

	a.show-more {
		width: 146px;
		text-align: center;
		height: 46px;
		line-height: 46px;
	}
	@media screen and (max-width: 750px) {
		.advice-one{
			display: none;
		}
	}
</style>

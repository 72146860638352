<template>
        <div :id="id" style="width:80%;height:410px;margin:20px auto;"></div>
</template>
<script>
import * as echarts from 'echarts/lib/echarts.js'
import formatCurrency from '../../assets/js/numberToCurrency'


export default ({
    props: [
        'chartData','id','year'
    ],
    data() {
        return {
            bottomtitle:[],  // legendtitle
            title:[],  // dataxAxis
            right: "",
            bluedata: [],  // bluedata
            totalOwnershipInterest: [], // greydata
            linedataone: [],  // linedataone
            linedatatwo: [],  // linedatatwo
            percent: ""
        }
    },
    mounted(){
        var that = this;
        if(that.chartData){
            switch (that.id) {
                case "assetLiabilityRatio" :  // 资产负债率
                    that.bottomtitle = ['负债合计', '所有者权益合计', '企业资产负债率', '行业资产负债率均值'];
                    that.title = that.year;
                    that.right = "20";
                    that.bluedata = that.chartData.totalLiabilities;
                    that.totalOwnershipInterest = that.chartData.totalOwnershipInterest;
                    that.linedataone = that.chartData.liabRatio;
                    that.linedatatwo = that.chartData.industryLiabRatio;
                    that.percent = "%";
                    break;
                case "liquidityRatio" :  // 流动比率
                    that.bottomtitle = ['流动资产合计', '流动负债合计', '流动比率', '行业毛利率均值 '];
                    that.title = that.year;
                    that.right = "20";
                    that.bluedata = that.chartData.totalCurrentAssets;
                    that.totalOwnershipInterest = that.chartData.totalNonLiabilities;
                    that.linedataone = that.chartData.flowRatio;
                    that.linedatatwo = that.chartData.industryFlowRatio;
                    that.percent = "";
                    break;
                case "EBIT" :  // EBIT 利息保障倍数
                    that.bottomtitle = ['EBIT', '利息费用', 'EBIT利息保障倍数', '行业EBIT利息保障 倍数均值'];
                    that.title = that.year;
                    that.right = "0";
                    that.bluedata = that.chartData.ebit;
                    that.totalOwnershipInterest = that.chartData.interestCost;
                    that.linedataone = that.chartData.ebitRatio;
                    that.linedatatwo = that.chartData.industryEBITRatio;
                    that.percent = "";
                    break;
                case "grossProfitRate" :  // 毛利率
                    that.bottomtitle = ['主营业务成本', '主营业务收入', '企业毛利率', '行业毛利率均值'];
                    that.title = that.year;
                    that.right = "20";
                    that.bluedata = that.chartData.cost;
                    that.totalOwnershipInterest = that.chartData.income;
                    that.linedataone = that.chartData.grossProfitRatio;
                    that.linedatatwo = that.chartData.industryGrossProfitRatio;
                    that.percent = "%";
                    break;
                case "netProfitRate" :  // 净利润率
                    that.bottomtitle = ['净利润', '主营业务收入', '企业净利润率', '行业净利润率均值'];
                    that.title = that.year;
                    that.right = "20";
                    that.bluedata = that.chartData.netProfit;
                    that.totalOwnershipInterest = that.chartData.income;
                    that.linedataone = that.chartData.netProfitRatio;
                    that.linedatatwo = that.chartData.industryNetProfitRatio;
                    that.percent = "%";
                    break;
                case "incomeAbiltyChart" :  // 主营业务收入增长率
                    that.bottomtitle = ['主营业务成本', '主营业务收入', '企业毛利率', '行业毛利率均值'];
                    that.title = that.year;
                    that.right = "20";
                    that.bluedata = that.chartData.cost;
                    that.totalOwnershipInterest = that.chartData.income;
                    that.linedataone = that.chartData.netProfitRatio;
                    that.linedatatwo = that.chartData.industryNetProfitRatio;
                    that.percent = "%";
                    break;
                case "netProfit" :  // 净资产收益率
                    that.bottomtitle = ['净利润', '平均净资产', '企业净资产收益率', '行业净资产收益率均值'];
                    that.title = that.year;
                    that.right = "20";
                    that.bluedata = that.chartData.netProfit;
                    that.totalOwnershipInterest = that.chartData.totalAsset;
                    that.linedataone = that.chartData.netAssetRatio;
                    that.linedatatwo = that.chartData.industryNetAssetRatio;
                    that.percent = "%";
                    break;
                case "receivables" :  // 应收账款周转率
                    that.bottomtitle = ['应收账款', '营业收入', '企业应收账款周转率', '行业应收账款周转率均值'];
                    that.title = that.year;
                    that.right = "20";
                    that.bluedata = that.chartData.receivables;
                    that.totalOwnershipInterest = that.chartData.income;
                    that.linedataone = that.chartData.receivableRatio;
                    that.linedatatwo = that.chartData.industryReceivableRatio;
                    that.percent = "";
                    break;
                case "inventoryTurnover" :  // 存货周转率
                    that.bottomtitle = ['营业成本', '平均存货', '企业存货周转率', '行业存货周转率均值'];
                    that.title = that.year;
                    that.right = "20";
                    that.bluedata = that.chartData.cost;
                    that.totalOwnershipInterest = that.chartData.inventory;
                    that.linedataone = that.chartData.inventoryRatio;
                    that.linedatatwo = that.chartData.industryInventoryRatio;
                    that.percent = "";
                    break;
            }

            that.initChart()
        }
    },
    methods: {
        initChart: function(){
            this.chart = echarts.init(document.getElementById(this.id));
            this.setOptions(this.id, this.bottomtitle, this.title, this.right, this.bluedata, this.totalOwnershipInterest, this.linedataone, this.linedatatwo, this.percent)
        },
        setOptions: function(id, legendtitle, dataxAxis, right, bluedata, greydata, linedataone, linedatatwo, percent){
            var option = {
                legend: {
                    orient: 'vertical',
                    bottom: '50%',
                    right: right,
                    // left: left,
                    data: legendtitle,
                    itemHeight: 8
                },
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    axisPointer: { type: 'none' },
                    textStyle: { fontSize: 12, fontWeight: 500 },
                    formatter(params) {
                        // 只展示柱子对应的内容，把顶部底部的 tooltip 过滤掉
                        return params.reduce((pre, i) => {
                            if (i.componentSubType === "line") {
                                i.marker = i.marker.replace(/\[object Object\]/, i.color);
                                i.value = `<span style="flex: 1; text-align: right; margin-left: 16px;">${i.value}</span>`;
                                const current = `<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.seriesName} ${i.value}</div>`;
                                return `${pre}${current}`;
                            }
                            if (i.componentSubType === 'bar') {
                                i.marker = "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:" + i.color.colorStops[1].color + ";'></span>"
                                i.value = `<span style="flex: 1; text-align: right; margin-left: 16px;">${i.value}</span>`;
                                const current = `<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.seriesName} ${i.value}</div>`;
                                return `${pre}${current}`;
                            }
                            return pre;
                        }, '');
                    },
                },
                grid: { // 控制图的大小，调整下面这些值就可以，
                    left: '2%',
                    right: '25%',
                    bottom: '5%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: dataxAxis,
                    axisLine: { lineStyle: { color: '#000' } },
                    axisTick: { show: false },
                    axisLabel: { color: '#585D63', fontSize: '14', margin: 20 },
                },
                yAxis: [{
                    axisLine: {
                        lineStyle: { color: '#111111' },
                        show: true
                    },
                    name: '万元',
                    axisTick: { show: true },
                    axisLabel: {
                        textStyle: {
                            color: '#111',
                            fontSize: '14'
                        }
                    },
                    splitLine: { lineStyle: { color: '#E1E1E1' } },
                }, {
                    type: 'value',
                    name: percent,
                    axisLabel: {
                        textStyle: {
                            color: '#111',
                            fontSize: '14'
                        }
                    },
                    axisLine: {
                        lineStyle: { color: '#111111' },
                        show: true
                    },
                    splitLine: { show: false }
                }],
                series: [{
                        name: legendtitle[0],
                        type: 'bar',
                        barWidth: 34,
                        barCategoryGap: 12,
                        data: bluedata,
                        itemStyle: {　　　　　　　　　 // 柱体渐变色
                            normal: {
                                label: {
                                    textStyle: {
                                        color: "#616161"
                                    },
                                    show: true,
                                    position: 'top', //在上方显示
                                    formatter: function(p) {
                                        return formatCurrency.formateNum(p.value.toString(),2);
                                    }
                                },
                                color: function(params) { // 柱体渐变色
                                    var colorList = [
                                        ['#336DD2', '#6094EC']
                                    ]
                                    var index = params.dataIndex;
                                    if (params.dataIndex >= colorList.length) {
                                        index = params.dataIndex - colorList.length;
                                    }
                                    // console.log(params, colorList)
                                    return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                                        { offset: 0, color: '#336DD2' },
                                        { offset: 1, color: '#6094EC' }
                                    ])
                                }
                            }

                        }
                    },
                    {
                        name: legendtitle[0],
                        type: 'pictorialBar',
                        symbolSize: [34, 16],
                        // 这个属性很重要，直接决定了顶部跟柱子是否契合
                        z: 12,
                        itemStyle: { color: '#6094EC' },
                        // symbolOffset: [-22, -6],
                        // symbolPosition: 'end',
                        // data: bluedata
                        // 要给成武汉这两个柱子的值，这是圆柱顶部，值不给对高度会对不上
                        data: this.pictorialBar1(bluedata)
                    },
                    {
                        name: legendtitle[1],
                        type: 'bar',
                        barWidth: 34,
                        barCategoryGap: 12,
                        data: greydata,
                        itemStyle: {
                            normal: {
                                label: {
                                    textStyle: {
                                        color: "#616161"
                                    },
                                    show: true,
                                    position: 'top', //在上方显示
                                    formatter: function(p) {
                                        return formatCurrency.formateNum(p.value.toString(),2);
                                    }
                                },
                                color: function(params) { // 柱体渐变色
                                    var colorList = [
                                        ['#C0C9DB', '#DAE3F3']
                                    ]
                                    var index = params.dataIndex;
                                    if (params.dataIndex >= colorList.length) {
                                        index = params.dataIndex - colorList.length;
                                    }
                                    return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                                        { offset: 0, color: '#C0C9DB' },
                                        { offset: 1, color: '#DAE3F3' }
                                    ])
                                }
                            }

                        }
                    },
                    {
                        name: legendtitle[1],
                        type: 'pictorialBar',
                        // 这个属性很重要，直接决定了顶部跟柱子是否契合
                        z: 12,
                        itemStyle: { color: '#DAE3F3' },
                        symbolSize: [34, 16],
                        // symbolOffset: [22, -6],
                        // symbolPosition: 'end',
                        // data: greydata
                        data: this.pictorialBar2(greydata),
                    },
                    {
                        name: legendtitle[3],
                        type: 'line',
                        z: 12,
                        data: linedataone,
                        symbol: 'circle', //设定为实心点
                        symbolSize: 6, //设定实心点的大小
                        yAxisIndex: 1, //选择纵坐标的第几个
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                    fontSize: 14,
                                    position: [-50, 15], //在上方显示
                                    formatter: '{c}' //显示百分号
                                },
                                color: "#9A9A9A"
                            }
                        },
                        textStyle: {
                            shadowBlur: 0,
                            shadowColor: 'transparent'
                        }
                    },
                    {
                        name: legendtitle[2],
                        type: 'line',
                        z: 12,
                        data: linedatatwo,
                        symbol: 'circle', //设定为实心点
                        symbolSize: 6, //设定实心点的大小
                        yAxisIndex: 1, //选择纵坐标的第几个
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                    fontSize: 14,
                                    position: [-50, 15], //在上方显示
                                    formatter: '{c}' //显示百分号
                                },
                                color: "#EC6B1F"
                            }
                        },
                        textStyle: {
                            shadowBlur: 0,
                            shadowColor: 'transparent'
                        }
                    }

                ],
                // legend 对应的 color，实际上柱状图的颜色都是在 itemStyle 里定义的
                color: ['#6094EC', '#DAE3F3', '#9A9A9A', '#EC6B1F'],
            }
            var myChart = echarts.init(document.getElementById(id));
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        },
        pictorialBar1: function(data) {
            var arr = [];
            data.forEach(function(ele, i) {
                if (ele < 0) {
                    arr[i] = {
                        value: ele,
                        symbolOffset: [-20, 6],
                        symbolPosition: 'end'
                    }
                } else {
                    arr[i] = {
                        value: ele,
                        symbolOffset: [-20, -6],
                        symbolPosition: 'end'
                    }
                }
            });
            return arr
        },
        pictorialBar2: function(data) {
            var arr = [];
            data.forEach(function(ele, i) {
                if (ele < 0) {
                    arr[i] = {
                        value: ele,
                        symbolOffset: [-21, 6],
                        symbolPosition: 'end'
                    }
                } else {
                    arr[i] = {
                        value: ele,
                        symbolOffset: [21, -6],
                        symbolPosition: 'end'
                    }
                }
            });
            return arr
        }
    }
})
</script>
<style scoped>
</style>

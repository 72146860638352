<template>
        <div :id="id"></div>
</template>
<script>
import * as Highcharts from 'highcharts'
import * as Exporting from 'highcharts/modules/exporting'
import VariablePie from 'highcharts/modules/variable-pie'
// import Highcharts3D from 'highcharts/highcharts-3d'
Exporting(Highcharts)
VariablePie(Highcharts)
// Highcharts3D(Highcharts)
// import formatCurrency from '../../assets/js/numberToCurrency'

export default ({
    props: [
        'chartData','id'
    ],
    data() {
        return {
            
        }
    },
    mounted(){
        var arr = [100, 80, 60, 40, 20];
        var data_ = [];
        for (var i = 0; i < this.chartData.length; i++) {
            var obj = {
                name: this.chartData[i].name,
                y: this.chartData[i].y,
                z: arr[i]
            };
            data_.push(obj);
        }
        Highcharts.chart(this.id, {
            
            chart: {
                type: 'variablepie'
            },
            title: {
                text: ''
            },
            colors: ["#0478fc", "#775BFF", "#FF6B6B", "#FD865A", "#00BF8C"],
            tooltip: {
                enabled: false
            },
            credits: {
                enabled: false // 禁用版权信息
            },
            exporting: { //禁用导出模块  菜单按钮
                enabled: false
            },
            series: [{
                minPointSize: 120,

                innerSize: '20%',
                zMin: 0,
                name: 'countries',
                data: data_,
                dataLabels: {
                    enabled: true,
                    formatter: function() {
                        return this.point.options.name + ":" + this.point.options.y.toFixed(2) + "%"

                    },
                    style: { fontSize: '18px' }
                }
            }]
        });
    },
    methods: {
    }
})
</script>
<style scoped>
</style>

<template>
    <div>
        <!-- 心流注册协议 -->
        <div class="agreement registration">
            <div class="text_content">
                <h3>心流网注册协议</h3>
                <div class="content agreementContent">
                    <p>本服务协议（以下简称“本协议”）由您个人或您所代表的单位（以下统称“您”）和北京心流慧估科技有限公司（以下简称 “本公司”）签订，请仔细阅读本协议。</p>
                    <p>心流网服务（以下简称“本服务”）是指由本公司以及本公司关联方通过心流网网站（即www.flcccc.com等域名所指向的网站）、微信、APP或其他平台或软件、应用程序等（以下统称“心流网”）以线上或线下方式向用户提供的企业估值服务及其他相关服务，包括但不限于提供相关页面、内容、产品等服务。</p>
                    <p>本协议中，关联方包括关联公司和关联人以及通过心流网向您提供内容、产品或服务的第三方，在出现下列任一情况时，任何实体应被视为本协议相关一方的关联公司、关联人：</p>
                    <p>（1）直接或间接控制本协议一方、被本协议一方控制或与本协议一方同受其他主体控制的任何实体；</p>
                    <p>（2）注册资本、投票权、股权或决策权的百分之十（10%）或以上由本协议一方直接或间接拥有（反之亦然）；</p>
                    <p>（3）本协议任何一方通过合同约定、选任董事或其他方式指导、影响或制定该实体的决策、发展、管理和政策的方向（反之亦然）；</p>
                    <p>（4）由本协议一方的关联人担任董事、合伙人、股东、高管的任何实体；“关联人”是指自然人的近亲属，包括父母、配偶、亲兄弟姐妹及其配偶、成年子女及其配偶。</p>
                    <h4>一、总则</h4>
                    <p>1、您确认：在您注册成为心流网用户并接受本服务之前，您已充分阅读、理解并接受本协议的全部内容，一旦您选择“同意”并完成注册之后，进行购买或使用本服务（包括浏览网页，制作、下载或上传文件等各种使用心流网的情形，下同），即表示您同意遵循本协议之所有约定。</p>
                    <p>2、您同意，本公司有权随时对本协议内容进行单方面的变更，并以网站内容变更的方式予以公布，不再另行单独通知您；若在本协议内容修改后，您继续使用本服务的，表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的协议内容使用本服务；若您不同意变更后的协议内容，应立即停止使用本服务，否则您应自行承担因继续使用本服务而产生的所有后果。</p>
                    <p>3、您声明，在您同意接受本协议并注册成为心流网用户时，具有法律规定的完全民事权利能力和民事行为能力，您是能够独立承担民事责任的自然人、法人或其他组织；本协议内容不受您所属国家或地区法律的排斥。不具备前述条件的，您应立即终止注册或停止使用本服务，否则您应当自行承担因此产生的所有后果。</p>
                    <h4>二、本服务简介</h4>
                    <p>心流网向注册用户提供企业估值服务及其相关服务，心流网所提供的相关服务说明详见www.flcccc.com相关产品及服务介绍。</p>
                    <h4>三、心流网账户</h4>
                    <p>1、 注册相关</p>
                    <p>在使用本服务前，您必须先行注册，取得本公司提供给您的心流网账户（以下简称“心流网账户”），您同意并保证：</p>
                    <p>(1)您所填写的用户信息是真实、准确、完整、及时的，并且没有任何引人误解或者虚假的陈述，且保证心流网可以通过您所填写的联系方式与您取得联系。</p>
                    <p>(2)您应根据心流网对于相关服务的要求，及时提供相应的身份证明等资料，否则心流网有权拒绝向您提供相关服务。</p>
                    <p>(3)您承诺将及时更新您的用户信息并维持该等信息的有效性。</p>
                    <p>(4)如果您提供的资料或信息包含有不正确、不真实的信息，心流网有权保留取消您的用户资格并决定随时结束为您提供服务的权利。</p>
                    <p>2、 账户安全</p>
                    <p>您将对使用心流网账户及密码进行的一切操作及言论行为负完全的责任，您同意：</p>
                    <p>(1)本公司通过您的用户名和密码识别您的指示，请您妥善保管您的用户名和密码，对于因密码泄露所致的损失，由您自行承担。您保证不向其他任何个人或机构泄露心流网账户及密码，亦不将在心流网注册获得的账户借给他人使用，否则您应承担由此产生的全部责任，并与实际使用人承担连带责任。</p>
                    <p>(2)如您发现他人冒用或盗用您的账户及密码或任何其他未经您合法授权之情形时，应立即以有效方式通知本公司并要求本公司暂停相关服务。同时，您理解本公司对您的请求采取行动需要合理期限，在此之前，本公司对已执行的指令及所导致的损失不承担任何责任。</p>
                    <p>(3)为明确起见，如果您是企业用户，应当谨慎授权并妥善管理实际操作企业账户及密码的人员，防止该等人员泄露和许可他人使用贵企业信息和账户的情形，尤其需要避免该等人员离开企业之后可能出现的失去账户控制权的情形。本公司对您管理不善所导致的损失不承担任何责任。</p>
                    <h4>四、心流网服务使用守则</h4>
                    <p>为有效保障您使用本服务的合法权益，您理解并同意接受以下规则：</p>
                    <p>1、您在使用本服务过程中，本协议内容、网页上出现的关于相关操作的提示或本公司发送到您手机的信息（短信或电话等）内容是您使用本服务的相关规则，您使用本服务即表示您同意接受本服务的相关规则。</p>
                    <p>2、您了解并同意本公司无须征得您的同意可单方修改服务的相关规则，服务规则应以您使用服务时的页面内容为准，您同意并遵照服务规则是您使用本服务的前提。根据国家法律法规变化及网站运营需要，本公司有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本站上，则立即生效并代替原来的协议内容。您可随时登录查阅最新协议；您有义务不时关注并阅读最新版的协议及网站公告。如您不同意更新后的协议，应立即停止接受心流网依据本协议提供的服务；如您继续使用心流网提供的服务的，即视为同意更新后的协议。本公司建议您在使用心流网之前，应阅读本协议及心流网的公告。</p>
                    <p>3、本公司可能会以系统通知、电子邮件或发送到您手机的短信或电话等方式通知您服务进展情况以及提示您进行下一步的操作，但本公司不保证您能够收到或者及时收到该等通知，且不对您没有及时收到该等通知承担任何责任。因此，在服务过程中您应当及时登录到心流网查看服务进展情况和进行相关操作。因您没有及时查看和对服务状态进行修改或确认或未能及时提供反馈和信息资料而导致的任何纠纷或损失，本公司不承担任何责任。</p>
                    <p>4、服务费用，在您使用本服务时，本公司有权依照相应的服务收费介绍、订单或相关协议要求您支付服务费用。本公司拥有制订及调整服务费用之权利，具体服务费用以您使用本服务时心流网上所列之收费方式公告或您与本公司或心流网上的服务提供方通过心流网达成的其他书面协议为准。</p>
                    <h4>五、您的权利和义务</h4>
                    <p>1、您有权利拥有自己在心流网的用户名和密码并有权利使用自己的用户名和密码随时登录心流网网站。</p>
                    <p>2、您有权利享受心流网提供的企业估值服务及其他相关服务，并有权利在接受心流网提供的服务时获得心流网的支持、咨询等服务，服务内容详见心流网相关产品介绍。</p>
                    <p>3、您保证不会利用技术或其他手段破坏及扰乱心流网网站和服务以及心流网其他客户的网站和服务。</p>
                    <p>4、您应尊重心流网及其他第三方的知识产权和其他合法权益，并保证在发生侵犯上述权益的违法或违约事件时，您将尽力保护本公司及关联方、雇员、合作伙伴等免于因该等事件受到影响或损失；心流网保留您侵犯上述各方合法权益时终止向您提供服务的权利。</p>
                    <p>5、对由于您向心流网提供的联络方式问题（如信息有误或未保持畅通或未能及时查看信息）以及您用于接受心流网邮件的电子邮箱安全性、稳定性不佳而导致的一切后果，您应自行承担责任，包括但不限于因您未能及时收到心流网的相关通知而导致的后果和损失。</p>
                    <p>6、您保证您使用心流网服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，不会利用心流网提供的服务进行违反国家法律、法规、政策的行为。您同意心流网有权在您违反上述规定时终止向您提供服务，因您上述行为给心流网造成损失的，您应承担赔偿责任。</p>
                    <h4>六、心流网的权利和义务</h4>
                    <p>1、心流网应根据您选择的服务以及交纳款项（如需）的情况向您提供合格的企业估值服务及其他相关服务。</p>
                    <p>2、心流网承诺对您的资料采取对外保密措施，不向第三方披露您的资料，不授权第三方使用您的资料，除非：</p>
                    <p>(1)依据本协议条款或者您与心流网之间其他服务协议、合同等规定可以提供的。</p>
                    <p>(2)依据法律法规的规定应当提供的。</p>
                    <p>(3)行政、司法等职权部门依职权要求心流网提供的。</p>
                    <p>(4)您同意心流网向第三方提供的。</p>
                    <p>(5)心流网为解决举报事件、提起诉讼而提交的。</p>
                    <p>(6)心流网为防止严重违法行为或涉嫌犯罪行为的发生而采取必要合理行动所必须提交的。</p>
                    <p>(7)心流网为向您提供产品、服务、信息而向第三方提供您的资料和信息或通过第三方向您提供产品、服务、咨询而使用您的资料和信息时，在此情况下，心流网有权向上述第三方提供。</p>
                    <p>3、心流网及本公司关联方有权使用您的资料和信息，包括但不限于通过邮件、短信或电话等形式并有权向您发送注册登录信息、订单信息、服务要求、促销活动等告知信息。</p>
                    <p>4、心流网保留在您违反国家、地方法律法规规定或违反本协议条款的情况下终止为您提供服务并终止您账户的权利。</p>
                    <p>5、心流网有权使用您的注册信息、用户名、密码等信息，登录进入您的注册账户，进行证据保全，包括但不限于公证、见证等。</p>
                    <h4>七、隐私及其他个人信息的保护</h4>
                    <p>一旦您同意本协议或使用本服务，您即同意本公司按照以下条款来使用和披露您的个人信息。</p>
                    <p>1、用户名和密码</p>
                    <p>在您注册为心流网用户时，我们会要求您设置用户名和密码来识别您的身份。您仅可通过您设置的密码来使用心流网账户，如果您泄漏了密码，您可能会丢失您的个人识别信息，并可能导致对您不利的法律后果。心流网账户和密码因任何原因受到潜在或现实危险时，您应该立即和本公司取得联系，在本公司采取行动前，本公司对此不负任何责任。</p>
                    <p>2、 注册信息</p>
                    <p>您注册心流网账户时应向本公司提供您的真实姓名或名称以及联系方式（如地址、手机号码和电子邮件地址），您还可以选择来填写相关附加信息，以便于心流网为有针对性地向您提供更加有效的服务和机会，您了解并同意本公司及其关联方将通过您的电子邮件地址或该手机通知您这些信息。</p>
                    <p>3、 手机信息</p>
                    <p>您应妥善保护您的手机，一旦您手机出借、被盗、遗失或被他人窥视等，均可能引起您心流网账户相关个人信息之外泄，心流网对此不承担任何责任。</p>
                    <p>4、 银行账户信息</p>
                    <p>若本公司所提供的服务需要您提供您的银行账户或其他支付工具信息以及支付相关信息（如支付历史记录、账单及互动信息），在您提供相应信息后，本公司将严格履行相关保密约定。</p>
                    <p>5、 登录记录</p>
                    <p>为了保障您使用本服务的安全以及保障公司不断改进服务质量，本公司将记录并保存您登录和使用本服务的相关信息，本公司承诺不将此类信息提供给任何第三方，但双方另有约定或法律法规另有规定或本公司提供给本公司关联方的情形除外。</p>
                    <p>6、 数据统计</p>
                    <p>本公司会对心流网用户的身份数据进行综合统计，并基于市场调查、销售或优惠奖励的需要进行使用或披露。</p>
                    <p>7、 第三方</p>
                    <p>心流网含有到其他网站的链接或第三方产品或服务推荐，但本公司对第三方的隐私保护措施不承担任何责任。</p>
                    <h4>八、系统中断或故障</h4>
                    <p>系统因下列状况无法正常运转，使您无法使用各项服务时，本公司不承担损害赔偿责任，该状况包括但不限于：</p>
                    <p>1、 本公司在心流网公告之系统停机维护期间。</p>
                    <p>2、 第三方提供的基础设施出现故障不能进行数据传输的。</p>
                    <p>3、 因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成本公司系统障碍不能执行业务的。</p>
                    <p>4、 由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。</p>
                    <p>九、责任范围及责任限制</p>
                    <p>1、 心流网依据国际通用的企业估值模型和方法通过网络为用户提供非法定的企业估值服务，心流网提供的所有计算模型均由专业分析师准备和审阅，但用户使用心流网制作及计算出来的文件和数据仅供参考，并非最终文件，用户可以自行修改，心流网特别建议用户根据实际情况进行审查后再使用，心流网和本公司就此并不承担任何法律责任。</p>
                    <p>2、 心流网用户信息是由您本人自行提供的，本公司无法保证该信息之真实、准确、及时和完整，您应对您的判断承担全部责任。</p>
                    <p>3、 您自本公司及本公司工作人员或经由本服务取得之建议和资讯，无论其为书面或口头形式，均不构成本公司对本服务之保证。</p>
                    <p>4、 在法律允许的情况下，本公司对于与本协议有关或由本协议引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失、使用数据或其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约（包括违反保证）还是由侵权造成的，均不负有任何责任，即使事先已被告知此等损失的可能性。另外即使本协议规定的排他性救济没有达到其基本目的，也应排除本公司对上述损失的责任。</p>
                    <p>5、 除本协议另有规定外，在任何情况下，本公司对本协议所承担的违约赔偿责任总额以本公司向您收取的当次服务费用（如有）为上限。</p>
                    <h4>十、知识产权</h4>
                    <p>1、 心流网上所有心流网程序或内容，包括但不限于图片、档案、资讯、数据、信息、资料、网站架构、网站画面的安排、网页设计以及系统根据您的输入生产的任何文件、信息或内容（“专有内容”），均由本公司或本公司关联方依法拥有其知识产权，包括但不限于著作权、商标权、专利权、商业秘密等。</p>
                    <p>2、 特此授权用户就其在问卷中所披露的特定项目通过心流网企业估值服务及其他相关服务使用和修改形成的项目文件（“允许使用范围”）。除前述允许使用范围之外，非经本公司事先书面同意，任何人不得擅自使用、修改、复制、公开传播、散布、发行或公开发表上述专有内容，也不得擅自指令或允许他人使用、修改、复制、公开传播、散布、发行或公开发表上述专有内容。</p>
                    <p>3、 尊重知识产权并遵守本第十条是您应尽的义务，如有违反，您应承担损害赔偿责任。</p>
                    <h4>十一、协议的解释和适用</h4>
                    <p>1、 本协议由本协议条款与心流网公示的各项规则组成，相关名词可互相引用参照，如有不同理解，以本协议条款为准。</p>
                    <p>2、 您对本协议理解和认同，您即对本协议所有组成部分的内容理解并认同，一旦您使用本服务，您和本公司即受本协议所有组成部分的约束。</p>
                    <p>3、 本协议部分内容被有管辖权的机关或机构认定为废止、无效或因任何理由不可执行，不因此影响其他内容的有效性和可执行性。</p>
                    <h4>十二、法律适用与管辖</h4>
                    <p>本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。 如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；但如在60天内不能友好解决有关争议，任何一方均可提交北京仲裁委员会适用简易程序在北京裁决，仲裁裁决为终局裁定，对双方均有约束力。</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name:"registration",
        data() {
            return {
            
            }
        }
    }
</script>
<style scoped>
    .content {
        width: 100%;
    }
    .agreement {
        padding: 0 20px;
    }
    .agreement h3 {
        text-align: center;
        font-size:22px;
        line-height:48px;
        color:#000;
        font-family: MicrosoftYaHei;
        font-weight: bold;
    }
    .agreement h4 {
        text-align: left;
        font-size:18px;
        line-height:40px;
        color:#000;
        font-family: MicrosoftYaHei;
        font-weight: bold;
    }
    .agreement p {
        font-size: 14px;
        color:#000;
        line-height:28px;
        font-family: MicrosoftYaHei;
        text-align: justify;
        text-indent:28px;
    }
</style>
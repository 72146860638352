<template>
        <div :id="id"></div>
</template>
<script>
import * as echarts from 'echarts/lib/echarts.js'
import formatCurrency from '../../assets/js/numberToCurrency'


export default ({
    props: [
        'chartData','id', 'unit'
    ],
    data() {
        return {
            chartName:[],
            chartValue:[]
        }
    },
    mounted(){
        if(this.chartData){
            this.chartName = this.chartData.name;
            this.chartValue = this.chartData.value;

            this.initChart()
        }
    },
    methods: {
        initChart: function(){
            this.chart = echarts.init(document.getElementById(this.id));
            this.setOptions(this.id, this.chartName, this.chartValue)
        },
        setOptions: function(id, dataxAxis, datayAxis){
            var fontSize = 14;
            var companyValue = datayAxis[0];
            var averageIndex;
            for (var i = 0; i < dataxAxis.length; i++) {
                if (dataxAxis[i] == "行业均值") {
                    averageIndex = i
                }
            }
            var markLineValue = companyValue - datayAxis[averageIndex],
                labelObj = {},
                normalObj = {};
            switch (id) {
                case "liabilitiesRatio":
                    var titleText = "资产负债率";
                    var yAxisName = "%";
                    break;
                case "flowRation":
                    var titleText = "流动比率";
                    var yAxisName = "";
                    break;
                case "ebitMultiple":
                    var titleText = "EBIT利息保障倍数";
                    var yAxisName = "";
                    break;
                case "grossProfitRatio":
                    var titleText = "毛利率";
                    var yAxisName = "%";
                    break;
                case "netProfiltRatio":
                    var titleText = "净利润率";
                    var yAxisName = "%";
                    break;
                case "mainIncomeRatio":
                    var titleText = "主营业务收入增长率";
                    var yAxisName = "%";
                    break;
                case "netAssetRatio":
                    var titleText = "净资产收益率";
                    var yAxisName = "%";
                    break;
                case "receivablesTurnoverRatio":
                    var titleText = "应收账款周转率";
                    //			var yAxisName = "%";
                    break;
                case "inventoryTurnoverRatio":
                    var titleText = "存货周转率";
                    var yAxisName = "";
                    break;
            }

            var xAxis = [],
                yAxis = [],
                position;
            var xObj = {},
                yObj = {
                    axisLabel: {
                        textStyle: {
                            fontSize: fontSize
                        }
                    }
                },
                splitLine = {},
                axisTick = { alignWithLabel: true },
                data = [],
                axisOne = {},
                axisTwo = {},
                coordOne = [],
                coordTwo = [],
                axisLabel = { interval: 0, color: "#282828" },
                textStyle = { fontSize: fontSize };
            xObj.type = "category";
            xObj.data = dataxAxis;
            xObj.axisTick = axisTick;
            xObj.axisLabel = axisLabel;
            axisTick.show = false;
            axisLabel.formatter = formatCurrency.formatter;
            axisLabel.textStyle = textStyle;
            xAxis.push(xObj);

            yObj.type = "value";
            yObj.name = yAxisName;
            yObj.splitLine = splitLine;
            yObj.axisLine = {
                show: true
            }
            yObj.axisTick = {
                show: true
            }
            splitLine.show = false;
            yAxis.push(yObj);

            position = "top";
            axisOne.yAxis = datayAxis[0];
            axisOne.symbolSize = [0, 0];
            axisTwo.yAxis = datayAxis[averageIndex];
            axisTwo.symbolSize = [0, 0];
            coordOne = [6, companyValue];
            coordTwo = [6, datayAxis[averageIndex]];
            normalObj.show = true;
            normalObj.position = position;
            var textObj = {};
            textObj.color = "black";
            normalObj.textStyle = textObj;
            normalObj.formatter = '{c}';
            labelObj.normal = normalObj;
            var option = {
                title: {
                    show: true,
                    text: titleText,
                    textStyle: {
                        color: '#282828',
                        align: 'center',
                        fontSize: 16
                    },
                    left: 'center',
                    top: 'top'
                },
                tooltip: {
                    // trigger: 'axis',
                    // axisPointer: { // 坐标轴指示器，坐标轴触发有效
                    //     type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'

                    // },
                    trigger: 'axis',
                    confine: true,
                    axisPointer: { type: 'none' },
                    textStyle: { fontSize: 12, fontWeight: 500 },
                    formatter(params) {
                        // 只展示柱子对应的内容，把顶部底部的 tooltip 过滤掉
                        return params.reduce((pre, i) => {
                            if (i.componentSubType === "line") {
                                i.marker = i.marker.replace(/\[object Object\]/, i.color);
                                i.value = `<span style="flex: 1; text-align: right; margin-left: 16px;">${i.value}</span>`;
                                const current = `<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.axisValue} ${i.value}</div>`;
                                return `${pre}${current}`;
                            }
                            if (i.componentSubType === 'bar') {
                                i.marker = i.marker.replace(/\[object Object\]/, i.color.colorStops[1].color);
                                i.value = `<span style="flex: 1; text-align: right; margin-left: 16px;">${i.value}</span>`;
                                const current = `<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.axisValue} ${i.value}</div>`;
                                return `${pre}${current}`;
                            }
                            return pre;
                        }, '');
                    },
                },
                grid: { // 控制图的大小，调整下面这些值就可以，
                    left: '2%',
                    right: '8%',
                    bottom: '10%',
                    top: '20%',
                    containLabel: true
                },
                animation: false,
                xAxis: xAxis,
                yAxis: yAxis,

                series: [{
                        // name: incomeType,

                        type: 'bar',
                        barMaxWidth: 34,
                        barCategoryGap: 12,
                        z: 12,
                        data: datayAxis,
                        itemStyle: {　　　　　　　　　 // 柱体渐变色
                            color: function(params) {
                                var colorList = [
                                    ['#336DD2', '#6094EC'],
                                    ['#C0C9DB', '#DAE3F3'],
                                    ['#8065FE', '#A18DFF'],
                                    ['#49C9C9', '#74E7E7'],
                                    ['#9BB4C4', '#B6CFE0'],
                                    ['#398FF1', '#7CC1F5'],
                                ]
                                var index = params.dataIndex;
                                if (params.dataIndex >= colorList.length) {
                                    index = params.dataIndex - colorList.length;
                                }
                                return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                                    { offset: 0, color: colorList[index][0] },
                                    { offset: 1, color: colorList[index][1] }
                                ])
                            }
                        },
                        markLine: {
                            lineStyle: {
                                color: '#282828',
                            },
                            label: {
                                show: false,
                            },
                            data: [
                                [{
                                        coord: coordOne,
                                        lineStyle: {
                                            color: '#282828',
                                            type: 'solid'
                                        },
                                        symbolSize: [0, 0],
                                    },
                                    {
                                        coord: coordTwo,
                                        lineStyle: {
                                            color: '#282828',
                                            type: 'solid'
                                        },
                                        label: {
                                            show: true,
                                            position: 'middle',
                                            formatter: function(params) {
                                                var value = markLineValue.toFixed(2);
                                                return value;
                                            }
                                        },
                                    },
                                ],

                            ],
                        },
                    },
                    {
                        // name: incomeType,
                        type: 'pictorialBar',
                        symbolSize: [34, 16],
                        // 这个属性很重要，直接决定了顶部跟柱子是否契合
                        // symbolOffset: [0, -10],
                        z: 13,
                        itemStyle: {

                            normal: {
                                label: {
                                    show: true,
                                    position: 'top', //在上方显示
                                    // formatter: function(params){
                                    //     if(id == "netAssetRatio" || id == "mainIncomeRatio" || id == "liabilitiesRatio"){
                                    //         return params.data.value + "%";
                                    //     } else {
                                    //         return params.data.value;
                                    //     }
                                    // } //显示百分号
                                    formatter: "{c}"
                                },
                                color: function(params) {
                                    var colorList = ['#6094EC', '#DAE3F3', '#A18DFF', '#74E7E7', '#B6CFE0', '#7CC1F5'];
                                    // console.log(params)
                                    var index = params.dataIndex;
                                    return params.color = colorList[index]
                                }
                            }
                        },
                        // symbolPosition: 'end',
                        // 要给成武汉这两个柱子的值，这是圆柱顶部，值不给对高度会对不上
                        data: this.pictorialBar1(datayAxis),
                    },
                    {
                        type: 'line',
                        markLine: {
                            symbolSize: [0, 0],
                            silent: true,
                            lineStyle: {
                                color: '#282828',
                            },
                            label: {
                                show: false
                            },
                            data: [
                                axisOne,
                                axisTwo,
                            ]
                        }
                    }
                ]
            };
            var myChart = echarts.init(document.getElementById(id));
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        },
        pictorialBar1: function(data) {
            var arr = [];
            data.forEach(function(ele, i) {
                if (ele < 0) {
                    arr[i] = {
                        value: ele,
                        symbolOffset: [0, 10],
                        symbolPosition: 'end'
                    }
                } else {
                    arr[i] = {
                        value: ele,
                        symbolOffset: [0, -10],
                        symbolPosition: 'end'
                    }
                }
            });
            return arr
        }
    }
})
</script>
<style scoped>
</style>

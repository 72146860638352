<template>
    <div>
        <!-- 助企培训-详情页面 -->
        <Header />
        <div style="padding-bottom:100px" class="content">
            <ul class="breadcrumb">
				<li>
					<img src="../../assets/img/mobileClient/weizhi.svg" alt="">
				</li>
				<li><a>当前位置：</a></li>
                <li><a @click="toIndex()">首页</a></li>
                <li><a @click="toGoBack()">助企培训</a></li>
                <li class="active">{{itemInfo.caseName}}</li>
            </ul>
			<div class="clear"></div>
            <div class="policyDetails">
                <div class="mainHeading">
                    <h3>{{itemInfo.caseName}}</h3>
                </div>
                <div class="mainContent" v-html="serviceContent">{{serviceContent}}</div>
            </div>
        </div>
        <router-view/>
        <Footer /> 
    </div>
</template>
<script>
	import qs from 'qs';
	import Header from '../../common/header.vue';
	import Footer from '../../common/footer.vue';

export default {
    name:"enterpriseTrainDes",
    components: {
        Header,
        Footer
    },
    data(){
        return {
            itemInfo:{},
            serviceContent:"",
            projectName:''
        }
    },
    mounted(){
        this.projectName=this.domain.serviceTraining
        this.initPage();
    },
    methods: {
        toIndex: function(){
            this.$router.push({
                name: 'home',
                path: '/',
            })
        },
        toGoBack: function(){
            this.$router.push({
                name:"enterpriseTrain",
                path:"/enterpriseTrain"
            })
        },
        initPage: function(){
            this.$post(this.projectName+"policyService/getDetailService",qs.stringify({
                serviceId: this.$route.query.id
            })).then((res) => {
                console.log(res);
                var data = res.data;
                if(data.code == 200){
                    this.itemInfo = data.content.policyService;
                    this.serviceContent = data.content.serviceContent;
                }
            })
        }
    }
}
</script>
<style scoped>
    .policyDetails {
        width: 1200px;
        margin: 0 auto;
		margin-top: 30px;
    }
    .policyDetails .mainHeading {
        height: 60px;
        border-bottom: 1px solid #d8d8d8;
    }
    .policyDetails .mainHeading h3 {
        font-size: 22px;
        font-weight: bold;
        line-height: 60px;
        text-align: center;
    }
    .policyDetails .mainContent {
        font-size: 14px;
        line-height: 28px;
        text-indent: 28px;
        color: #000;
    } 
    @media screen and (max-width:765px) {
        .content {
            width: 100%;
            padding: 0 20px;
        }
        .content ul {
            width: 100%;
            padding: 0;
        }
        .content .policyDetails {
            width: 100%;
        }
        .policyDetails .mainHeading h3 {
            font-size: 22PX;
        }
        .policyDetails .mainContent {
            font-size: 14PX;
            text-align: justify;
            line-height: 22PX;
            margin-top: 20px;
        }
		
		ul.breadcrumb{
			margin: 100px auto 30px;
		}
    }
</style>
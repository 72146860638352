<template>
	<div class="errorContent">
		<img src="../../assets/img/404@2x.png" />
		<p>啊哦～这个页面走丢了...</p>
		<p>我们正尝试与TA联系，您可以</p>
		<router-link to="/" tag="a">返回首页</router-link>
	</div>
</template>

<script>
	export default {
		mounted() {
			
		}
	}
</script>

<style scoped>
	.errorContent {
		height: 322px;
		position: absolute;
		left: 50%;
		margin-left: -174px;
		top: 20%;
	}
	.errorContent img{
		width: 348px;
	}

	.errorContent>p:first-of-type {
		font-size: 18PX;
		color: #515F6B;
		margin: 23px 0 0 0;
	}

	.errorContent>p:last-of-type {
		font-size: 14PX;
		color: #515F6B;
		margin: 16px 0 0 0;
	}

	.errorContent>a {
		height: 58px;
		width: 188px;
		border-radius: 30px;
		border: 1px solid #ECC641;
		text-align: center;
		line-height: 58px;
		font-size: 16PX;
		color: #ECC641;
		display: block;
		margin: 43px auto 0;
	}
	@media screen and (max-width: 768px){
		.errorContent img{
			/* width: 100px; */
		}
	}
</style>

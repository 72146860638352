<template>
        <div :id="id"></div>
</template>
<script>
import * as echarts from 'echarts/lib/echarts.js'
import formatCurrency from '../../assets/js/numberToCurrency'


export default ({
    props: [
        'chartData','id', 'unit'
    ],
    data() {
        return {
            chartName:[],
            chartValue:[]
        }
    },
    mounted(){
        if(this.chartData){
            if(this.unit){
                if(this.chartData){
                    if(this.id == "expenditurePic"){
                        for (var i = 0; i < this.chartData.netProfitArr.length; i++) {
                            this.chartName.push(this.chartData.yearArray[i])
                            if (i < 3) {
                                this.chartValue.push(this.chartData.netProfitArr[i]);
                            } else {
                                var obj = {
                                    value: this.chartData.netProfitArr[i],
                                    itemStyle: {
                                        normal: {
                                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                                offset: 0,
                                                color: '#087CFF'
                                            }, {
                                                offset: 1,
                                                color: '#0F58FE'
                                            }]),
                                            // color: linear-gradient("0deg","#0487FC", "#5FB3FF")
                                            // color: "#0951FD"
                                        },
                                    }
                                }
                                this.chartValue.push(obj);
                            }
                        }
                    } else {
                        for (var i = 0; i < this.chartData.netArr.length; i++) {
                            this.chartName.push(this.chartData.yearArray[i])
                            if (i < 3) {
                                this.chartValue.push(this.chartData.netArr[i]);
                            } else {
                                var obj = {
                                    value: this.chartData.netArr[i],
                                    itemStyle: {
                                        normal: {
                                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                                offset: 0,
                                                color: '#087CFF'
                                            }, {
                                                offset: 1,
                                                color: '#0F58FE'
                                            }]),
                                            // color: linear-gradient("0deg","#0487FC", "#5FB3FF")
                                            // color: "#0951FD"
                                        },
                                    }
                                }
                                this.chartValue.push(obj);
                            }
                        }
                    }
                } else {
                    var year = new Date().getFullYear();
                    this.chartValue = [0, 0, 0, 0, 0, 0, 0, 0];
                    this.chartName = [year - 3, year - 2, year - 1, year, year + 1, year + 2, year + 3, year + 4];
                }
            } else {
                this.chartData.map((item) => {
                    this.chartName.push(item.name);
                    this.chartValue.push(item.value);
                })
            }
            
        };
        this.initChart()
    },
    methods: {
        initChart: function(){
                this.chart = echarts.init(document.getElementById(this.id));
                this.setOptions(this.id, this.chartName, this.chartValue, this.unit)
        },
        setOptions: function(id, dataxAxis, dataSeries, name){
            var option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    // left: '13%',
                },
                xAxis: {
                    type: 'category',
                    data: dataxAxis,
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        margin: 10,
                        textStyle: {
                            fontSize: 14,
                            color: "#121212",
                        },
                        interval: 0
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#000"
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    name: name,
                    nameTextStyle: {
                        fontSize: 16
                    },
                    splitLine: {
                        show: true,

                    },
                    axisTick: {
                        show: true
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#000"
                        }
                    },
                    // nameGap:30,
                    axisLabel: {
                        textStyle: {
                            fontSize: 16,
                            color: "#121212",
                        }
                    },
                },

                series: {
                    data: dataSeries,
                    type: 'bar',
                    barMaxWidth: 80,
                    label: {
                        normal: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: 'black'
                            },
                            formatter: function(p) {
                                return formatCurrency.formateNum(p.value.toString(), 2);
                            }
                        }
                    },
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#5FB3FF'
                            }, {
                                offset: 1,
                                color: '#0487FC'
                            }]),
                            // color: linear-gradient("0deg","#0487FC", "#5FB3FF")
                            // color: "#0951FD"
                        },
                    }
                },
                // },
            };
            var myChart = echarts.init(document.getElementById(id));
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        }
    }
})
</script>
<style scoped>
</style>

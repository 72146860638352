<template>
	<div>
		<Header />
		<uploadData />
		<navigation />
		<div class="information" id="finanAnalisis">
			<p class="moduleName">
				<img src="../../assets/img/navIcon/financeSele.png" alt="心流">
				<span>{{this.$route.query.type==5?this.$route.query.year+'年财务指标':'财务指标'}}</span>
				<span v-if="this.$route.query.type==2" class="division">|</span>
				<span v-if="this.$route.query.type==2" class="">专业估值</span>
			</p>

			<div class="moduleMain">
				<financeNav />
				<div class="inputTabDesc">
					<img src="../../assets/img/guzhi/desc@2x.png" alt="财务分析是根据填写的资产负债表和利润表的数据通过计算分析得出结果-心流">
					<span>财务分析是根据您填写的资产负债表和利润表的数据，心流通过计算分析得出结果，该页面不需要填写，请查看并确认。</span>
				</div>
				<div class="table-main">
					<!-- 表名-->
					<div class="table-name">
						<span>财务分析</span>
					</div>

					<!-- 财务分析主要内容-->
					<div class="finanAnalysis-main">
						<div v-if="perfect">
							<img src="../../assets/img/navIcon/financialAnalysis.png" alt="线上估值-心流" />
							<div class="finanAnalysis-tip">
								暂无信息，请先完善
								<span class="complete">
									<span v-if="baseInformation"><a class="completeInfo" data-info="baseInformation"
											@click="skip('baseInformation')">基本信息</a></span>
									<span v-if="majorBalance"><a class="completeInfo" data-info="majorBalance"
											@click="skip('majorBalance')">资产负债表</a></span>
									<span v-if="majorProfit"><a class="completeInfo" data-info="majorProfit"
											@click="skip('majorProfit')">利润表</a></span>
								</span>
							</div>
						</div>
						<div v-if="analyse" class="row" v-for="(topic, idx) in list">
							<div class="position-tit-container">
								<span>{{idx+1}}</span>
								<span class="postion-tit">{{topic.content}}</span>
							</div>
							<div class="options-container">
								<ul>
									<li v-for="(item, i) in topic.options" :class="item.chooseFlag?'active':''">
										<input v-if="item.chooseFlag" type="radio" disabled="disabled" checked="checked"
											:name="item.id">
										<input v-if="!item.chooseFlag" type="radio" disabled="disabled" :name="item.id">
										<label :for="item.id">{{item.content}}</label>
										<span class="back"></span>
									</li>
								</ul>
							</div>
						</div>
					</div>

				</div>
			</div>

			<div class="saveBox">
				<span class="last lastStep" id="last" @click="lastStep">上一步</span>
				<span class="save saveNext" id="saveButton" @click="saveButton">保 存</span>
			</div>

		</div>



		<Footer />
		<router-view />

	</div>
</template>

<script>
	import Header from '../header.vue';
	import Footer from '../footer.vue';
	import navigation from './navigation.vue';
	import uploadData from './uploadData.vue';
	import financeNav from './financeNav.vue';
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';
	import '../../assets/css/softInformation.css';
	
	export default {
		data() {
			return {
				perfect: true, //没有填基本信息、资产负债表和利润表的显示内容
				analyse: false,
				baseInformation: false,
				majorBalance: false,
				majorProfit: false,
				list: [],
				projectName: ''
			}
		},
		components: {
			Header,
			Footer,
			navigation,
			uploadData,
			financeNav
		},
		mounted() {
			if (this.$route.query.type == 7) {
				this.projectName = this.domain.diagnoseProject;
			} else if (this.$route.query.type == 5) {
				this.projectName = this.domain.traceProject;
			} else if (this.$route.query.type == 2) {
				this.projectName = this.domain.valuationProject;
			}
			this.pageModify('false');
			this.getFinancialAnalysis()
		},
		methods: {
			...mapMutations(['pageModify']),
			getFinancialAnalysis: function() {
				if (this.$route.query.reportId) {
					var params = {
						reportId: this.$route.query.reportId,
						reportType: this.$route.query.type
					};
					if (this.$route.query.type == 5) {
						params.traceReportId = this.$route.query.traceReportId;
						var url = this.projectName + 'financeQuestion/qryFinanceQuestion';
					} else {
						var url = this.projectName + 'financeQuestion/checkOutFinanceQuestion';
					}

					this.$post(url, qs.stringify(params))
						.then((res) => {
							if (res.data.code == 200) {
								var data = res.data;

								if (this.$route.query.type == 5) {
									if (data.content.financeQuestionList) {
										this.perfect = false;
										this.analyse = true;
										this.list = data.content.financeQuestionList;
									} else {
										if (!data.content.baseInfoFlag) {
											this.baseInformation = true;
										} else if (!data.content.liabilitiesInfoFlag) {
											this.majorBalance = true;
										} else if (!data.content.profitInfoFlag) {
											this.majorProfit = true;
										}
									}
								} else {
									if (data.content.checkReportRequired) {
										if (!data.content.checkReportRequired.baseInfoFlag) {
											this.baseInformation = true;
										} else if (!data.content.checkReportRequired.liabilitiesInfoFlag) {
											this.majorBalance = true;
										} else if (!data.content.checkReportRequired.profitInfoFlag) {
											this.majorProfit = true;
										}

									} else {
										// 添加内容
										this.perfect = false;
										this.analyse = true;
										this.list = data.content.list;
									}
								}
							}
						})
				} else {
					this.perfect = true;
					this.baseInformation = true;
					this.majorBalance = true;
					this.majorProfit = true;
					this.analyse = false;
				}
			},
			skip: function(data) {
				if (data == 'baseInformation') {
					var path = '/basicInformation';
				} else if (data == 'majorBalance') {
					var path = '/balanceSheet';
				} else {
					var path = '/profitStatement';
				}


				if (this.$route.query.type == 5) {
					this.$router.push({
						path: path,
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type,
							reportId: this.$route.query.reportId,
							traceReportId: this.$route.query.traceReportId,
							year: this.$route.query.year
						}
					})
				} else {
					if (this.$route.query.reportId) {
						this.$router.push({
							path: path,
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId
							}
						})
					} else {
						this.$router.push({
							path: path,
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
							}
						})
					}
				}
			},
			lastStep: function() {
				if (this.$route.query.reportId) {
					if (this.$route.query.type == 5) {
						this.$router.push({
							path: '/majorPolicyStatis',
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId,
								traceReportId: this.$route.query.traceReportId,
								year: this.$route.query.year
							}
						})
					} else {
						this.$router.push({
							path: '/majorPolicyStatis',
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId
							}
						})
					}
				} else {
					this.$router.push({
						path: '/majorPolicyStatis',
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type
						}
					})
				}
			},
			saveButton: function() {
				if (this.$route.query.reportId) {
					if (this.$route.query.type == 5) {
						this.$router.push({
							path: '/submitReport',
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId,
								traceReportId: this.$route.query.traceReportId,
								year: this.$route.query.year
							}
						})
					} else {
						this.$router.push({
							path: '/submitReport',
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId
							}
						})
					}
				} else {
					this.$router.push({
						path: '/submitReport',
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type
						}
					})
				}
			}









		}


	}
</script>

<style scoped>
	.finanAnalysis-main>div>img{
		margin:70px auto 50px;
		display: block;
	}
	@media screen and (max-width: 750px) {
		div.table-name>span {
			font-size: 16PX;
		}

	}
</style>
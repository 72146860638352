import { render, staticRenderFns } from "./diagnoseReport.vue?vue&type=template&id=4c1c4937&scoped=true&"
import script from "./diagnoseReport.vue?vue&type=script&lang=js&"
export * from "./diagnoseReport.vue?vue&type=script&lang=js&"
import style0 from "./diagnoseReport.vue?vue&type=style&index=0&id=4c1c4937&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c1c4937",
  null
  
)

export default component.exports
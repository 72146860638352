<template>
    <div style="background:#f3f3f3">
        <div class="tools">
            <button @click="pdf()">导出PDF</button>
        </div>
        <div id="report-page">
            <!--第一页：封面-->
            <div class="page page-one" id="page-one">
                <img src="../../assets/img/logo-08.svg" alt="北京心流慧估科技有限公司logo-心流" id="logo"/>

                <!-- <div class="flowAndStock hide">
                    <div>
                        <img class="flow" src="../../assets/img/logo-08.svg" alt="北京心流慧估科技有限公司logo-心流" />
                        <span class="logoXian"></span>
                        <img class="stock" src="" />
                    </div>
                    <p class="reportNumber" style="position: inherit;font-size: 18px;margin-bottom: 100px;margin-top: 4px;">报告编号：</p>
                </div> -->
                <img src="../../assets/img/specialization/artFont.png" alt="" class="artFont">
                <p class="reportNumber" v-text="'报告编号：' + pageInfo.reportNumber">报告编号：</p>
                <div class="page-main">
                    <p class="companyName" v-text="pageInfo.baseInfo.companyName"></p>
                    <h5>价值追踪报告</h5>
                    <h4>（专精特新版）</h4>
                    <img src="../../assets/img/trace/fengmian.png" class="fengmian" alt="">
                </div>
                <div class="page-one-footer">
                    <div class="date" v-text="pageInfo.createTime ? replaceAll(pageInfo.createTime) : ''">2018 .05. 08</div>
                    <p>
                        <span>北京心流慧估科技有限公司</span>
                        <span>|</span>
                        <span>心流保留对此报告的最终解释权</span>
                    </p>
                </div>
            </div>
            <!--盖章页-->
            <div class="page page-stamp" id="page-stamp">
                <!--内容-->
                <div class="page-content">
                    <p class="stamp">依据《企业会计准则第22号--金融工具确认和计量》、《企业会计准则第39号--公允价值计量》、《私募投资基金非上市股权投资估值指引》和《中小微企业估值规范》等政策规章，基于股权估值模型和估值指标体系，贵公司在估值基准日的股权价值为（人民币）：</p>
                    <div class="detail_result">
                        <div>
                            <h4 v-text="resultFormate(pageInfo.minValue, pageInfo.maxValue)">0.00</h4>
                            <img src="../../assets/img/specialization/artFont.svg" class="artFont" alt="">
                        </div>
                    </div>
                    <div class="stamptime">
                        <p>北京心流慧估科技有限公司</p>
                        <p v-text="pageInfo.createTime ? replaceAll(pageInfo.createTime) : ''"></p>
                    </div>

                </div>
            </div>
            <!--目录页-->
            <div class="page page-catalogue" id="page-catalogue">
                <div class="page-content cataloguePage" id="cataloguePage">
                    <h2>目录</h2>
                    <div class="generalize">
                        <div>
                            <p>1</p>
                            <div></div>
                            <p>基本信息</p>
                            <span style="width: 688px;" class="dottedLine titleActive"></span>
                            <span class="pageNumber pageNumActive">01</span>
                        </div>
                        <div>
                            <p>2</p>
                            <div></div>
                            <p>估值模型</p>
                            <span style="width: 688px;" class="dottedLine titleActive"></span>
                            <span class="pageNumber pageNumActive">02</span>
                        </div>
                        <ul>
                            <li>
                                <p>相对估值</p>
                                <span class="dottedLine" style="width: 713px;"></span>
                                <span class="pageNumber">03</span>
                            </li>
                            <li>
                                <p>绝对估值</p>
                                <span class="dottedLine" style="width: 713px;"></span>
                                <span class="pageNumber">04</span>
                            </li>
                            <li>
                                <p>估值结果</p>
                                <span class="dottedLine" style="width: 713px;"></span>
                                <span class="pageNumber">05</span>
                            </li>
                        </ul>
                        <div>
                            <p>3</p>
                            <div></div>
                            <p>估值结果分析</p>
                            <span class="dottedLine titleActive" style="width: 642px;"></span>
                            <span class="pageNumber pageNumActive">05</span>
                        </div>
                        <ul>
                            <li>
                                <p>估值结果明细</p>
                                <span class="dottedLine" style="width: 678px;"></span>
                                <span class="pageNumber">05</span>
                            </li>
                            <li>
                                <p>价值比率及财务预测分析</p>
                                <span class="dottedLine" style="width: 589px;"></span>
                                <span class="pageNumber">06</span>
                            </li>
                            <li>
                                <p style="text-indent: 18px">价值比率变化</p>
                                <span style="width: 662px" class="dottedLine"></span>
                                <span class="pageNumber">06</span>
                            </li>
                            <li>
                                <p style="text-indent: 18px">财务预测变化</p>
                                <span style="width: 662px" class="dottedLine"></span>
                                <span class="pageNumber">06</span>
                            </li>
                        </ul>
                        <div>
                            <p>4</p>
                            <div></div>
                            <p>价值追踪分析要素</p>
                            <span style="width: 596px;" class="dottedLine titleActive"></span>
                            <span class="pageNumber pageNumActive">07</span>
                        </div>
                        <ul>
                            <li>
                                <p>企业综合竞争力变化分析</p>
                                <span class="dottedLine" style="width: 590px;"></span>
                                <span class="pageNumber">07</span>
                            </li>
                            <li>
                                <p>企业财务状况变化分析</p>
                                <span class="dottedLine" style="width: 608px;"></span>
                                <span class="pageNumber">10</span>
                            </li>
                            <li>
                                <p style="text-indent: 18px">偿债能力</p>
                                <span style="width: 699px" class="dottedLine"></span>
                                <span class="pageNumber">11</span>
                            </li>
                            <li>
                                <p style="text-indent: 18px">盈利能力</p>
                                <span style="width: 699px" class="dottedLine"></span>
                                <span class="pageNumber">12</span>
                            </li>
                            <li>
                                <p style="text-indent: 18px">成长能力</p>
                                <span style="width: 699px" class="dottedLine"></span>
                                <span class="pageNumber">13</span>
                            </li>
                            <li>
                                <p style="text-indent: 18px">运营能力</p>
                                <span style="width: 699px" class="dottedLine"></span>
                                <span class="pageNumber">14</span>
                            </li>
                            <li>
                                <p>杜邦分析</p>
                                <span class="dottedLine" style="width: 719px;"></span>
                                <span class="pageNumber">15</span>
                            </li>
                        </ul>
                        <div>
                            <p>5</p>
                            <div></div>
                            <p>附件</p>
                            <span class="dottedLine titleActive" style="width: 743px;"></span>
                            <span class="pageNumber pageNumActive">16</span>
                        </div>
                        <div class="stockIntroduce hide">
                            <p>6</p>
                            <div></div>
                            <p class="stock_introductory">股交所介绍</p>
                            <span style="width: 673px;" class="dottedLine titleActive"></span>
                            <span class="pageNumber pageNumActive">20</span>
                        </div>
                        <div>
                            <p class="catalogueHeader">6</p>
                            <div></div>
                            <p>关于心流慧估</p>
                            <span style="width: 649px;" class="dottedLine titleActive"></span>
                            <span class="pageNumber pageNumActive bottomofPage">20</span>
                        </div>
                    </div>
                    <div class="generalize">


                    </div>
                </div>
            </div>
            <!--第二页-->
            <div class="page page-two" id="page-two">
                <!-- 页面内容-->
                <div class="page-content">
                    <!--估值结果-->
                    <div class="valueResult">
                        <div class="valueResultTitle title_top">
                            <img src="../../assets/img/guzhi/valueResult.png" class="icon_" alt="">
                            <p>估值结果</p>
                        </div>
                        <div class="valueResultCont">
                            <div class="valueResultPic">
                                <p class="result-num" v-text="resultFormate(pageInfo.minValue, pageInfo.maxValue)">0.00</p>
                            </div>
                            <h6>报告基准日：<span id="reportBaseDate" v-text="replaceAll(pageInfo.reportBaseDate)">xxx年x月x日</span></h6>
                            <div class="resultDes result-desc"></div>
                        </div>
                    </div>
                    <!-- 企业基本信息-->
                    <div class="basicInfo">
                        <div class="basicInfoTitle title_top">
                            <img src="../../assets/img/startup/jibenxinxi.png" alt="" class="icon_ icon1">
                            <p>企业基本信息</p>
                        </div>
                        <div class="baseInfoCont">
                            <ul>
                                <li>
                                    <span>企业名称：</span>
                                    <span id="companyName" v-text="pageInfo.baseInfo.companyName">XXXX公司</span>
                                </li>
                                <li>
                                    <span>企业所在地：</span>
                                    <span id="area" v-text="pageInfo.baseInfo.area ? pageInfo.baseInfo.area:'无数据'">无数据</span>
                                </li>
                                <li>
                                    <span>统一社会信用代码：</span>
                                    <span id="creditCode" v-text="pageInfo.baseInfo.creditCode ? pageInfo.baseInfo.creditCode:'无数据'">无数据</span>
                                </li>
                                <li>
                                    <span>法定代表人：</span>
                                    <span id="legalPeople" v-text="pageInfo.baseInfo.legalPeople ? pageInfo.baseInfo.legalPeople:'无数据'">无数据</span>
                                </li>
                                <li>
                                    <span>邮箱：</span>
                                    <span id="email" v-text="pageInfo.baseInfo.email ? pageInfo.baseInfo.email:'无数据'">xxxxxxx</span>
                                </li>
                                <li>
                                    <span>成立日期：</span>
                                    <span id="establishDate" v-text="pageInfo.baseInfo.establishDate ? dateFormate(pageInfo.baseInfo.establishDate) : '无数据'">xxx年x月x日</span>
                                </li>
                                 <li>
                                    <span>所属行业：</span>
                                    <span id="industryName" v-text="pageInfo.baseInfo.industryName + '-' + pageInfo.baseInfo.industryChildName">无数据</span>
                                </li>
                                <li>
                                    <span>企业价值（自评）：</span>
                                    <span id="selfValue" v-text="valueFormate(pageInfo.baseInfo.selfValue)">0</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!--主营业务-->
                    <div class="mainBusiness">
                        <div class="mainBusinessTitle title_top">
                            <img src="../../assets/img/startup/zhuyingyewu.png" alt="" class="icon_ icon_2">
                            <p>主营业务</p>
                        </div>
                        <div class="mainBusinessCont">
                            <p class="bussinessDesc" v-text="pageInfo.baseInfo.bussinessDesc">无数据</p>
                        </div>
                    </div>
                    <!-- 发展情况 + 企业家素质 -->
                    <div class="productAndGroup">
                        <!-- 发展情况 -->
                        <div class="product">
                            <div class="product_Title title_top" style="margin: 0;">
                                <img src="../../assets/img/startup/chanpin.png" alt="心流-发展情况" class="icon_ icon_3">
                                <p>发展情况</p>
                            </div>
                            <div class="productCont">
                                <ul>
                                    <li>
                                        <span>所处周期：</span>
                                        <span class="productStage">成长期</span>
                                    </li>
                                    <li>
                                        <span>产品推广：</span>
                                        <span class="productType" v-text="pageInfo.baseInfo.extendMarketFlag == 1 ? '已推广到市场' : '未推广到市场'"></span>
                                    </li>
                                    <li>
                                        <span>盈亏情况：</span>
                                        <span class="clientFeelback" v-text="pageInfo.profitLoss?pageInfo.profitLoss:'无数据'"></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>1</span>
                </div>
            </div>
            <!--第三页-->
            <div class="page page-three" id="page-three">
                <!-- 页面内容-->
                <div class="page-content">
                    <div class="financingSituation">
                        <div class="situationTitle title_top">
                            <img src="../../assets/img/startup/rongzi.png" alt="" class="icon_ icon_5">
                            <p>企业价值(自评)变化</p>
                        </div>
                        <div class="situationCont">
                            <ul>
                                <li>
                                    <span>本期自评：</span>
                                    <span class="financingLimit" v-text="pageInfo.baseInfo.selfValue ? valueFormate(pageInfo.baseInfo.selfValue) : '无数据'">无数据</span>
                                </li>
                                <li>
                                    <span>上期自评：</span>
                                    <span class="concessionStock" v-text="pageInfo.lastSelfValue ? valueFormate(pageInfo.lastSelfValue) : '无数据'">无数据</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- 股权结构 -->
                    <div class="stockStructure title_top">
                        <div class="structureTitle">
                            <img src="../../assets/img/startup/guquan.png" alt="" class="icon_ icon_6">
                            <p>股权结构</p>
                        </div>
                        <Draw2DPieCanvas :id="'stock'" v-if="initStatus" :chartData="pageInfo.baseInfo.holderRation"/>
                    </div>
                    <!-- 心流图谱 -->
                    <div class="flowDiagnose">
                        <div class="flowDiagnoseTitle title_top">
                            <img src="../../assets/img/guzhi/xinliuPic.png" alt="" class="icon_ icon1">
                            <p>估值图谱</p>
                        </div>
                        <TraceRadarCanvas :chartData="pageInfo.question" :id="'flowValuation'" v-if="initStatus"/>
                    </div>
                    <!-- 财务分析 -->
                    <div class="financeAnalyze">
                        <div class="financeAnalyzeTitle title_top">
                            <img src="../../assets/img/guzhi/caiwufenxi.png" alt="" class="icon_ icon7">
                            <p>财务分析</p>
                        </div>
                        <TraceRadarCanvas :chartData="pageInfo.financial" :id="'finAnalysis'" v-if="initStatus"/>
                    </div>
                </div>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>2</span>
                </div>
            </div>
            <!--第四页-->
            <div class="page page-four" id="page-four">
                <!-- 页面内容-->
                <div class="page-content">
                    <!--相对估值法-->
                    <div class="page-text">
                        <div class="relativeValuationTitle title_top">
                            <img src="../../assets/img/guzhi/relativePic.png" alt="" class="icon_">
                            <p>相对估值法</p>
                        </div>

                        <div class="page-text-content">
                            <div class="relavtive-container">
                                <div class="relavtive-left">
                                    <div class="relavtive-one">
                                        <DrawBarFinancialAnalysisRelativeX :id="'barOne'" v-if="initStatus" :chartData="pageInfo.relativeValuation.baseInfo" style="height:250px"/>
                                    </div>
                                    <div class="relavtive-two">
                                        <DrawBarFinancialAnalysisRelativeX :id="'barTwo'" v-if="initStatus" :chartData="pageInfo.relativeValuation.valuationMul" style="height:250px"/>
                                    </div>
                                </div>
                                <div class="relavtive-mid-container">
                                    <div class="relavtive-mid"></div>
                                    <div class="amount"></div>
                                </div>
                                <div class="relavtive-right">
                                    <p>相对估值</p>
                                    <div class="relavtive-three">
                                        <DrawBarFinancialAnalysisRelativeY :id="'barThree'" v-if="initStatus" :chartData="pageInfo.relativeValuation.valuationValue" style="height:360px"/>
                                    </div>
                                </div>
                            </div>
                            <div class="clear"></div>
                            <div class="relative-result">
                                <p>(相对估值法)估值结果</p>
                                <span class="relaResult" v-text="valueFormate(pageInfo.relativeValuationValue)">12,122.45万元</span>
                            </div>
                            <div class="page-tip-container">
                                <ul>
                                    <li>
                                        <label>1、</label>
                                        <p class="tipStrOne" id="tipResultStr" v-text="relativeText">
                                        </p>
                                    </li>
                                    <li>
                                        <label>2、</label>
                                        <p>相对估值法是根据“上市公司比较法”（Guideline Public Company Method，GPCM），以可比公司在市场上的当前定价为基础，评估企业价值。</p>
                                    </li>
                                    <li>
                                        <label>3、</label>
                                        <p>市盈率、市净率、市销率、市现率所取数值为系统对A股、新三板、港股、纳斯达克等板块数据进行分行业整理、计算得出，根据企业 所处行业分类选取。其他资料详见附件。</p>
                                    </li>
                                    <li>
                                        <label>4、</label>
                                        <p>当市盈率法、市净率法、市销率法、市现率法的估值结果为负值或空值，则该估值指标不参与计算。</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>3</span>
                </div>
            </div>
            <!--第五页-->
            <div class="page page-five" id="page-five">
                <!-- 页面内容-->
                <div class="page-content">
                    <!--绝对估值法标题-->
                    <div class="absoluteValuation">
                        <div class="absoluteValuationTitle title_top">
                            <img src="../../assets/img/guzhi/absolutePic.png" alt="" class="icon_">
                            <p>绝对估值法</p>
                        </div>
                    </div>
                    <!--表格部分-->
                    <div class="table_area">
                        <DrawPileBar :id="'cashFlowforecast'" class="cash-flow" :chartData="pageInfo.absoluteValuation" :year="nowYear" v-if="initStatus" style="height: 480px"/>
                        <div class="cash-flow center-arrow">
                            <img src="../../assets/img/report/equal@2x.png" alt="线上估值-心流" />
                        </div>
                        <DrawBarLine :id="'cashFlowTrend'" class="cash-flow" :chartData="pageInfo.absoluteValuation" :year="nowYear" v-if="initStatus" style="height: 480px"/>
                    </div>
                    <!--虚线框部分-->
                    <div class="dashes-line"></div>
                    <!--箭头指示-->
                    <div class="arrow-down">
                        <p>WACC<br /><span></span></p>
                    </div>
                    <div class="border-area">
                        <p>(绝对估值法)估值结果</p>
                        <p class="result-absolute" v-text="pageInfo.absoluteValuation.ggm ? valueFormate(pageInfo.absoluteValuation.ggm):''"></p>
                    </div>
                    <div class="explain-container">
                        1、绝对估值法：系统采用DCF(Discount Cash Flow)中FCFF(Free Cash Flow to Firm)模型和GGM (Gorden Growth &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Model)模型相结合，对企业无杠杆自由现金流及终值进行折现，继而对企业进行价值评估。
                        <br /> 2、在对企业终值测算时，系统采用GGM(Gorden Growth Model)模型。
                        <br /> 3、WACC(加权平均资本成本)是系统综合考虑企业所在行业特性，杠杆比率，股权成本，债务成本等因素后，计算出 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;的总资本成本。<br /> 4、当FCFF计算估值结果为负值时，则该估值方法不适用。
                    </div>
                </div>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>4</span>
                </div>
            </div>
            <!--第六页-->
            <div class="page page-six" id="page-six">
                <!-- 页面内容-->
                <div class="page-content">
                    <div class="valueResultTitle title_top">
                        <img src="../../assets/img/guzhi/valueResult.png" class="icon_" alt="">
                        <p>估值结果</p>
                    </div>
                    <div class="valuation-result-main">
                        <p>估值结果</p>
                        <p id="valuResult" v-text="resultFormate(pageInfo.minValue, pageInfo.maxValue)"></p>
                    </div>
                    <TraceLineCanvas :chartData="pageInfo.valuationDesc" :id="'ValuationResultChart'" v-if="pageInfo.name" style="width:746px;height:363px;margin:54px auto;margin-top:75px;"/>
                    <p style="font-size:16px;">
                        <span style="color:red;">注：</span>
                        <span>此处所取每年估值结果均为当年最后一次估值的结果</span>
                    </p>
                    <div class="valuResultText" v-text="valuResultText">
                        2016 年-2018 年，估值结果整体呈上升趋势。此次为 2018 年价值追踪报告(报告基准日 2017 年 12 月 31 日)，本次追踪估值结果为 XXX-XXX 亿元。 影响估值结果变化的主要因素包括:企业财务预测变化、市场行情 变化、软性指标得分变化、财务状况变化。市场行情包括:企业所处行业价值比率、费率、风险溢价、风险系数、无风险收益率等。
                    </div>
                    <div class="value_top title_top">
                        <img src="../../assets/img/report/fenxi-3.png" class="icon_" alt="">
                        <p>估值结果分析</p>
                    </div>
                    <p class="smalltitle">(一) 估值结果明细</p>
                    <div class="units-title">
                        <p></p>
                        <span class="unit ">单位:万元</span>
                    </div>
                    <table border="1" cellspacing="0" cellpadding="0" class="appraisementTable" id="valuationSubsidiary">
                        <tbody>
                            <tr>
                                <td colspan='4' class="colspan">估值结果明细表</td>
                            </tr>
                            <tr>
                                <td>项目</td>
                                <td v-for="(item,index) in pageInfo.valuationDesc.years" :key="index">{{item}}</td>
                            </tr>
                            <tr v-for="(item,index) in pageInfo.valuationAnalysis.valuationValues" :key="index + 1">
                                <td>{{item.name}}</td>
                                <td v-for="(itemChild,indexChild) in item.value" :key="indexChild + 2">{{formatCurrency.formateNum(itemChild,2)}}</td>
                            </tr>
                            <tr>
                                <td colspan='4' class="colspan">
                                    <span style="color:red;">注</span><span>:数据来源为历史估值数据及本次追踪数据</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>5</span>
                </div>
            </div>
            <!--第七页-->
            <div class="page page-seven " id="page-seven">
                <!-- 页面内容-->
                <div class="page-content">
                    <p class="smalltitle" style="margin-bottom:20px;">(二) 价值比率及财务预测分析</p>
                    <p style="font-size:18px;text-indent:2em;"> 价值比率的变化反映了企业目标市场对应二级行业的市场行情变化;财务预测变化反映了企业对自身发展与经营预期的变化。</p>
                    <p class="lesserTitle">( 1 ) 价值比率变化</p>
                    <div class="value_ratio">
                        <!-- 市盈率 -->
                        <TraceHistogram :id="'PERatio'" v-if="initStatus" :chartData="pageInfo.valuationAnalysis"/>
                        <!-- 市净率 -->
                        <TraceHistogram :id="'PBRatio'" v-if="initStatus" :chartData="pageInfo.valuationAnalysis"/>
                        <!-- 市销率 -->
                        <TraceHistogram :id="'salesRatio'" v-if="initStatus" :chartData="pageInfo.valuationAnalysis"/>
                        <!--  市现率 -->
                        <TraceHistogram :id="'cityRate'" v-if="initStatus" :chartData="pageInfo.valuationAnalysis"/>
                    </div>
                    <p style="margin:20px 0;">数据来源:历史估值所使用的行业倍数及本年度行业倍数</p>
                    <p class="lesserTitle">( 2 ) 财务预测变化</p>
                    <div class="units-title">
                        <p>
                            <span class="rhombus"></span>
                            <span>财务预测变化</span>
                        </p>
                        <span class="unit ">单位:万元</span>
                    </div>
                    <table border="1" cellspacing="0" cellpadding="0" class="appraisementTable" id="financialForecasting">
                        <tbody>
                            <tr>
                                <td colspan='4'>财务预测变化表</td>
                            </tr>
                            <tr>
                                <td>项目</td>
                                <td v-for="(item,index) in pageInfo.valuationAnalysis.years" :key="index + 3">{{item}}</td>
                            </tr>
                            <tr v-for="(item,index) in pageInfo.valuationAnalysis.financeForeCast" :key="index + 4">
                                <td>{{item.name}}</td>
                                <td v-for="(itemChild,indexChild) in item.value" :key="indexChild">{{formatCurrency.formateNum(itemChild,2)}}</td>
                            </tr>
                            <tr>
                                <td colspan='4'>
                                    <span style="color:red;">注：</span><span class="annotation" v-text="financialForecastingText"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="font-size:18px;margin: 20px 0;">
                        <span class="rhombus"></span>
                        <span>财务预测变化</span>
                    </div>
                    <div class="valueResultTitle title_top">
                        <img src="../../assets/img/startup/shouru.png" alt="" class="icon_">
                        <p>营业收入预测</p>
                    </div>
                    <!-- 营业收入预测 -->
                    <Draw3DLineCanvas2item :id="'operatingReceipt'" v-if="initStatus && pageInfo.incomeForecast.netArr.length == 2" :chartData="pageInfo.incomeForecast"/>

                    <Draw3DLineCanvas3item :id="'operatingReceipt'" v-if="initStatus && pageInfo.incomeForecast.netArr.length == 3" :chartData="pageInfo.incomeForecast"/>
                    <p>
                        <span style="color:red;">注：</span><span class="incomefont" v-text="incomefont"></span>
                    </p>
                </div>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>6</span>
                </div>
            </div>
            <!--第八页-->
            <div class="page page-eight " id="page-eight"> 
                <!-- 页面内容-->
                <div class="page-content">
                    <div class="valueResultTitle title_top">
                        <img src="../../assets/img/guzhi/jinglirun.png" alt="" class="icon_">
                        <p>净利润预测</p>
                    </div>
                    <!-- 净利润预测 -->
                    <Draw3DLineCanvas2item :id="'netMargin'" v-if="initStatus && pageInfo.netProfitForecast.netArr.length == 2" :chartData="pageInfo.netProfitForecast"/>
                    
                    <Draw3DLineCanvas3item :id="'netMargin'" v-if="initStatus && pageInfo.netProfitForecast.netArr.length == 3" :chartData="pageInfo.netProfitForecast"/>
                    <div class="valuation-result">
                        <p>
                            <span style="color:red;">注：</span><span class="netfont" v-text="netfont"></span>
                        </p>
                    </div>
                    <div>
                        <p>
                            <span style="margin-top:7px;margin-right:10px;">
                                <img src="../../assets/img/trace/questionMark.png" alt="价值追踪报告-心流">
                            </span>
                            <span style="font-size:18px;">说明：根据企业提供的历史财务数据，结合国际通用的财务预测方法及规则，对企业未来五年营业收入、净利润进行预测。</span>
                        </p>
                    </div>
                    <div class="valueResultTitle title_top value_top">
                        <img src="../../assets/img/report/fenxi-2.png" class="icon_" alt="">
                        <p>价值追踪分析要素</p>
                    </div>
                    <p class="smalltitle">(一)企业综合竞争力变化分析</p>
                    <p style="text-indent:2em;font-size:18px;margin-bottom: 20px;" class="enterprisetitle" v-text="enterPriseTitle"> 2018 年企业综合竞争力得分为 88 分，与 2017 年相比显著提高。2018 年企业各项指标得分分别为:行 业环境 XX 分、产品竞争力 XX 分、技术竞争力 XX 分、管理团队情况 XX 分、风险管理 XX 分。</p>
                    <table border="1" cellspacing="0" cellpadding="0" class="appraisementTable" id="competitiveness">
                        <tbody>
                            <tr>
                                <td colspan='4' class="colspan">企业综合竞争力变化表</td>
                            </tr>
                            <tr>
                                <td>指标项 </td>
                                <td v-for="(item,index) in pageInfo.years" :key="index + 6">{{item}}</td>
                            </tr>
                            <tr class="competitivenessone" v-for="(item,index) in pageInfo.questionTotalAnalysis.curQuestion" :key="index + 7">
                                <td style="padding-left: 0;text-align: center;" >{{item.name}}</td>
                                <td v-for="(itemChild,indexChild) in item.value" :key="indexChild + 2">{{formatCurrency.formateNum(itemChild,2)}}
                                     <img class="upimg" src="../../assets/img/trace/up.png" alt="价值追踪报告-心流" v-if="indexChild != 0"  v-show="Number(pageInfo.questionTotalAnalysis.curQuestion[index].value[0]) < Number(pageInfo.questionTotalAnalysis.curQuestion[index].value[1])">
                                    <img class="downimg" src="../../assets/img/trace/down.png" alt="价值追踪报告-心流" v-if="indexChild != 0" v-show="Number(pageInfo.questionTotalAnalysis.curQuestion[index].value[0]) > Number(pageInfo.questionTotalAnalysis.curQuestion[index].value[1])">
                                </td>
                            </tr>
                            <tr>
                                <td colspan='4' class="colspan">
                                    <span style="width:0;display: inline;">数据来源:历史估值分析数据及本次软性指标数据</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="font-size:18px;margin: 20px 0;">
                        <span class="rhombus"></span>
                        <span>具体变化情况如下:</span>
                    </div>
                </div>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>7</span>
                </div>
            </div>
            <!--第九页-->
            <div class="page page-nine " id="page-nine">
                <!-- 页面内容-->
                <div class="page-content">
                    <div class="industryEnvironment">
                        <p style="margin-top: 0;">专业化（主营业务专业专注）</p>
                        <div>
                            <DrawCutoffrule :id="'industryEnvironmentOne'" v-if="initStatus" :chartData="industryEnvironmentOne"/>
                            <div id="industryEnvironmentOne"></div>
                        </div>
                    </div>
                    <p class="font" id="industryfontone" v-text="pageInfo.industry[6].titleDesc" v-if="initStatus"></p>
                    <p class="font" id="industryfonttwo" v-text="industryStatements"></p>
                    <div class="industryEnvironment">
                        <p>精细化（经营管理精细高效）</p>
                        <div>
                            <DrawCutoffrule :id="'productCompetitiveness'" v-if="initStatus" :chartData="productCompetitiveness"/>
                        </div>
                    </div>
                    <p class="font" id="productfontone" v-text="pageInfo.competitivePower[2].titleDesc" v-if="initStatus"></p>
                    <p class="font" id="productfonttwo" v-text="competitivePowerStatements"></p>
                </div>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>8</span>
                </div>
            </div>
            <!--第十页-->
            <div class="page page-ten " id="page-ten">
                <!-- 页面内容-->
                <div class="page-content">
                    <div class="industryEnvironment">
                        <p style="margin-top: 0;">特色化（产品服务独具特色）</p>
                        <div>
                            <DrawCutoffrule :id="'managementTeam'" v-if="initStatus" :chartData="managementTeam"/>
                        </div>
                    </div>
                    <p class="font" id="managementfontone" v-text="pageInfo.teamManage[2].titleDesc" v-if="initStatus"></p>
                    <p class="font" id="managementfontwo" v-text="teamManageStatements"></p>
                    <div class="industryEnvironment">
                        <p>新颖化（创新能力成果显著）</p>
                        <div>
                            <DrawCutoffrule :id="'technological'" v-if="initStatus" :chartData="technological"/>
                        </div>
                    </div>
                    <p class="font" id="technologicalfontone" v-text="pageInfo.technology[2].titleDesc" v-if="initStatus"></p>
                    <p class="font" id="technologicalfontwo" v-text="technologyStatements"></p>
                </div>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>9</span>
                </div>
            </div>
            <!--第十一页-->
            <div class="page page-eleven " id="page-eleven">
                <!-- 页面内容-->
                <div class="page-content">
                    <p class="smalltitle" style="margin:50px 0 20px 0;">(二) 企业财务状况变化分析</p>
                    <p class="font" id="financfont"></p>
                    <table border="1" cellspacing="0" cellpadding="0" class="appraisementTable" id="financialIndex" style="margin-top:30px;">
                        <tbody>
                            <tr>
                                <td colspan='4'>企业财务指标变化表</td>
                            </tr>
                            <tr>
                                <td>指标项 </td>
                                <td v-for="(item,index) in pageInfo.years" :key="index + 8">{{item}}</td>
                            </tr>
                            <tr class="financialIndexOne" v-for="(item,index) in pageInfo.financeTotalAnalysis.curFinance" :key="index">
                                <td>{{item.name}}</td>
                                <td v-for="(itemChild,indexChild) in item.value" :key="indexChild + 10">
                                    {{formatCurrency.formateNum(itemChild,2)}}
                                    <img class="upimg" src="../../assets/img/trace/up.png" alt="价值追踪报告-心流" v-if="indexChild != 0" v-show="Number(pageInfo.financeTotalAnalysis.curFinance[index].value[0]) < Number(pageInfo.financeTotalAnalysis.curFinance[index].value[1])">
                                    <img class="downimg" src="../../assets/img/trace/down.png" alt="价值追踪报告-心流" v-if="indexChild != 0" v-show="Number(pageInfo.financeTotalAnalysis.curFinance[index].value[0]) > Number(pageInfo.financeTotalAnalysis.curFinance[index].value[1])">
                                </td>
                            </tr>
                            <tr>
                                <td colspan='4'>
                                    <span style="color:red;display: inherit;width: 0;">注</span><span style="display: inherit;width: 99%;text-align: left;">:数据来源历史估值报告、财务数据及本次追踪数据，此表格年份为每次估值报告基准日年份。</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="title_top">
                        <img src="../../assets/img/startup/zhichu.png" alt="" class="icon_">
                        <p>偿债能力</p>
                    </div>
                    <div>
                        <p class="lesserTitle">( 1 ) 资产负债率</p>
                        <DrawStereomixedMap :id="'assetLiabilityRatio'" v-if="initStatus" :chartData="pageInfo.liabilitiesRatio"/>
                        <p class="font" id="assetfontone" v-text="liabilitiesRatioStatement"></p>
                        <p class="font" id="assetfontwo" v-for="(item,index) in liabilitiesRatioStatementArr" :key="index + 11">{{item}}</p>
                    </div>
                </div>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>10</span>
                </div>
            </div>
            <!--第十二页-->
            <div class="page page-twelve " id="page-twelve ">
                <!-- 页面内容-->
                <div class="page-content">
                    <div>
                        <p class="lesserTitle">( 2 ) 流动比率</p>
                        <DrawStereomixedMap :id="'liquidityRatio'" v-if="initStatus" :chartData="pageInfo.flowRation"/>
                        <p class="font" id="liquidityone" v-text="flowRationStatement"></p>
                        <p class="font" id="liquiditytwo" v-for="(item,index) in flowRationStatementArr" :key="index + 12">{{item}}</p>
                    </div>
                    <div>
                        <p class="lesserTitle" style="margin-top: 110px;">( 3 ) EBIT 利息保障倍数</p>
                        <DrawStereomixedMap :id="'EBIT'" v-if="initStatus" :chartData="pageInfo.ebitMultiple"/>
                        <p class="font" id="EBITone" v-text="ebitMultipleStatement"></p>
                        <p class="font" id="EBITwo" v-for="(item,index) in ebitMultipleStatementArr" :key="index + 13">{{item}}</p>
                    </div>
                </div>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>11</span>
                </div>
            </div>
            <!--第十三页-->
            <div class="page page-thirteen " id="page-thirteen">
                <!-- 页面内容-->
                <div class="page-content">
                    <div class="title_top value_top" style="margin-top: 0;">
                        <img src="../../assets/img/guzhi/yinglifenxi.png" alt="" class="icon_">
                        <p>盈利能力</p>
                    </div>
                    <div>
                        <p class="lesserTitle">( 1 ) 毛利率</p>
                        <DrawStereomixedMap :id="'grossProfitRate'" v-if="initStatus" :chartData="pageInfo.grossProfitRatio"/>
                        <p class="font" id="grossone" v-text="grossProfitRatioStatement"></p>
                        <p class="font" id="grosstwo" v-for="(item,index) in grossProfitRatioStatementArr" :key="index + 14">{{item}}</p>
                    </div>
                    <div>
                        <p class="lesserTitle" style="margin-top: 110px;">( 2 ) 净利润率</p>
                        <DrawStereomixedMap :id="'netProfitRate'" v-if="initStatus" :chartData="pageInfo.netProfitRatio"/>
                        <p class="font" id="netProfitone" v-text="netProfiltRatioStatement"></p>
                        <p class="font" id="netProfitwo" v-for="(item,index) in netProfiltRatioStatementArr" :key="index + 15">{{item}}</p>
                    </div>
                </div>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>12</span>
                </div>
            </div>
            <!--第十四页-->
            <div class="page page-fourteen " id="page-fourteen">
                <!-- 页面内容-->
                <div class="page-content">

                    <!--成长能力-->
                    <div class="page-text">
                        <div class="title_top value_top" style="margin-top: 0;">
                            <img src="../../assets/img/guzhi/chengzhangfenxi.png" alt="" class="icon_">
                            <p>成长能力</p>
                        </div>
                        <div class="finanIndex operationContent">
                            <p class="lesserTitle">( 1 ) 主营业务收入增长率</p>
                            <!-- 图-->
                            <DrawStereomixedMap :id="'incomeAbiltyChart'" v-if="initStatus" :chartData="pageInfo.mainIncomeRatio"/>
                            <!--描述-->
                            <div class="incomeAbiltyDescribe describe">
                                <p class="font" v-text="mainIncomeRatioStatement"></p>
                                <p class="font" v-for="(item,index) in mainIncomeRatioStatementArr" :key="index + 15">{{item}}</p>
                            </div>
                        </div>
                        <div>
                            <p class="lesserTitle" style="margin-top: 110px;">( 2 ) 净资产收益率</p>
                            <!-- 图-->
                            <DrawStereomixedMap :id="'netProfit'" v-if="initStatus" :chartData="pageInfo.netAssetRatio"/>
                            <!--描述-->
                            <div class="netProfitDescribe describe">
                                <p class="font" v-text="netAssetRatioStatement"></p>
                                <p class="font" v-for="(item,index) in netAssetRatioStatementArr" :key="index + 16">{{item}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>13</span>
                </div>
            </div>
            <!--第十五页-->
            <div class="page page-fifteen" id="page-fifteen">
                <!-- 页面内容-->
                <div class="page-content">
                    <!-- 净利润率 -->

                    <!--运营能力（应收账款周转率）-->
                    <div class="page-text">
                        <div class="title_top value_top" style="margin-top: 0;">
                            <img src="../../assets/img/guzhi/yunyingfenxi.png" alt="" class="icon_">
                            <p>运营能力</p>
                        </div>
                        <div class="finanIndex operationContent">
                            <p class="lesserTitle">( 1 ) 应收账款周转率</p>
                            <!-- 图-->
                            <DrawStereomixedMap :id="'receivables'" v-if="initStatus" :chartData="pageInfo.receivablesTurnoverRatio"/>
                            <!--描述-->
                            <div class="receivablesDescribe describe">
                                <p class="font" v-text="receivablesTurnoverRatioStatement"></p>
                                <p class="font" v-for="(item,index) in receivablesTurnoverRatioStatementArr" :key="index + 17">{{item}}</p>
                            </div>
                        </div>
                        <div class="page-text">
                            <div class="finanIndex operationContent">
                                <p class="lesserTitle" style="margin-top: 110px;">( 2 ) 存货周转率</p>
                                <!-- 图-->
                                <DrawStereomixedMap :id="'inventoryTurnover'" v-if="initStatus" :chartData="pageInfo.inventoryTurnoverRatio"/>
                                <!--描述-->
                                <div class="inventoryTurnoverDescribe describe">
                                    <p class="font" v-text="inventoryTurnoverRatioStatement"></p>
                                    <p class="font" v-for="(item,index) in inventoryTurnoverRatioStatementArr" :key="index + 18">{{item}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>14</span>
                </div>
            </div>
            <!--杜邦分析页  第十六页-->
            <div class="page page-dupon" id="page-dupon">
                <DupontAnalyze v-if="initStatus" :versions="0" :dupontData="pageInfo.dubangAnalysis"/>
                <!--页脚-->
                <div class="pageNum">
                    <p>///</p><span>15</span>
                </div>
            </div>
            <!--第十七页-->
            <div class="page page-seventeen" id="page-seventeen">
                <!-- 页面内容-->
                <LibTableComponent1 :liaTableInfo="liaTableInfo" v-if="initStatus" :year="liaTableYear" :versions="'trace'"/>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>16</span>
                </div>
            </div>
            <!--第十八页-->
            <div class="page page-eighteen" id="page-eighteen">
                <LibTableComponent2 :liaTableInfo="liaTableInfo" v-if="initStatus" :year="liaTableYear" :versions="'trace'"/>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>17</span>
                </div>
            </div>
            <!--第十九页-->
            <div class="page page-twenty" id="page-twenty">
                <ProfitTableComponent1 :profitTableInfo="profitTableInfo" v-if="initStatus" :year="profitYear" :versions="'trace'"/>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>18</span>
                </div>
            </div>
            <!--第二十页-->
            <div class="page page-twentyOne" id="page-twentyOne">
                <ProfitTableComponent2 :profitTableInfo="profitTableInfo" v-if="initStatus" :year="profitYear" :financeRatio="[]" :versions="'trace'"/>
                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span>19</span>
                </div>
            </div>
            <!--第二十一页-->
            <div class="page page-twentytwo" id="page-twentytwo">
                <!-- 页面内容-->
                <div class="page-contentpage-last page-content">

                    <img src="../../assets/img/report/map@2x.png" alt="价值追踪报告-心流" />


                    <div class="flow-statement ">
                        <img src="../../assets/img/logo-08.svg" alt="价值追踪报告-心流" />
                        <ul>
                            <li>
                                <div class="statement-tit ">
                                    <span class="xian "></span>
                                    <img src="../../assets/img/report/statement-01@2x.png" alt="价值追踪报告-心流" />
                                    <p>价值追踪声明</p>
                                    <img src="../../assets/img/report/statement-01@2x.png" alt="价值追踪报告-心流" />
                                    <span class="xian "></span>
                                </div>
                                <div class="statement-main ">
                                    本报告由系统自动生成。该系统依据企业提供的财务数据和软性指标，结 合具备可靠性水平的数据库， 根据通用的行业业绩及财务预测逻辑和规则，对企业价值所需要的参数进行预测 和估算;结合企业历史估值数据对企业估值结果变化进行追 踪分析。企业提供资料的完整度和准确性对最终估 值结果有重要影响。请本报告使用者知悉。
                                </div>
                            </li>
                            <li>
                                <div class="statement-tit ">
                                    <span class="xian "></span>
                                    <img src="../../assets/img/report/statement-01@2x.png" alt="价值追踪报告-心流" />
                                    <p>免责声明</p>
                                    <img src="../../assets/img/report/statement-01@2x.png" alt="价值追踪报告-心流" />
                                    <span class="xian "></span>
                                </div>
                                <div class="statement-main ">
                                    系统所采用的数据信息来源于企业自行提供的资料和公开渠道可获取的行业信息和数据，并基于前述资料属实、 合法的假设出具本报告，网站亦不对前述资料的准确性、完整性负有实质审核的义务，本报告仅作为对企业整体价值衡量的参考，非并 购、转让等交易的直接证据，网站及其运营方不承担因信赖本报告信息而采取行动所导致的任何损失及一切法律责任。
                                </div>
                            </li>
                            <li>
                                <div class="statement-tit ">
                                    <span class="xian "></span>
                                    <img src="../../assets/img/report/statement-01@2x.png" alt="价值追踪报告-心流" />
                                    <p>心流慧估</p>
                                    <img src="../../assets/img/report/statement-01@2x.png" alt="价值追踪报告-心流" />
                                    <span class="xian "></span>
                                </div>
                                <div class="statement-main lastStatement-main">
                                    <p>
                                    	北京心流慧估科技有限公司是国内外领先的投行AIGC公司，专注于用AI技术为中国众多中小企业提供“估值+组合式金融服务”，通过自主研发的曼殊AI（Manju AI）系列软件产品，从发现、培育优质股权，再到资本对接，引金融之水精确“滴灌”，低成本、高效率地为中小企业走进资本市场提供定制化解决方案
                                    </p>
                                    <p>
                                    	心流慧估运用人工智能技术和多种统计分析方法，将国际通用估值技术结合心流慧估独创软性指标体系，搭建出完善的股权估值模型和算法模型。采用AIGC技术将估值模型、对标企业、指标权重、语句分析自动匹配并实时在线生成完整专业报告，不仅能科学、客观、公允地评估企业价值，还能帮助企业更好地进行融资规划、财务系统建设、企业管理规范，提高企业决策和融资效率。
                                    </p>
                                    <div>
                                        <img src="../../assets/img/wechartCode.jpg" alt="">
                                        <ul>
                                            <li><span>网址</span>：www.flcccc.com</li>
                                            <li><span>客服电话</span>：17610076778 &nbsp; 010-8444-6599</li>
                                            <li><span>微信号</span>：flcccc888</li>
                                            <li><span>地址</span>：北京市朝阳区亮马桥路甲40号二十一世纪大厦B座3层303-305</li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- 页脚-->
                <div class="pageNum">
                    <p>///</p><span class="bottomofPage">20</span>
                </div>
            </div>

            <!--</div>-->
        </div>
        <div id="loading" style="display: none;">
            <div class='base'>
                <div class='cube'></div>
                <div class='cube'></div>
                <div class='cube'></div>
                <div class='cube'></div>
                <div class='cube'></div>
                <div class='cube'></div>
                <div class='cube'></div>
                <div class='cube'></div>
                <div class='cube'></div>
            </div>
        </div>
    </div>
</template>
<script>
import qs from 'qs'
import '../../assets/css/loading.css'
import formatCurrency from '../../assets/js/numberToCurrency'
import PdfOnload from '../../utils/pdf'
import Draw2DPieCanvas from '../../common/echarts/draw2DPieCanvas.vue'
import TraceRadarCanvas from '../../common/echarts/traceRadarCanvas.vue'
import DrawBarFinancialAnalysisRelativeX from '../../common/echarts/drawBarFinancialAnalysisRelativeX.vue'
import DrawBarFinancialAnalysisRelativeY from '../../common/echarts/drawBarFinancialAnalysisRelativeY.vue'
import DrawPileBar from '../../common/echarts/drawPileBar.vue'
import DrawBarLine from '../../common/echarts/drawBarLine'
import TraceLineCanvas from '../../common/echarts/traceLineCanvas.vue'
import TraceHistogram from '../../common/echarts/traceHistogram.vue'
import Draw3DLineCanvas2item from '../../common/echarts/draw3DLineCanvas2item.vue'
import Draw3DLineCanvas3item from '../../common/echarts/draw3DLineCanvas3item.vue'
import DrawCutoffrule from '../../common/echarts/drawCutoffrule.vue'
import DrawStereomixedMap from '../../common/echarts/drawStereomixedMap.vue'
import DupontAnalyze from '../../common/reportComponent/dupontAnalyze.vue'
import LibTableComponent1 from '../../common/reportComponent/liaTableComponent1.vue'
import LibTableComponent2 from '../../common/reportComponent/liaTableComponent2.vue'
import ProfitTableComponent1 from '../../common/reportComponent/profitTableComponent1.vue'
import ProfitTableComponent2 from '../../common/reportComponent/profitTableComponent2.vue'


export default ({
    name: "traceValuationReport",
    components: {
        Draw2DPieCanvas,
        TraceRadarCanvas,
        DrawBarFinancialAnalysisRelativeX,
        DrawBarFinancialAnalysisRelativeY,
        DrawPileBar,
        DrawBarLine,
        TraceLineCanvas,
        TraceHistogram,
        Draw3DLineCanvas2item,
        Draw3DLineCanvas3item,
        DrawCutoffrule,
        DrawStereomixedMap,
        DupontAnalyze,
        LibTableComponent1,
        LibTableComponent2,
        ProfitTableComponent1,
        ProfitTableComponent2
    },
    data() {
        return {
            reportId: this.$route.query.reportId ? this.$route.query.reportId : "", 
            pageInfo: {
                baseInfo: {
                    companyName: "",
                    establishDate: ""
                },
                absoluteValuation: {
                    ggm: ""
                },
                valuationDesc: {
                    years: []
                },
                valuationAnalysis: {
                    valuationValues: []
                },
                questionTotalAnalysis: {
                    curQuestion: []
                },
                financeTotalAnalysis: {
                    curFinance : []
                }
            },
            // 相对估值语句
            relativeText: "",
            // 
            nowYear: "",
            // 估值结果语句
            valuResultText: "",
            // 财务预测语句
            financialForecastingText: "",
            // 营业收入预测语句
            incomefont: "",
            // 净利润预测语句
            netfont: "",
            // 
            enterPriseTitle: "",
            // 专业化
            industryEnvironmentOne: {},
            industryStatements: "",
            // 精细化
            productCompetitiveness: {},
            competitivePowerStatements: "",
            // 特色化
            managementTeam: {},
            teamManageStatements: "",
            // 新颖化
            technological: {},
            technologyStatements: "",
            // 资产负债率 liabilitiesRatio
            liabilitiesRatioStatement: "",
            liabilitiesRatioStatementArr: [],
            // 流动比率 flowRation
            flowRationStatement: "",
            flowRationStatementArr: [],
            // EBIT利息保障倍数 ebitMultiple
            ebitMultipleStatement: "",
            ebitMultipleStatementArr: [],
            // 毛利率 grossProfitRatio
            grossProfitRatioStatement: "",
            grossProfitRatioStatementArr: [],
            // 净利润率 netProfitRatio
            netProfiltRatioStatement: "",
            netProfiltRatioStatementArr: [],
            // 主营业务收入增长率 mainIncomeRatio
            mainIncomeRatioStatement: "",
            mainIncomeRatioStatementArr: [],
            // 净资产收益率 netAssetRatio
            netAssetRatioStatement: "",
            netAssetRatioStatementArr: [],
            // 应收账款周转率 receivablesTurnoverRatio
            receivablesTurnoverRatioStatement: "",
            receivablesTurnoverRatioStatementArr: [],
            // 存货周转率 inventoryTurnoverRatio
            inventoryTurnoverRatioStatement: "",
            inventoryTurnoverRatioStatementArr: [],
            // 资产负债表
            liaTableInfo: [],
            liaTableYear: [],
            // 利润表
            profitTableInfo: [],
            profitYear: [],
            //
            pdfArr: [],
            pdfIndex: 0,
            projectName:''
        }
    },
    mounted(){
        this.projectName=this.domain.traceProject
        if(this.reportId){
            this.initPage();
            this.libTableInit();
            this.profitTableInit();
        }
    },
    methods: {
        // 导出pdf
        pdf: function(){
            document.getElementById("loading").style.display = "block";
            var title = this.pageInfo.baseInfo.companyName + '价值追踪报告.pdf'
            var pageArr = document.getElementsByClassName("page");
            for(var i = 0;i < pageArr.length;i++){
                this.pdfArr.push(pageArr[i].id)
            }
            PdfOnload.getPdf(this.pdfArr,this.pdfIndex, title);
        },
        // 页面初始化
        initPage: function(){
            var that = this;
            var queryObj = {
                reportId: that.reportId
            }
            that.$post(this.projectName+"traceReport/qryTraceReport",qs.stringify(queryObj)).then((res) => {
                var data = res.data;
                if(data.code == 200 && data.content){
                    that.pageInfo = data.content;
                    console.log(that.pageInfo);
                    that.pageInfo.years = JSON.parse(that.pageInfo.years)
                    // 股权
                    var holderArr = [];
                    for(var i in JSON.parse(that.pageInfo.baseInfo.holderRation)){
                        holderArr.push({
                            name:JSON.parse(that.pageInfo.baseInfo.holderRation)[i].name,
                            y:JSON.parse(that.pageInfo.baseInfo.holderRation)[i].value,
                        })
                    };
                    that.pageInfo.baseInfo.holderRation = holderArr;
                    // 估值图谱
                    if(that.pageInfo.question){
                        that.pageInfo.question = JSON.parse(that.pageInfo.question);
                    }
                    // 财务分析
                    if(that.pageInfo.financial){
                        that.pageInfo.financial = JSON.parse(that.pageInfo.financial);
                    }
                    // 相对估值
                    if(that.pageInfo.relativeValuation){
                        that.pageInfo.relativeValuation = JSON.parse(that.pageInfo.relativeValuation);
                        var relativeValueKeys = "";
                        var relativeValues = "";
                        for(var i = 0;i < that.pageInfo.relativeValuation.valuationValue.length;i++){
                            if(i != that.pageInfo.relativeValuation.valuationValue.length - 1){
                                relativeValueKeys += that.pageInfo.relativeValuation.valuationValue[i].name + "法、";
                                relativeValues += formatCurrency.formateNum(that.pageInfo.relativeValuation.valuationValue[i].value, 2) + "万元、";
                            } else {
                                relativeValues += formatCurrency.formateNum(that.pageInfo.relativeValuation.valuationValue[i].value, 2) + "万元，经调整后，最终相对估值企业估值为" + that.valueFormate(that.pageInfo.relativeValuationValue);
                                relativeValueKeys += that.pageInfo.relativeValuation.valuationValue[i].name + "法对企业的估值结果分别为：";
                            }
                        }
                        that.relativeText = relativeValueKeys + relativeValues;
                    };
                    // 绝对估值
                    if(that.pageInfo.absoluteValuation){
                        that.pageInfo.absoluteValuation = JSON.parse(that.pageInfo.absoluteValuation);
                        that.nowYear = parseInt(that.pageInfo.createTime.split("-")[0]);
                    }
                    // 估值结果
                    if(that.pageInfo.valuationDesc){
                        that.pageInfo.valuationDesc = JSON.parse(that.pageInfo.valuationDesc);
                        var lastyear = that.pageInfo.valuationDesc.years[that.pageInfo.valuationDesc.years.length - 1];
                        that.valuResultText = that.pageInfo.valuationDesc.years[0] + '年~' + lastyear + '年，估值结果整体' + that.pageInfo.valuationDesc.description + '。此次为' + lastyear +
                            '年价值追踪报告(报告基准日' + lastyear + '年 12 月 31 日)，本次追踪估值结果为' + that.valueFormate(that.pageInfo.minValue) + "~" + that.valueFormate(that.pageInfo.maxValue) + "。影响估值结果变化的主要因素包括:企业财务预测变化、市场行情 变化、软性指标得分变化、财务状况变化。市场行情包括:企业所处行业价值比率、费率、风险溢价、风险系数、无风险收益率等。"
                    };
                    // 估值结果分析
                    if(that.pageInfo.valuationAnalysis){
                        that.pageInfo.valuationAnalysis = JSON.parse(that.pageInfo.valuationAnalysis);
                        if (that.pageInfo.valuationAnalysis.years.length == 2) {
                            that.financialForecastingText = that.pageInfo.valuationAnalysis.years[0] + "为前一次估值企业填写的预测值，" + that.pageInfo.valuationAnalysis.years[1] + "为本次财务数据预测值，日期基于报告基准日。";
                        } else {
                            that.financialForecastingText = that.pageInfo.valuationAnalysis.years[0] + "、" + that.pageInfo.valuationAnalysis.years[1] + "均为前两次估值企业填写的预测值，" + that.pageInfo.valuationAnalysis.years[2] + "为本次财务数据预测值，日期基于报告基准日。";
                        };
                    };
                    // 营业收入预测
                    if(that.pageInfo.incomeForecast){
                        that.pageInfo.incomeForecast = JSON.parse(that.pageInfo.incomeForecast);
                        var icomyears = that.pageInfo.incomeForecast.years.length
                        if(that.pageInfo.incomeForecast.netArr.length == 2){
                            that.incomefont = '首次估值，预测阶段为' + that.pageInfo.incomeForecast.years[0] + '~' + that.pageInfo.incomeForecast.years[icomyears - 2] + '年；本次估值预测阶段为：' + that.pageInfo.incomeForecast.years[1] + '~' + that.pageInfo.incomeForecast.years[icomyears - 1] + '年。'
                        } else {
                            that.incomefont = '首次估值，预测阶段为' + that.pageInfo.incomeForecast.years[0] + '~' + that.pageInfo.incomeForecast.years[icomyears - 3] + '年；第二次估值预测阶段为：' + that.pageInfo.incomeForecast.years[1] + '~' + that.pageInfo.incomeForecast.years[icomyears - 2] + '年；本次估值预测阶段为：' + that.pageInfo.incomeForecast.years[2] + '~' + that.pageInfo.incomeForecast.years[icomyears - 1] + '年。'
                        };
                    };
                    // 净利润预测
                    if(that.pageInfo.netProfitForecast){
                        that.pageInfo.netProfitForecast = JSON.parse(that.pageInfo.netProfitForecast);
                        var netyears = that.pageInfo.netProfitForecast.years.length;
                        if(that.pageInfo.netProfitForecast.netArr.length == 2){
                            that.netfont = '首次估值，预测阶段为' + that.pageInfo.netProfitForecast.years[0] + '~' + that.pageInfo.netProfitForecast.years[netyears - 2] + '年；本次估值预测阶段为：' + that.pageInfo.netProfitForecast.years[1] + '~' + that.pageInfo.netProfitForecast.years[netyears - 1] + '年。'
                        } else {
                            that.netfont = '首次估值，预测阶段为' + that.pageInfo.netProfitForecast.years[0] + '~' + that.pageInfo.netProfitForecast.years[netyears - 3] + '年；第二次估值预测阶段为：' + that.pageInfo.netProfitForecast.years[1] + '~' + that.pageInfo.netProfitForecast.years[netyears - 2] + '年；本次估值预测阶段为：' + that.pageInfo.netProfitForecast.years[2] + '~' + that.pageInfo.netProfitForecast.years[netyears - 1] + '年。'
                        };
                    };
                    // 
                    if(that.pageInfo.questionTotalAnalysis){
                        that.pageInfo.questionTotalAnalysis = JSON.parse(that.pageInfo.questionTotalAnalysis);
                        var yearslength = that.pageInfo.years.length;
                        that.enterPriseTitle = that.pageInfo.questionTotalAnalysis.description + that.pageInfo.years[yearslength - 1] + '年企业各项指标得分分别为:' + 
                            (that.pageInfo.questionTotalAnalysis.curQuestion[0].name) + that.pageInfo.questionTotalAnalysis.curQuestion[0].value[yearslength - 1] + '分、' + 
                            that.pageInfo.questionTotalAnalysis.curQuestion[1].name + that.pageInfo.questionTotalAnalysis.curQuestion[1].value[yearslength - 1] + '分、' + 
                            that.pageInfo.questionTotalAnalysis.curQuestion[2].name + that.pageInfo.questionTotalAnalysis.curQuestion[2].value[yearslength - 1] + '分、' + 
                            that.pageInfo.questionTotalAnalysis.curQuestion[3].name + that.pageInfo.questionTotalAnalysis.curQuestion[3].value[yearslength - 1] + '分。';
                    };
                    // 专业化
                    if(that.pageInfo.industry){
                        that.pageInfo.industry = JSON.parse(that.pageInfo.industry);
                        var industrydata = [];
                        for (var i = 0; i < that.pageInfo.years.length; i++) {
                            industrydata.push({
                                type: 'bar',
                                name: that.pageInfo.years[i],
                                barWidth: '40',
                                data: [that.pageInfo.industry[0].value[i], that.pageInfo.industry[1].value[i], that.pageInfo.industry[2].value[i], that.pageInfo.industry[3].value[i], that.pageInfo.industry[4].value[i], that.pageInfo.industry[5].value[i]],
                                label: {
                                    normal: {
                                        show: true,
                                        position: 'top',
                                        textStyle: {
                                            color: '#000'
                                        },
                                        formatter: '{c}'
                                    }
                                },
                            })

                        }
                        that.industryEnvironmentOne = {
                            xdata: [that.pageInfo.industry[0].name, that.pageInfo.industry[1].name, that.pageInfo.industry[2].name, that.pageInfo.industry[3].name, that.pageInfo.industry[4].name, that.pageInfo.industry[5].name],
                            bottomtitle: that.pageInfo.years,
                            data: industrydata
                        };
                        for(var i = 0;i < 6;i++){
                            that.industryStatements += that.pageInfo.industry[i].description;
                        }
                    };
                    // 精细化
                    if(that.pageInfo.competitivePower){
                        that.pageInfo.competitivePower = JSON.parse(that.pageInfo.competitivePower);
                        var competitivedata = [];
                        for (var i = 0; i < that.pageInfo.years.length; i++) {
                            competitivedata.push({
                                type: 'bar',
                                name: that.pageInfo.years[i],
                                barWidth: '40',
                                data: [that.pageInfo.competitivePower[0].value[i], that.pageInfo.competitivePower[1].value[i]],
                                label: {
                                    normal: {
                                        show: true,
                                        position: 'top',
                                        textStyle: {
                                            color: '#000'
                                        },
                                        formatter: '{c}'
                                    }
                                },
                            })
                        }
                        that.productCompetitiveness = {
                            xdata: [that.pageInfo.competitivePower[0].name, that.pageInfo.competitivePower[1].name],
                            bottomtitle: that.pageInfo.years,
                            data: competitivedata
                        };
                        for(var i = 0;i < 2;i++){
                            that.competitivePowerStatements += that.pageInfo.competitivePower[i].description;
                        }
                    };
                    // 特色化
                    if(that.pageInfo.teamManage){
                        that.pageInfo.teamManage = JSON.parse(that.pageInfo.teamManage);
                        var teamManagedata = [];
                        for (var i = 0; i < that.pageInfo.years.length; i++) {
                            teamManagedata.push({
                                type: 'bar',
                                name: that.pageInfo.years[i],
                                barWidth: '40',
                                data: [that.pageInfo.teamManage[0].value[i], that.pageInfo.teamManage[1].value[i]],
                                label: {
                                    normal: {
                                        show: true,
                                        position: 'top',
                                        textStyle: {
                                            color: '#000'
                                        },
                                        formatter: '{c}'
                                    }
                                },
                            })
                        }
                        that.managementTeam = {
                            xdata: [that.pageInfo.teamManage[0].name, that.pageInfo.teamManage[1].name],
                            bottomtitle: that.pageInfo.years,
                            data: teamManagedata
                        };
                        for(var i = 0;i < 2;i++){
                            that.teamManageStatements += that.pageInfo.teamManage[i].description;
                        }
                    };
                    // 新颖化
                    if(that.pageInfo.technology){
                        that.pageInfo.technology = JSON.parse(that.pageInfo.technology);
                        var technologydata = [];
                        for (var i = 0; i < that.pageInfo.years.length; i++) {
                            technologydata.push({
                                type: 'bar',
                                name: that.pageInfo.years[i],
                                barWidth: '40',
                                data: [that.pageInfo.technology[0].value[i], that.pageInfo.technology[1].value[i]],
                                label: {
                                    normal: {
                                        show: true,
                                        position: 'top',
                                        textStyle: {
                                            color: '#000'
                                        },
                                        formatter: '{c}'
                                    }
                                },
                            })
                        }
                        that.technological = {
                            xdata: [that.pageInfo.technology[0].name, that.pageInfo.technology[1].name],
                            bottomtitle: that.pageInfo.years,
                            data: technologydata
                        };
                        for(var i = 0;i < 2;i++){
                            that.technologyStatements += that.pageInfo.technology[i].description;
                        }
                    };
                    // 财务指标
                    if(that.pageInfo.financeTotalAnalysis){
                        that.pageInfo.financeTotalAnalysis = JSON.parse(that.pageInfo.financeTotalAnalysis);
                    }
                    // 资产负债率
                    if(that.pageInfo.liabilitiesRatio){
                        that.pageInfo.liabilitiesRatio = JSON.parse(that.pageInfo.liabilitiesRatio);
                        var liabilitiesRatioLists = that.pageInfo.liabilitiesRatio.description.split("|");
                        that.liabilitiesRatioStatement = that.pageInfo.liabilitiesRatio.titleDesc + liabilitiesRatioLists[1];
                        that.liabilitiesRatioStatementArr = liabilitiesRatioLists.slice(2);
                    }
                    // 流动比率
                    if(that.pageInfo.flowRation){
                        that.pageInfo.flowRation = JSON.parse(that.pageInfo.flowRation);
                        var flowRationLists = that.pageInfo.flowRation.description.split("|");
                        that.flowRationStatement = that.pageInfo.flowRation.titleDesc + flowRationLists[1];
                        that.flowRationStatementArr = flowRationLists.slice(2);
                    }
                    // EBIT利息保障倍数
                    if(that.pageInfo.ebitMultiple){
                        that.pageInfo.ebitMultiple = JSON.parse(that.pageInfo.ebitMultiple);
                        var ebitMultipleLists = that.pageInfo.ebitMultiple.description.split("|");
                        that.ebitMultipleStatement = that.pageInfo.ebitMultiple.titleDesc + ebitMultipleLists[1];
                        that.ebitMultipleStatementArr = ebitMultipleLists.slice(2);
                    }
                    // 毛利率
                    if(that.pageInfo.grossProfitRatio){
                        that.pageInfo.grossProfitRatio = JSON.parse(that.pageInfo.grossProfitRatio);
                        var grossProfitRatioLists = that.pageInfo.grossProfitRatio.description.split("|");
                        that.grossProfitRatioStatement = that.pageInfo.grossProfitRatio.titleDesc + grossProfitRatioLists[1];
                        that.grossProfitRatioStatementArr = grossProfitRatioLists.slice(2);
                    }
                    // 净利润率
                    if(that.pageInfo.netProfitRatio){
                        that.pageInfo.netProfitRatio = JSON.parse(that.pageInfo.netProfitRatio);
                        var netProfitRatioLists = that.pageInfo.netProfitRatio.description.split("|");
                        that.netProfiltRatioStatement = that.pageInfo.netProfitRatio.titleDesc + netProfitRatioLists[1];
                        that.netProfiltRatioStatementArr = netProfitRatioLists.slice(2);
                    }
                    // 主营业务收入增长率
                    if(that.pageInfo.mainIncomeRatio){
                        that.pageInfo.mainIncomeRatio = JSON.parse(that.pageInfo.mainIncomeRatio);
                        var mainIncomeRatioLists = that.pageInfo.mainIncomeRatio.description.split("|");
                        that.mainIncomeRatioStatement = that.pageInfo.mainIncomeRatio.titleDesc + mainIncomeRatioLists[1];
                        that.mainIncomeRatioStatementArr = mainIncomeRatioLists.slice(2);
                    }
                    // 净资产收益率
                    if(that.pageInfo.netAssetRatio){
                        that.pageInfo.netAssetRatio = JSON.parse(that.pageInfo.netAssetRatio);
                        var netAssetRatioLists = that.pageInfo.netAssetRatio.description.split("|");
                        that.netAssetRatioStatement = that.pageInfo.netAssetRatio.titleDesc + netAssetRatioLists[1];
                        that.netAssetRatioStatementArr = netAssetRatioLists.slice(2);
                    }
                    // 应收账款周转率
                    if(that.pageInfo.receivablesTurnoverRatio){
                        that.pageInfo.receivablesTurnoverRatio = JSON.parse(that.pageInfo.receivablesTurnoverRatio);
                        var receivablesTurnoverRatioLists = that.pageInfo.receivablesTurnoverRatio.description.split("|");
                        that.receivablesTurnoverRatioStatement = that.pageInfo.receivablesTurnoverRatio.titleDesc + receivablesTurnoverRatioLists[1];
                        that.receivablesTurnoverRatioStatementArr = receivablesTurnoverRatioLists.slice(2);
                    }
                    // 存货周转率
                    if(that.pageInfo.inventoryTurnoverRatio){
                        that.pageInfo.inventoryTurnoverRatio = JSON.parse(that.pageInfo.inventoryTurnoverRatio);
                        var inventoryTurnoverRatioLists = that.pageInfo.inventoryTurnoverRatio.description.split("|");
                        that.inventoryTurnoverRatioStatement = that.pageInfo.inventoryTurnoverRatio.titleDesc + inventoryTurnoverRatioLists[1];
                        that.inventoryTurnoverRatioStatementArr = inventoryTurnoverRatioLists.slice(2);
                    };
                    // 杜邦分析
                    if(that.pageInfo.dubangAnalysis){
                        that.pageInfo.dubangAnalysis = JSON.parse(that.pageInfo.dubangAnalysis);
                    }
                }
            })
        },
        // 资产负债表初始化
        libTableInit: function(){
            var that = this;
            var queryObj = {
                reportId: that.reportId
            }
            that.$post(this.projectName+"traceReport/queryLiabilitiesInfo",qs.stringify(queryObj)).then((res) => {
                var data = res.data;
                if(data.code == 200 && data.content){
                    console.log(data.content)
                    that.liaTableInfo = JSON.parse(data.content.liabilities);
                    that.liaTableYear = data.content.year;
                    console.log(that.liaTableInfo)
                }
            })
        },
        // 利润表初始化
        profitTableInit: function(){
            var that = this;
            var queryObj = {
                reportId: that.reportId
            };
            that.$post(this.projectName+"traceReport/queryProfitInfo",qs.stringify(queryObj)).then((res) => {
                var data = res.data;
                if(data.code == 200 && data.content){
                    console.log(data.content);
                    that.profitTableInfo = JSON.parse(data.content.profit);
                    that.profitYear = data.content.year;
                }
            })
        },
        /*页脚日期格式转换*/
        replaceAll: function(val) {　　
            if(val){
                return val.replace(new RegExp("-", "gm"), ".");
            }
        },
        // 估值结果格式化
        resultFormate: function(min,max) {
            var val = '';
            if(min){
                return this.method.valuationResultConversion(min, max)
            } else {
                return '无数据'
            }
        },
        // 日期格式化
        dateFormate: function(date) {
            if(date){
                var dataStr = date.split("-");
                var newDataStr = dataStr[0] + "年" + dataStr[1] + "月" + dataStr[2] + "日";
                return newDataStr;
            }
        },
        // 
        valueFormate: function(val) {
            if(val){
                return this.method.resultConversion(val)
            } else {
                return '无数据'
            }
        },
    },
    computed: {
        initStatus: function(){
            if(this.pageInfo.baseInfo.companyName){
                return true;
            } else {
                return false;
            }
        },
        
    }
})
</script>
<style scoped>
    .tools {
        width: 1160px;
        height: 60px;
        margin: 0 auto;
        padding-top: 10px;
        box-sizing: border-box;
    }
    .tools button {
        float: right;
        width: 120px;
        padding: 8px;
        border: 1px #aaa solid;
        border-radius: 4px;
        outline: none;
        font-size: 16px;
        transition: background-color 0.3s;
        background: #B1781E;
        color: #fff;
        margin-right: 20px;
        cursor: pointer;
    }
    /*页眉*/
    #report-page {
        width: 1240px;
        margin: 0 auto;
        position: relative;
    }
    #report-page #page-one {
        position: relative;
        background: #fbfdfe;
    }

    .fengmian {
        width: 100%;
        position: absolute;
        left: 0;
        top: 557px;
    }

    .page-header {
        margin: 30px 0 38px 0;
        overflow: hidden;
    }

    .page-header>div {
        float: right;
    }

    .page-header .page-header-tip {
        width: 20px;
        height: 40px;
        background: url(../../assets/img/report/page-header.png) no-repeat;
        background-size: 100% 100%;
        position: relative;
        right: -1px;
        z-index: 0;
    }

    .page-header .page-header-company {
        height: 40px;
        padding: 0 30px;
        font-size: 18px;
        line-height: 40px;
        background: #0478fc;
        color: #FFF;
        margin-right: 77px;
    }


    /* 页脚*/

    .page-footer {
        position: absolute;
        bottom: 0;
        height: 28px;
        width: 1010px;
        margin-left: 76px;
        margin-bottom: 37px;
    }

    .page-footer>.page-footer-left {
        float: left;
        font-size: 14px;
        color: #282828;
        height: 28px;
        line-height: 28px;
    }

    .page-footer>.page-footer-left>span:nth-child(2) {
        margin: 0 5px;
    }

    .page-footer>.page-footer-left>span:last-of-type {
        color: #0D0D0D;
    }

    .page-footer>.page-footer-right {
        float: right;
        font-size: 0;
    }

    .page-footer>.page-footer-right>.date {
        height: 26px;
        border-bottom: 2px solid #0478fc;
        line-height: 28px;
        padding: 0 5px;
        font-size: 14px;
        color: #282828;
    }

    .page-footer>.page-footer-right>div {
        display: inline-block;
    }

    .page-footer>.page-footer-right .page-num {
        width: 28px;
        height: 28px;
        background: url(../../assets/img/report/page-num@2x.png) no-repeat;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        color: #fff;
    }


    /*第一页*/

    .page-one>img#logo {
        width: 386px;
        height: 130px;
        margin: 58px 0 96px 54px;
    }

    .page-main {
        width: 1240px;
        height: 1428px;
        overflow: hidden;
        text-align: center;
    }

    .page-main>p {
        /* margin-top: 100px; */
        text-align: center;
        font-size: 47px;
        color: #0478fc;
        letter-spacing: 2px;
        line-height: 1;
    }

    .page-main>h5 {
        font-size: 143px;
        font-family: "微软雅黑";
        font-weight: 400;
        color: #0478fc;
        letter-spacing: 30px;
        line-height: 100%;
        margin-top: 53px;
    }

    .page-one-footer {
        position: absolute;
        bottom: 52px;
        height: 21px;
        width: 1048px;
        margin: 0 34px;
    }

    .page-one-footer>p {
        float: right;
        margin-right: 193px;
        font-size: 18px;
        color: #282828;
        line-height: 1;
    }

    .page-one-footer>p>span:nth-child(2) {
        margin: 0 5px;
    }

    .page-one-footer>p>span:last-of-type {
        color: #0D0D0D;
    }

    .page-one-footer>div.date {
        float: right;
        font-size: 18px;
        line-height: 1;
    }

    #content,
    #content1 {
        width: 1160px;
        height: auto;
        margin: 0 auto;
    }

    .report-page {
        width: 1160px;
        margin: 0 auto;
    }

    .page {
        width: 100%;
        height: 1754px;
        background-color: #fff;
        overflow: hidden;
        position: relative;
        border-bottom: 1px solid #cccccc;
    }


    /*目录页*/

    .catalogue-img-container {
        position: absolute;
        top: 0;
        left: 24px;
        height: 277px;
    }

    .catalogue-img-container>img {
        display: block;
        height: 100%;
    }

    .littleMask {
        position: absolute;
        width: 160px;
        height: 1150px;
        background: #fff;
        right: 0;
        top: 300px;
        z-index: 10;
    }

    .page-catalogue>ul {
        margin-top: 264px;
        margin-left: 118px;
        margin-right: 118px;
    }

    .page-catalogue>ul>li {
        margin-bottom: 20px;
    }

    .page-catalogue>ul>li>div {
        position: relative;
    }

    .page-catalogue>ul>li>div>span {
        color: #000;
        line-height: 36px;
    }

    .page-catalogue>ul>li>div>span:nth-of-type(1) {
        margin-right: 132px;
        font-size: 40px;
    }

    .page-catalogue>ul>li>div.titlethree>span:nth-of-type(1) {
        margin-right: 150px;
        font-size: 40px;
    }

    .page-catalogue>ul>li>div>span:nth-of-type(2) {
        font-size: 18px;
        color: #282828;
    }

    .page-catalogue>ul>li>div>span:nth-of-type(3) {
        position: absolute;
        width: 60%;
        height: 1px;
        top: 18px;
        margin-left: 30px;
        background: url(../../assets/img/report/catalogue_.png) left center repeat;
    }

    .page-catalogue>ul>li>div.titletwo>span:nth-of-type(3) {
        position: absolute;
        width: 63%;
        height: 1px;
        top: 18px;
        margin-left: 30px;
        background: url(../../assets/img/report/catalogue_.png) left center repeat;
    }

    .page-catalogue>ul>li>div>span:nth-of-type(4) {
        line-height: 34px;
        position: absolute;
        right: 0;
        z-index: 100;
        font-size: 18px;
    }

    .page-catalogue>ul>li>div.title>span {
        color: #000;
        margin-bottom: 20px;
    }

    .page-catalogue>ul>li>div.title>span:nth-of-type(2) {
        font-size: 24px;
    }

    .dot-line {
        position: absolute;
        top: 271px;
        left: 214px;
    }

    .dot-line>span {
        display: block;
        position: relative;
        width: 20px;
        height: 20px;
        background: #0478fc;
        border-radius: 10px;
        z-index: 2;
    }

    .dot-line>span:nth-of-type(2) {
        margin-top: 58px;
    }

    .dot-line>span:nth-of-type(3) {
        margin-top: 180px;
    }

    .dot-line>span:nth-of-type(4) {
        margin-top: 226px;
    }

    .dot-line>span:nth-of-type(5) {
        margin-top: 347px;
    }

    .dot-line>span:nth-of-type(6) {
        margin-top: 56px;
    }

    .dot-line>div {
        position: absolute;
        top: 0;
        left: 9px;
        width: 2px;
        height: 986px;
        background: #a9a9a9;
    }

    .bigtitle {
        font-size: 21px;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .smalltitle {
        font-size: 20px;
        margin-bottom: 30px;
    }

    .lesserTitle {
        font-size: 19px;
        margin: 50px 0 20px 0;
    }


    /*第二页*/

    .page-text>.page-text-header {
        border-bottom: 2px solid #666666;
        height: 38px;
    }

    .page-text-tit {
        overflow: hidden;
        background: #0478fc;
        display: inline-block;
        float: left;
    }

    .page-text-tit>img {
        width: 26px;
        height: 26px;
        margin: 7px 10px 0 10px;
        float: left;
    }

    .page-text-tit>span {
        height: 40px;
        margin-right: 10px;
        line-height: 40px;
        display: block;
        float: left;
        font-size: 20px;
        color: #fff;
    }

    .page-text>.page-text-header>img {
        width: 10px;
        height: 40px;
        float: left;
        margin-left: -1px;
    }

    .page-text-content {
        padding: 20px 0;
    }

    .page-two .page-text-content {
        padding: 20px 10px;
        font-size: 16px;
    }

    .result-container {
        overflow: hidden;
    }

    .result-container>img {
        width: 86px;
        margin-right: 27px;
        float: left;
    }

    .result-container>p.result-num {
        margin: 16px 0 10px 0;
        font-size: 30px;
        line-height: 1;
        color: #0478fc;
    }

    .result-container>p:last-of-type {
        font-size: 16px;
        line-height: 1;
        margin-bottom: 11px;
        color: #666;
    }

    .result-desc {
        font-size: 16px;
        color: #282828;
        line-height: 30px;
        text-align: justify;
        text-indent: 2em;
        margin-bottom: 12px;
        margin-left: 15px;
    }

    .page-text-content>.page-text-left {
        float: left;
        width: 464px;
        margin: 0 60px 0 10px;
    }

    .page-text-content>.page-text-right {
        float: left;
        width: 448px;
    }

    .page-text-content .row {
        font-size: 16px;
        color: #282828;
        overflow: hidden;
        line-height: 1.8;
    }

    .page-text-content .row>label {
        float: left;
    }

    .page-text-content .row>span {
        float: left;
    }

    .page-text-content>.page-text-left>.row>span {
        max-width: 384px;
    }

    .page-text-content>.page-text-right>.row>span {
        max-width: 368px;
    }

    .clear {
        clear: both;
        margin-bottom: 27px;
    }

    p.result-num>span {
        margin-right: 5px;
    }

    p.result-num>span.line {
        margin: 0 5px;
    }

    .page-one-bottom {
        overflow: hidden;
    }

    .page-one-left {
        width: 474px;
        margin-right: 59px;
        float: left;
    }

    .page-one-right {
        width: 470px;
        float: left;
    }

    .pageOne-canvas01 {
        height: 370px;
    }

    .pageOne-canvas02,
    .pageOne-canvas03 {
        height: 273px;
        top: -24px;
    }


    /*第三页*/

    .relavtive-container {
        height: 638px;
    }

    .relavtive-left {
        width: 425px;
        float: left;
        height: 638px;
    }

    .relavtive-mid-container {
        overflow: hidden;
        width: 156px;
        float: left;
    }

    .relavtive-mid {
        background: url(../../assets/img/report/dottedPic@2x.png)no-repeat;
        margin-top: 104px;
        width: 30px;
        height: 467px;
        /* border: 1px dotted #0478fc; */
        /* border-left: 0; */
        float: left;
    }

    .amount {
        margin: 310px 0px 0 59px;
        width: 65px;
        height: 46px;
        display: block;
        float: left;
        background: url(../../assets/img/report/equal@2x.png) no-repeat;
    }

    .relavtive-right {
        width: 410px;
        height: 385px;
        margin-top: 145px;
        float: left;
    }

    .relavtive-right>p {
        font-size: 16px;
        color: #282828;
        line-height: 1.5;
        text-align: center;
    }

    .relavtive-right>.relavtive-three {
        height: 360px;
    }

    .relavtive-one {
        margin-left: -10px;
    }

    .relavtive-one,
    .relavtive-two {
        margin-top: 40px;
        height: 250px;
        width: 100%;
    }

    .relavtive-one>div.div-canvas,
    .relavtive-two>div.div-canvas,
    .relavtive-three>div.div-canvas {
        height: 100%;
    }

    .relative-weight {
        height: 17px;
        margin-bottom: 39px;
    }

    .relative-weight>img {
        width: 13px;
        float: left;
        height: 13px;
        margin: 2px 10px 2px 0;
    }

    .relative-weight>p {
        font-size: 16px;
        line-height: 17px;
    }

    .relative-weight-container {
        height: 65px;
        margin-bottom: 49px;
    }

    .relative-result {
        width: 760px;
        height: 117px;
        margin: 0 auto;
        overflow: hidden;
        margin-bottom: 48px;
        border: 3px solid #0478fc;
        border-radius: 10px;
    }

    .relative-result>p {
        font-size: 22px;
        line-height: 1;
        text-align: center;
        color: #686868;
        margin: 20px 0 27px;
    }

    .relative-result>span {
        font-size: 26px;
        line-height: 1;
        text-align: center;
        display: block;
        color: #0478fc;
    }

    .page-tip-container {
        width: 966px;
        padding: 30px 21px 34px 17px;
        background: #F7F7F7;
    }

    .page-tip-container>ul {
        overflow: hidden;
    }

    .page-tip-container>ul>li {
        font-size: 18px;
        line-height: 34px;
        overflow: hidden;
    }

    .page-tip-container>ul>li>label {
        font-size: 16px;
        color: #282828;
        float: left;
        line-height: 34px;
        height: 2em;
    }

    .relative-weight-container>div {
        float: left;
        height: 100%;
    }

    .relative-weight-container>div>span {
        display: block;
        text-align: center;
        font-size: 16px;
        line-height: 2;
        color: #fff;
    }

    .relative-weight>p {
        font-size: 16px;
        line-height: 17px;
    }


    /*第四页*/


    /*页面标题*/

    .pageTitle {
        width: 1008px;
        height: 48px;
        margin: 0 auto;
        margin: 0 auto 47px;
        background: #FFA26A;
        overflow: hidden;
    }
    .pageTitle>div {
        margin-right: 34px;
        float: left;
        padding-left: 30px;
        height: 48px;
        line-height: 48px;
        font-size: 22px;
        color: #FFF;
        letter-spacing: 2px;
    }


    /*绝对估值法标题*/

    .page-text-sub-header {
        width: 1008px;
        height: 38px;
        margin: 0 auto;
        border-bottom: 2px solid #666666;
    }

    .page-text-tit {
        overflow: hidden;
        background: #0478fc;
        display: inline-block;
        float: left;
    }

    .page-text-tit>img {
        width: 26px;
        height: 26px;
        margin: 7px 10px 0 10px;
        float: left;
    }

    .page-text-tit>span {
        height: 40px;
        margin-right: 10px;
        line-height: 40px;
        display: block;
        float: left;
        font-size: 20px;
        color: #fff;
    }

    .page-text-header>img {
        width: 10px;
        height: 40px;
        float: left;
    }

    .valuResultText {
        font-size: 18px;
        margin-top: 40px;
        text-indent: 2em;
        line-height: 34px;
    }


    /*表格部分*/

    .table_area {
        width: 1008px;
        height: 525px;
        margin: 0 auto;
        margin-top: 60px;
        overflow: hidden;
    }

    .cash-flow {
        float: left;
        width: 488px;
        height: 480px;
    }

    #cashFlowTrend {
        float: right;
    }

    .center-arrow {
        width: 32px;
        height: 23px;
        padding-top: 212px;
    }

    .center-arrow>img {
        width: 100%;
    }

    .appraisementTable {
        border: none;
        border-top: 3px #0478fc solid;
        border-bottom: 2px #0478fc solid;
    }

    .appraisementTable tr:first-child td {
        text-align: center;
    }

    .appraisementTable tr:nth-of-type(even) td {
        background: #F0F0F0;
    }

    .appraisementTable tr:nth-of-type(2) td {
        font-weight: bold;
        background: #C6DEFF;
    }

    .appraisementTable tr td:nth-child(n+2) {
        padding: 0;
        text-align: center;
    }

    .appraisementTable tr td:first-of-type {
        width: 26%;
        padding-left: 84px;
    }

    .appraisementTable tr:last-child td {
        background: #FFFF;
        font-size: 14px;
        padding: 0;
    }

    .appraisementTable td {
        border: none;
        font-size: 16px;
    }


    /*虚线框*/

    .dashes-line {
        width: 770px;
        height: 27px;
        margin: 0 auto;
        border-top: none;
        background: url(../../assets/img/report/dottedPicX.png);
        background-size: cover;
    }

    .arrow-down {
        width: 133px;
        height: 130px;
        margin: 0 auto;
        margin-top: 28px;
        background: url(../../assets/img/report/profession_page04_down_arrow.png) center center no-repeat;
        background-size: 100%;
    }

    .arrow-down>p {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: rgba(255, 255, 255, 1);
        line-height: 18px;
        text-align: center;
        padding-top: 64px;
    }

    .border-area {
        width: 760px;
        height: 117px;
        margin: 0 auto;
        margin-top: 21px;
        border: 3px solid #0478fc;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }

    .border-area>p {
        font-size: 22px;
        font-family: MicrosoftYaHei;
        text-align: center;
        line-height: 40px;
        color: #686868;
    }

    .border-area>p:first-child {
        margin-top: 15px;
    }

    .border-area>p:last-child {
        margin-top: 5px;
        font-size: 26px;
        color: #0478fc;
    }

    .explain-container {
        width: 952px;
        height: auto;
        margin: 0 auto;
        margin-top: 85px;
        padding: 28px;
        background: rgba(247, 247, 247, 1);
        font-size: 18px;
        font-family: MicrosoftYaHei;
        color: rgba(40, 40, 40, 1);
        line-height: 34px;
        text-align: justify;
    }


    /*第五页*/

    .socre-explain-container {
        background: #f7f7f7;
        width: 1001px;
        margin: 0 auto 68px;
        height: auto;
        overflow: hidden;
    }

    .socre-explain-container>p:first-of-type {
        height: 24px;
        margin: 17px 0 12px 21px;
    }

    .socre-explain-container>p:first-of-type>img {
        height: 24px;
        width: 24px;
        margin-right: 11px;
        float: left;
    }

    .socre-explain-container>p:first-of-type>span {
        height: 24px;
        float: left;
        font-size: 18px;
        color: #282827;
        line-height: 24px;
    }

    .socre-explain-container>p:last-of-type {
        width: 95%;
        margin: 0 20px 31px 24px;
        font-size: 16px;
        line-height: 1.875;
        color: #282828;
    }

    .socre-explain-container-sapn {
        display: inline;
    }

    .industryEnvirContent,
    .enterpriseContent {
        width: 1008px;
        margin: 0 auto;
    }

    div.chart {
        height: 188px;
        width: 50%;
        margin-top: -50px;
    }

    div.chart.enterpriseCompeChart {
        height: 240px;
    }

    .describe {
        margin-top: 20px;
    }

    .describe>p {
        font-size: 18px;
        line-height: 34px;
        text-indent: 2em;
        text-align: justify;
    }

    .describe>p:first-of-type {
        margin-bottom: 10px;
    }

    .socre-explain-container-p>span {
        display: inline;
    }

    .valuation-result-main {
        width: 746px;
        height: 136px;
        margin: 0 auto;
        margin-top: 63px;
        border: 3px solid #0478fc;
        border-radius: 10px;
    }

    .valuation-result-main>p {
        font-size: 22px;
        font-family: MicrosoftYaHei;
        text-align: center;
        line-height: 40px;
        color: #686868;
    }

    .valuation-result-main>p:first-child {
        margin-top: 15px;
    }

    .valuation-result-main>p:last-child {
        margin-top: 5px;
        font-size: 26px;
        color: #0478fc;
    }


    /*第六页*/

    .value_ratio>div {
        display: inline-block;
        width: 24%;
        height: 300px;
        /* background: pink; */
    }

    .units-title {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        font-size: 18px;
        margin-bottom: 15px;
    }

    .units-title span {
        display: inline-block;
        font-size: 18px;
    }

    .rhombus {
        width: 5px;
        height: 5px;
        margin-right: 10px;
        background: #0478fc;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
    }


    /*第七页*/

    .valuation-result-container {
        width: 896px;
        height: 319px;
        margin: 0 auto;
        margin-top: 136px;
    }

    .valuation-result-container>.valuation-result {
        float: left;
        width: 134px;
        height: 319px;
        margin-left: 45px;
        margin-right: 45px;
        background: url(../../assets/img/report/result-img@2x.png) left top no-repeat;
    }

    .valuation-result>img {
        margin-left: 62px;
        margin-top: 34px;
    }

    .img_pageSeven_arrow {
        display: block;
        margin: 0 auto;
        margin-top: 28px;
    }

    .valuation-result p {
        margin-bottom: 30px;
    }

    .page-seven>p {
        margin-left: 82px;
        margin-top: 116px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: rgba(40, 40, 40, 1);
        line-height: 16px;
    }

    .page-seven>.explain-container {
        width: 963px;
        height: 78px;
        padding: 30px 23px 30px 20px;
        margin-top: 33px;
        background: rgba(247, 247, 247, 1);
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: rgba(40, 40, 40, 1);
        line-height: 30px;
    }

    .page-seven>.explain-container>span {
        display: inline-block;
        float: left;
        width: 10px;
    }

    .page-seven>.explain-container>ul {
        float: left;
    }

    #operatingReceipt,
    #netMargin,
    #assetLiabilityRatio,
    .charts {
        width: 80%;
        height: 410px;
        margin: 20px auto;
        margin-bottom: 0;
        /* background: pink; */
    }

    #operatingReceipt {
        width: 100%;
    }


    /*第八页*/

    .revenue_table {
        width: 1008px;
        height: 515px;
        margin: 0 auto;
    }

    .netProfits_table {
        width: 1008px;
        height: 520px;
        margin: 0 auto;
    }

    .bottom_explain {
        width: 1008px;
        height: 70px;
        margin: 0 auto;
        background: rgba(247, 247, 247, 1);
    }

    .bottom_explain>span {
        display: inline-block;
        float: left;
        width: 24px;
        height: 24px;
        margin-top: 23px;
        margin-left: 16px;
        margin-right: 10px;
    }

    .bottom_explain>p {
        float: left;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: rgba(51, 51, 51, 1);
        line-height: 70px;
    }

    .industryEnvironment {
        width: 80%;
        height: 380px;
        margin: 20px auto;
        margin-bottom: 30px;
    }

    .industryEnvironment:first-child {
        margin-top: 0;
    }

    .industryEnvironment p {
        text-align: center;
        font-size: 18px;
        margin: 25px 0;
    }

    .industryEnvironment>div {
        /* background: pink; */
    }

    .industryEnvironment>div>div {
        width: 100%;
        height: 323px;
        display: inline-block;
    }

    .font {
        font-size: 18px;
        line-height: 34px;
        text-indent: 2em;
        text-align: justify;
    }


    /*第九页*/

    .table_explain {
        width: 1008px;
        margin: 0 auto;
    }

    .sovency {
        height: 380px;
        margin-top: 62px;
    }

    .asset-liability-ratio {
        margin-top: 52px;
    }

    .sovency_table,
    .profitability_table,
    .growUp_table,
    .operational_table {
        float: left;
        width: 540px;
        height: 380px;
    }

    .sovency_table>div,
    .profitability_table>div,
    .growUp_table>div,
    .operational_table>div {
        width: 100%;
        height: 100%;
    }

    .table_explain>.right {
        float: right;
        width: 420px;
        height: 380px;
        padding: 0 24px;
        background: rgba(247, 247, 247, 1);
    }

    .grade {
        position: relative;
        float: right;
        height: 20px;
        margin-top: 20px;
        overflow: hidden;
    }

    .grade img.battery {
        margin-right: 6px;
    }

    .grade>ul {
        position: absolute;
        width: 26px;
        height: 20px;
        top: 0;
        left: 6px;
    }

    .grade>ul>li {
        float: right;
        width: 16%;
        height: 49%;
        margin: 12% 2%;
        background: #97CA5B;
    }

    .grade>p {
        display: inline-block;
        float: right;
        height: 20px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: rgba(40, 40, 40, 1);
        line-height: 16px;
    }

    .table_explain>.right>h4 {
        margin-top: 44px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: rgba(40, 40, 40, 1);
        line-height: 24px;
        text-indent: 2em
    }

    .table_explain>.right>p {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: rgba(51, 51, 51, 1);
        line-height: 1.875;
        text-align: justify;
        text-indent: 2em;
        margin: 12px 0;
    }


    /*第十页*/

    .table_explain.profitability {
        height: 490px;
        margin-top: 50px;
    }

    .table_explain>.profitability_explain {
        width: 410px;
        height: 450px;
        padding: 0 20px;
    }

    #financialIndex tbody tr td,
    #competitiveness tbody tr td {
        vertical-align: middle;
        position: relative;
    }

    #financialIndex tbody tr td span,
    #competitiveness tbody tr td span {
        width: 59%;
        display: block;
        text-align: right;
    }

    #financialIndex tbody tr td img,
    #competitiveness tbody tr td img {
        position: absolute;
        left: 64%;
        top: 10px;
    }

    #competitiveness tbody tr:nth-child(n+2) td:nth-child(2) img,
    #financialIndex tbody tr:nth-child(n+2) td:nth-child(2) img {
        display: none;
    }


    /*第十一页*/

    .table_explain.growUp {
        height: 450px;
        margin-top: 60px;
    }

    .table_explain>.growUp_explain {
        width: 420px;
        height: 450px;
        padding: 0 20px;
    }


    /*第十二页*/

    .table_explain.operational {
        height: 490px;
        margin-top: 60px;
    }

    .table_explain>.operational_explain {
        width: 418px;
        height: 450px;
        padding: 0 16px;
    }


    /*杜邦分析页*/

    .return-on-assets {
        position: relative;
        width: 1008px;
        margin: 0 auto;
        margin-top: 38px;
        margin-bottom: 40px;
    }

    .return-on-assets>p {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(40, 40, 40, 1);
        line-height: 30px;
    }

    .return-on-assets .box {
        width: 180px;
        height: 60px;
        background: #0478fc;
        /* border: 1px solid #666666; */
    }

    .return-on-assets .box {
        position: relative;
        box-sizing: border-box;
    }

    .return-on-assets .box>p {
        width: 100%;
        height: 16px;
        margin-top: 8px;
        font-size: 16px;
        text-align: center;
    }

    .return-on-assets .box>span {
        position: absolute;
        right: 5px;
        top: 7px;
        font-size: 30px;
        text-align: center;
        color: #fff;
    }

    .box.dubangNetAssetsRatio {
        margin-left: 440px;
        overflow: hidden;
    }

    .second-line {
        width: 538px;
        margin: 0 auto;
        margin-left: 260px;
        overflow: hidden;
    }

    .second-line>.box {
        float: left;
        width: 146px;
        background: #76B0FF;
    }

    .second-line>span {
        width: 20px;
        margin-left: 112px;
        margin-top: 20px;
        text-align: center;
        vertical-align: middle;
    }

    .second-line>.box:last-child {
        float: right;
    }

    .third-line {
        position: relative;
        width: 810px;
        margin-left: 100px;
        overflow: hidden;
    }

    .third-line>.box {
        float: left;
        width: 146px;
        background: #A4CBFF;
    }

    .third-line>.box:first-of-type {
        margin-right: 170px;
    }

    .third-line>.box:last-of-type {
        margin-left: 174px;
    }

    .third-line>span {
        position: absolute;
        right: 0;
        top: 19px;
        font-size: 22px;
        color: #282828;
        line-height: 22px;
    }

    .fourth-line {
        margin-left: 32px;
        overflow: hidden;
    }

    .fourth-line>div {
        float: left;
        margin-right: 43px;
        overflow: hidden;
    }

    .fourth-line>div>.box {
        float: left;
        width: 120px;
        background: #E1EEFF;
    }

    .fourth-line>div>span {
        float: left;
        width: 24px;
        height: 30px;
        margin-left: 8px;
        margin-right: 8px;
        margin-top: 15px;
        font-size: 22px;
        text-align: center;
    }

    .link-line {
        width: 2px;
        background: #666666;
    }

    .first-link-line {
        height: 29px;
        margin-left: 526px;
    }

    .second-link-line {
        height: 29px;
        margin-left: 332px;
    }

    .third-link-line {
        height: 57px;
        margin-left: 766px;
        margin-top: -58px;
    }

    .line-box-container {
        width: 100%;
        height: 57px;
        margin-left: 90px;
        overflow: hidden;
    }

    .line-box-container>div {
        float: left;
    }

    .line-box-container>div:first-of-type {
        margin-right: 155px;
    }

    .line-box-container>div:last-of-type {
        margin-left: 166px;
    }

    .line-box-container>div>.link-line {
        height: 29px;
    }

    .fourth-link-line {
        margin-left: 82px;
    }

    .link-box {
        width: 160px;
        box-sizing: border-box;
        border: 2px solid rgba(102, 102, 102, 1);
        border-bottom: none;
    }

    .first-link-box {
        width: 397px;
        height: 38px;
        margin-left: 332px;
    }

    .second-link-box {
        width: 316px;
        height: 29px;
        margin-left: 173px;
    }

    .line-box-container>div>.link-box {
        width: 161px;
        height: 29px;
    }


    /*杜邦分析解释*/

    .dupon-explain {
        width: 1004px;
        padding: 25px 33px 25px 22px;
        background: rgba(247, 247, 247, 1);
        margin: 0 auto;
        margin-bottom: 36px;
        font-size: 18px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(40, 40, 40, 1);
        line-height: 30px;
        text-indent: 2em;
    }

    .page-dupon>table {
        border-collapse: collapse;
        border-color: #666;
        margin-bottom: 40px;
    }

    .page-dupon>table>tbody>tr {
        border-color: #666;
        height: 40px;
    }

    .page-dupon>table>tbody>tr>th {
        font-size: 16px;
        color: #282828;
        font-weight: bold;
    }

    .page-dupon>table>tbody>tr>td {
        border-color: #666;
        text-align: center;
        font-size: 16px;
        color: #282828;
    }

    .page-dupon>table>tbody>tr>td:nth-of-type(2) {
        padding-left: 48px;
        border-color: #666;
        text-align: center;
    }

    .page-dupon>table>tbody>tr>td:nth-of-type(3) {
        padding-left: 48px;
        border-color: #666;
        text-align: center;
    }

    .page-dupon>table>tbody>tr:nth-of-type(5)>td {
        font-weight: bold;
    }

    .rate-container {
        width: 1008px;
        margin: 0 auto;
        margin-top: 44px;
    }

    .rate-container>p {
        font-size: 24px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: rgba(40, 40, 40, 1);
        line-height: 18px;
        margin-bottom: 16px;
    }

    .rate-container>span {
        padding: 0 24px;
        font-size: 18px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(40, 40, 40, 1);
        line-height: 34px;
        text-indent: 2em;
    }

    #dubangAnalysis {
        font-size: 18px;
    }

    #dubangAnalysis tr td:nth-child(n+2) {
        padding: 0;
        text-align: center;
    }

    #dubangAnalysis th {
        width: 10%;
    }


    /*第十三页*/

    .page-thirteen .imgUl {
        width: 100%;
        height: auto;
        margin: 8% auto 0;
    }

    .page-thirteen .imgUl>li {
        width: 1008px;
        height: auto;
        list-style-type: none;
        margin: 0 auto;
        margin-bottom: 6%;
        overflow: hidden;
    }

    .page-thirteen .imgUl>li>.liDivImg {
        float: left;
        width: 170px;
        height: 170px;
        margin-left: 4%;
        margin-right: 56px;
        text-align: center;
        border: 2px solid #0478fc;
        border-radius: 90px;
    }

    #indicatorContainer2 {
        border: 2px solid #EA8E57;
    }

    #indicatorContainer3 {
        border: 2px solid #F1BC4F;
    }

    #indicatorContainer4 {
        border: 2px solid #66BBEA;
    }

    .page-thirteen .imgUl>li>p {
        float: left;
        width: 70%;
        margin-top: 1%;
        font-size: 18px;
        color: #282828;
        line-height: 2;
        text-indent: 2em;
        cursor: Default;
    }

    .page-thirteen .imgUl>li>h3 {
        width: 70%;
        float: left;
        font-size: 18px;
        text-align: left;
        margin-top: 2%;
        cursor: default;
    }

    .clear {
        clear: both;
    }


    /*附件*/

    .page-content {
        padding: 116px 110px 0 110px;
    }

    .enclosure-title {
        margin-bottom: 26px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        line-height: 26px;
    }

    .enclosure-title>span {
        font-size: 26px;
        display: block;
        line-height: normal;
    }

    .enclosure-title>span.unit {
        font-size: 17px;
        position: relative;
        top: 6px;
    }

    .enclosure-title>p {
        font-size: 26px;
    }

    .enclosure-title>p>span {
        line-height: normal;
    }


    /*表格*/

    table {
        width: 1008px;
        margin: 0 auto;
    }

    table tr {
        height: 40px;
    }

    table>thead>tr {
        background: #0478fc;
        border: 1px solid #0478fc;
    }

    table>thead>tr>th {
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold;
        color: #fff;
        line-height: 44px;
        border: 1px solid #000;
    }

    table tr td {
        border: 1px solid #333;
    }

    table>tbody>tr:first-of-type>td {
        border-top: none;
    }

    table>tbody>tr>td:first-of-type {
        padding-left: 35px;
        text-align: left;
    }

    table>tbody>tr>td:nth-child(n+2) {
        padding-right: 50px;
        text-align: right;
    }

    table.table {
        width: 100%;
        margin-bottom: 70px;
    }

    table.table>thead {
        text-align: center;
        font-size: 16px;
        color: #282828;
        font-weight: bold;
        background: #FFE178;
    }

    table.table tbody {
        border-left: 1px solid black;
    }


    /* 表格*/

    table>tbody>tr.trOne>td:first-of-type {
        padding-left: 20px;
    }

    table>tbody>tr.trTwo>td:first-of-type {
        padding-left: 34px;
        font-size: 14px;
        color: #282828;
    }

    table>tbody>tr.trThree>td:first-of-type {
        padding-left: 34px;
        font-size: 14px;
        color: #282828;
    }

    table>tbody>tr.trOne {
        font-size: 16px;
        font-weight: bold;
        color: #282828;
        font-weight: bold;
    }

    table th {
        width: 25%;
    }

    table.profitTableInfo tr {
        height: 36px;
    }

    .formula-container>h3 {
        font-size: 20px;
        line-height: 1;
        color: #282828;
        margin-bottom: 20px;
        margin-top: 26px;
    }

    .formula-container>ul>li {
        overflow: hidden;
        margin-bottom: 6px;
    }

    .formula-container>ul>li>.formula-tit {
        width: 984px;
        float: left;
        margin-bottom: 4px;
    }

    .formula-tit img {
        margin-top: 8px;
        margin-bottom: 10px;
    }

    .formula-container>ul>li>.formula-tit>p {
        line-height: 34px;
        color: #282828;
        font-size: 18px;
    }

    .formula-container>ul>li>.formula-tit>h4 {
        line-height: 34px;
        color: #282828;
        font-size: 20px;
    }

    .formula-container>ul>li>.formula-tit>h4>span {
        display: inline-block;
    }

    .formula-container>ul>li>p {
        font-size: 18px;
        line-height: 34px;
        color: #282828;
    }

    .formula-container span {
        float: left;
        font-size: 14px;
        margin: 7px 10px 0 0;
        color: #0478FC;
    }

    .page-last>img {
        width: 900px;
        height: 376px;
        margin: 91px auto 75px;
        display: block;
    }

    .flow-statement {
        width: 1008px;
        margin: 0 auto;
    }

    .flow-statement>img {
        display: block;
        width: 349px;
        height: 114px;
        margin: 0 auto 47px;
    }

    .flow-statement>ul>li>div.statement-tit {
        height: 20px;
        width: 428px;
        margin: 54px auto 35px;
        font-size: 0;
        position: relative;
    }

    .statement-tit>span.xian {
        width: 135px;
        height: 1px;
        display: block;
        position: absolute;
        top: 0;
        margin-top: 9.5px;
        background: #0478fc;
    }

    .statement-tit>span.xian:first-of-type {
        left: 2px;
    }

    .statement-tit>span.xian:last-of-type {
        right: 0;
    }

    .statement-tit>img {
        width: 12px;
        height: 12px;
        float: left;
        margin-top: 4px;
    }

    .statement-tit>img:first-of-type {
        margin-left: 135px;
    }

    .statement-tit>p {
        width: 136px;
        height: 20px;
        line-height: 20px;
        font-size: 20px;
        color: #282828;
        float: left;
        background: #FFF;
        text-align: center;
    }

    .statement-main {
        width: 977px;
        padding: 17px 20px 16px 20px;
        background: #E8F4FF;
        font-size: 16px;
        color: #282828;
        line-height: 30px;
        text-indent: 2em;
        text-align: justify;
    }


    .base {
        height: 9em;
        left: 50%;
        margin: -7.5em;
        padding: 3em;
        position: absolute;
        top: 50%;
        width: 17em;
        -webkit-transform: rotateX(45deg) rotateZ(45deg);
        transform: rotateX(45deg) rotateZ(45deg);
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }

    .cube,
    .cube:after,
    .cube:before {
        content: '';
        float: left;
        height: 3em;
        position: absolute;
        width: 3em;
    }

    .profitTableInfo tbody td:nth-of-type(1) {
        width: 35%;
    }

    .profitTableInfo tbody td:nth-of-type(1).one {
        font-weight: bold;
        padding-left: 20px;
    }

    .profitTableInfo tbody td:nth-of-type(1).two {
        padding-left: 70px;
    }

    .profitTableInfo tbody td:nth-of-type(1).three {
        padding-left: 116px;
    }


    /* Top */

    .cube {
        background-color: #cb9f4f;
        position: relative;
        -webkit-transform: translateZ(3em);
        transform: translateZ(3em);
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transition: .25s;
        transition: .25s;
        box-shadow: 13em 13em 1.5em rgba(0, 0, 0, 0.1);
        -webkit-animation: anim 1s infinite;
        animation: anim 1s infinite;
    }

    .cube:after {
        background-color: #BE9853;
        -webkit-transform: rotateX(-90deg) translateY(3em);
        transform: rotateX(-90deg) translateY(3em);
        -webkit-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }

    .cube:before {
        background-color: #AD8E55;
        -webkit-transform: rotateY(90deg) translateX(3em);
        transform: rotateY(90deg) translateX(3em);
        -webkit-transform-origin: 100% 0;
        -ms-transform-origin: 100% 0;
        transform-origin: 100% 0;
    }

    .cube:nth-child(1) {
        -webkit-animation-delay: 0.05s;
        animation-delay: 0.05s;
    }

    .cube:nth-child(2) {
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s;
    }

    .cube:nth-child(3) {
        -webkit-animation-delay: 0.15s;
        animation-delay: 0.15s;
    }

    .cube:nth-child(4) {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }

    .cube:nth-child(5) {
        -webkit-animation-delay: 0.25s;
        animation-delay: 0.25s;
    }

    .cube:nth-child(6) {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }

    .cube:nth-child(7) {
        -webkit-animation-delay: 0.35s;
        animation-delay: 0.35s;
    }

    .cube:nth-child(8) {
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
    }

    .cube:nth-child(9) {
        -webkit-animation-delay: 0.45s;
        animation-delay: 0.45s;
    }

    @-webkit-keyframes anim {
        50% {
            -webkit-transform: translateZ(0.5em);
            transform: translateZ(0.5em);
        }
    }

    @keyframes anim {
        50% {
            -webkit-transform: translateZ(0.5em);
            transform: translateZ(0.5em);
        }
    }

    #loading {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: none;
    }

    .result-explain-container {
        overflow: hidden;
        margin: 33px auto 0;
        background: rgba(247, 247, 247, 1);
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: rgba(40, 40, 40, 1);
    }

    .result-explain-container>ul>li>p {
        width: 910px;
        line-height: 1.875;
    }

    .result-explain-container>ul {
        width: 933px;
        min-width: 933px;
        float: left;
    }

    .result-explain-container>p {
        float: left;
    }

    .result-explain-container>ul>li>label {
        font-size: 16px;
        color: #282828;
        float: left;
        line-height: 1.9;
    }


    /* 股交所logo */

    #page-stock>.introduce-img {
        display: block;
        position: absolute;
        top: 0;
    }

    .icon_ {
        width: 48px;
        height: 48px;
        float: left;
        margin-right: 25px;
    }

    .icon_1 {
        height: 50px;
    }

    .icon_2 {
        width: 50px;
    }

    .icon_2 {
        width: 50px;
    }

    .title_top p {
        line-height: 48px;
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #121212;
    }

    .valueResultPic {
        width: 698px;
        height: 584px;
        background: url(../../assets/img/guzhi/resultPic.png)center no-repeat;
        background-size: cover;
        margin: 18px 0 0 163px;
        overflow: hidden;
    }

    .valueResultPic p {
        width: 448px;
        height: 30px;
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #0478fc;
        line-height: 31px;
        margin: 145px auto 0;
        text-align: center;
    }

    .valueResultCont {
        text-align: center;
    }

    .valueResultCont h6 {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 31px;
        margin: 37px auto;
    }

    .valueResultCont h6 span {
        line-height: 100%;
        margin-top: -2px;
    }

    .valueResultCont .resultDes {
        padding: 0 90px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #282828;
        line-height: 34px;
        text-align: left;
        text-indent: 36px;
    }

    .basicInfo {
        margin-top: 50px;
    }

    .situationCont ul,
    .baseInfoCont ul {
        padding: 25px 73px 0;
    }

    .baseInfoCont {
        height: 230px;
    }

    .situationCont {
        height: 100px;
    }

    .situationCont ul li,
    .baseInfoCont ul li {
        width: 50%;
        float: left;
    }

    .situationCont ul li span,
    .baseInfoCont ul li span {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #010000;
        line-height: 34px;
    }

    .mainBusinessCont p {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 34px;
        padding: 0 110px 0 73px;
        margin: 20px 0 0 0;
    }

    .productAndGroup {
        margin: 25px 0 0 0;
    }

    .product,
    .group {
        float: left;
        width: 50%;
    }

    .productAndGroup ul {
        padding: 25px 73px 0;
    }

    .productAndGroup li {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 34px;
    }

    #flowValuation,
    #finAnalysis {
        width: 690px;
        height: 400px;
        margin: 20px auto;
    }

    .industryEnvirContent,
    .enterpriseContent {
        width: 1008px;
        margin: 0 auto;
    }

    .product_Title {
        margin: 56px 0 0 116px;
    }

    .skillTitle,
    .riskTitle {
        margin-top: 56px;
    }

    #stock {
        width: 800px;
        height: 300px;
        margin: 20px auto;
    }

    .value_top {
        margin-top: 56px;
        margin-bottom: 38px;
    }

    .forecast {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 20px 0 49px 0;
    }


    /* 目录 */

    .cataloguePage h2 {
        font-size: 48px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #0F060B;
        margin: 56px auto 69px;
        text-align: center;
        line-height: 1;
    }

    .cataloguePage .generalize>div {
        width: 980px;
        height: 54px;
        margin: 23px auto 0px;
    }

    .cataloguePage .generalize>div p:first-child {
        font-size: 32px;
    }

    .cataloguePage .generalize p {
        float: left;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #0F060B;
        line-height: 54px;
        margin-right: 11px;
    }

    .cataloguePage .generalize>div>div {
        width: 6px;
        height: 54px;
        background: #0478FC;
        float: left;
        margin: 0 28px 0 0;
    }

    .cataloguePage .generalize .dottedLine {
        float: left;
        height: 2px;
        width: 736px;
        /* padding: 20px; */
        margin: 14px 20px 0;
        background: url(../../assets/img/report/catalogue_.png)left center repeat;
        /* border-top: 0.2px dotted #ccc; */
    }

    .cataloguePage .generalize .pageNumber {
        float: left;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #282828;
        line-height: 30px;
    }

    .cataloguePage .generalize ul {
        margin: 0 0 0 85px;
    }

    .cataloguePage .generalize ul li {
        height: 30px;
        margin: 9px 0 13px 0;
        width: calc(980px - 64px);
    }

    .cataloguePage .generalize ul li p {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #423838;
        line-height: 30px;
    }

    .cataloguePage .generalize ul li .dottedLine {
        width: 653px;
    }

    .cataloguePage .generalize .titleActive {
        margin-top: 26px;
    }

    .cataloguePage .generalize .pageNumActive {
        margin-top: 10px;
    }

    .cataloguePage .generalize ul.flowChart li .dottedLine {
        width: 761px;
    }
    
    .reportNumber {
        position: absolute;
        top: 6.5%;
        right: 45px;
        font-size: 18px;
        font-family: Microsoft YaHei;
    }
    .page-main>h4 {
        font-size: 38px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #0478fc;
        line-height: 100%;
        margin-top: 41px;
    }
    .detail_result {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-top: 54px;
        /* overflow: hidden; */
    }
    .stamp {
        width: 68%;
        line-height: 41px;
        margin: 10% auto;
        font-size: 18px;
        font-weight: 400;
        text-indent: 2em;
        margin-top: 31%;
        text-align: justify;
        color: #050407;
        font-family: Microsoft YaHei;
    }
    .stamptime {
        width: 68%;
        margin: 10% auto;
        font-size: 20px;
        text-align: right;
        line-height: 39px;
        margin-top: 29%;
    }
    .stamptime>p {
        font-size: 22px;
        margin-top: 29px;
        line-height: normal;
    }
    .detail_result>div {
        position: relative;
    }
    .detail_result h4 {
        float: left;
        height: 80px;
        margin-top: 6px;
        border-top: 3px solid #4393FF;
        border-bottom: 3px solid #4393FF;
        font-size: 38px;
        font-family: MicrosoftYaHei;
        color: #4393FF;
        line-height: 80px;
        padding: 0 34px;
        font-weight: bold;
    }
    .page-one>.artFont {
        width: 64px;
        height: 28px;
        margin: 0;
        position: absolute;
        top: 6.2%;
        right: 393px;
    }
    .page-stamp .artFont {
        width: 94px;
        height: 128px;
        position: absolute;
        top: -22px;
        left: -88px;
    }
    .pageNum {
        width: 57px;
        position: absolute;
        color: #000;
        line-height: 100%;
        font-size: 22PX;
        left: 50%;
        margin-left: -27px;
        bottom: 37px;
    }
    .pageNum p {
        color: #0478fc;
        display: inline;
        margin: 0 5px 0 0;
        font-size: 24px;
    }
    .pageNum span {
        font-size: 22px;
        margin-top: -3px;
    }
    .page-eleven .title_top {
        margin: 110px 0 0 0;
    }
    .statement-main div {
        margin: 30px 0 0 118px;
        overflow: hidden;
    }

    .statement-main div img {
        float: left;
        width: 138px;
        height: 138px;
        margin: 0 36px 20px 0;
    }

    .statement-main div ul {
        margin: 4px 0 0 0;
    }

    .statement-main div ul li {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #282828;
        line-height: 30px;
    }

    .statement-main div ul li span {
        width: 72px;
        text-indent: 0;
        text-align-last: justify;
        text-align: justify;
        text-justify: distribute-all-lines;
    }
</style>
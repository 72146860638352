<template>
        <div :id="id"></div>
</template>
<script>
import * as echarts from 'echarts/lib/echarts.js'
import formatCurrency from '../../assets/js/numberToCurrency'


export default ({
    props: [
        'chartData','id', 'year'
    ],
    data() {
        return {
            chartName:[],
            chartValue:[]
        }
    },
    mounted(){
        
        this.chartName = [this.year + "E", this.year + 1 + "E", this.year + 2 + "E", this.year + 3 + "E", this.year + 4 + "E"];
        this.chartValue = this.chartData.pillar;
        this.initChart()
    },
    methods: {
        initChart: function(){
                this.chart = echarts.init(document.getElementById(this.id));
                this.setOptions(this.id, this.chartName, this.chartValue)
        },
        setOptions: function(id, dataxAxis, data){
            var fontSize = 14;
            var data = data;
            var option = {
                title: {
                    show: true,
                    text: '现金流预测',
                    textStyle: {
                        color: '#282828',
                        align: 'center'
                    },
                    left: 'center',
                    top: 'top'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                    },
                    formatter: function(params) { //提示信息，为空时，显示成0
                        for (var i = 0; i < params.length; i++) {
                            if (params[i].value == "''") {
                                params[i].value = '0';
                            }
                        }
                        return params[0].marker + params[0].seriesName + "：" + formatCurrency.formateNum(params[0].value.toString(),2) + "<br>" +//.marker表示数据前面的小圆点
                            params[1].marker + params[1].seriesName + "：" + formatCurrency.formateNum(params[1].value.toString(),2) + "<br>" +
                            params[2].marker + params[2].seriesName + "：" + formatCurrency.formateNum(params[2].value.toString(),2) + "<br>" +
                            params[3].marker + params[3].seriesName + "：" + formatCurrency.formateNum(params[3].value.toString(),2);

                    },
                },
                legend: {
                    // radius:0,
                    data: ['净利润', '非现金支出', '资本性支出', '营运资本增加'],
                    bottom: 0,
                    textStyle: {
                        fontSize: fontSize
                    }
                },

                xAxis: {
                    type: 'category',
                    data: dataxAxis,
                    axisTick: {
                        show: false
                    },

                    axisLabel: {
                        textStyle: {
                            fontSize: fontSize
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    name: '万元',
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: true
                    },
                },
                series: [{
                        name: '净利润',
                        type: 'bar',
                        barMaxWidth: 50,
                        stack: '总量',
                        label: {
                            normal: {
                                show: true,
                                color: '#000000',
                                position: 'inside',
                                formatter: function(p) {
                                    return formatCurrency.formateNum(p.value.toString(),2);
                                },
                            }
                        },
                        itemStyle: {
                            color: '#4A83FF',
                            // borderColor: '#000',
                            // borderWidth: 0.5
                        },
                        data: this.change(data.netProfit)
                    },
                    {
                        name: '非现金支出',
                        type: 'bar',
                        barMaxWidth: 50,
                        stack: '总量',
                        label: {
                            normal: {
                                show: true,
                                color: '#000000',
                                position: 'inside',
                                formatter: function(p) {
                                    return formatCurrency.formateNum(p.value.toString(),2);
                                },
                            }
                        },
                        itemStyle: {
                            color: '#71C6FF',
                            // borderColor: '#000',
                            // borderWidth: 0.5
                        },
                        data: this.change(data.ebitda)
                    },
                    {
                        name: '资本性支出',
                        type: 'bar',
                        barMaxWidth: 50,
                        stack: '总量',
                        label: {
                            normal: {
                                show: true,
                                color: '#000000',
                                position: 'inside',
                                formatter: function(p) {
                                    return formatCurrency.formateNum(p.value.toString(),2);
                                },
                            }
                        },
                        itemStyle: {
                            color: '#74E7E7',
                            // borderColor: '#000',
                            // borderWidth: 0.5
                        },
                        data: this.change(data.capex)
                    },                                           
                    {
                        name: '营运资本增加',
                        type: 'bar',
                        barMaxWidth: 50,
                        stack: '总量',
                        label: {
                            normal: {
                                show: true,
                                color: '#000000',
                                position: 'inside',
                                formatter: function(p) {
                                    return formatCurrency.formateNum(p.value.toString(),2);
                                }
                            },
                        },
                        itemStyle: {
                            color: '#1D5CE3',
                            // borderColor: '#000',
                            // borderWidth: 0.5
                        },
                        data: this.change(data.workingCapital)
                    }
                ]
            };
			var myChart = echarts.getInstanceByDom(document.getElementById(id));
			if (myChart == undefined) {
				myChart = echarts.init(document.getElementById(id));
			}
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        },
        change: function(arr) { //柱状图每一项中数值为0时的处理方法
            var len = arr.length;
            for (var i = 0; i < len; i++) {
                if (arr[i] == 0) {
                    arr[i] = "''";
                }
            }
            return arr;
        }
    }
})
</script>
<style scoped>
</style>

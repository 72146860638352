<template>
	<div>
		<Header />
		<AuditStatusTitle />
		<div class="information viewContent">
			<ExamineNav />
			<div class="soft-container">
				<!-- 软性指标：题目内容-->
				<div class="soft-main" :style="obj2">
					<div v-for="(item, i) in questionBank" class="soft-sort" :id="item.contentType" :style="obj"
						ref="softSort">
						<p class="sort-tit"> <span></span> <span>{{item.contentName}}</span> </p>
						<div class="section">
							<div v-for="(topic, t) in item.contentDetails" class="row" data-required="1" id="">
								<div :class="['position-tit-container']">
									<span>{{t+1}}</span> <span class="postion-tit">{{topic.content}}</span>
								</div>
								<div class="options-container">
									<ul>
										<li :ref="answer.chooseFlag==1?'active':''"
											:class="answer.chooseFlag==1?'active':''"
											v-for="(answer, a) in topic.options">
											<input type="radio" :checked="answer.chooseFlag=='1'?'checked':''"
												:id="answer.id" :name="answer.questionId" :data-type="item.contentType"
												disabled>
											<label :for="answer.id">{{answer.content}}</label>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<Footer />
		<router-view />
	</div>
</template>

<script>
	import Header from '../header.vue';
	import Footer from '../footer.vue';
	import AuditStatusTitle from './auditStatusTitle.vue';
	import ExamineNav from './examineNav.vue';
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';

	export default {
		data() {
			return {
				questionBank: [],
				contentDetails: [],
				obj: {
					marginTop: 0,
					paddingTop: 40
				},
				obj2: {
					paddingTop: 0
				},
				projectName: ''

			}
		},
		components: {
			Header,
			Footer,
			AuditStatusTitle,
			ExamineNav
		},
		mounted() {
			if (this.$route.query.type == 7) {
				this.projectName = this.domain.diagnoseProject;
			} else if (this.$route.query.type == 5) {
				this.projectName = this.domain.traceProject;
			} else if (this.$route.query.type == 2) {
				this.projectName = this.domain.valuationProject;
			}
			if (this.$route.query.type == 5) {
				this.traceGetQuestion();
			} else {
				this.getQuestion();
			}
		},
		methods: {
			getQuestion: function() {
				var url = "";
				if (this.$route.query.type == 7) {
					if (sessionStorage.versions == 3) {
						url = this.projectName + 'auditInfoShow/getDiagnosticQuestion3';
					} else {
						url = this.projectName + 'auditInfoShow/getQuestion';
					}
				} else {
					url = this.projectName + 'auditInfoShow/getQuestion';
				}
				this.$post(url, qs.stringify({
						stage: sessionStorage.stage,
						reportType: sessionStorage.reportType,
						reportId: this.$route.query.reportId,
						version: sessionStorage.versions
					}))
					.then((res) => {
						console.log(res.data)
						this.questionBank = res.data.content;
						for (var i = 0; i < this.questionBank.length; i++) {
							this.total += this.questionBank[i].contentDetails.length;
						}
					});

			},
			traceGetQuestion: function() {
				this.$post(this.projectName + 'question/getAuditQuestion', qs.stringify({
						stage: sessionStorage.stage,
						reportId: this.$route.query.reportId,
						traceReportId: this.$route.query.traceReportId,
						year: this.$route.query.year
					}))
					.then((res) => {
						console.log(res.data)
						this.questionBank = res.data.content.question;
						for (var i = 0; i < this.questionBank.length; i++) {
							this.total += this.questionBank[i].contentDetails.length;
						}
					});
			},
		}


	}
</script>

<style scoped>
	.soft-sort {
		box-shadow: none;
		padding-bottom: 0px;
		margin-top: 20px !important;
	}

	.soft-sort:nth-of-type(1) {
		margin-top: 20px !important;
	}
	
	.sort-tit{
		border-bottom: 0;
	}
</style>
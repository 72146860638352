<template>
        <div :id="id"></div>
</template>
<script>
import * as Highcharts from 'highcharts'
import * as Exporting from 'highcharts/modules/exporting'
import Highcharts3D from 'highcharts/highcharts-3d'
Exporting(Highcharts)
Highcharts3D(Highcharts)
// import formatCurrency from '../../assets/js/numberToCurrency'

export default ({
    props: [
        'chartData','id'
    ],
    data() {
        return {
            
        }
    },
    mounted(){
        Highcharts.chart(this.id,{
                chart: {
                    type: 'pie',
                    options3d: {
                        enabled: true,
                        alpha: 45,
                        beta: 0
                    }   
                },
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                subtitle: {
                    // text: ''
                },
                labels: {
                    style: {
                        fontSize: 18
                    }
                },
                plotOptions: {
                    pie: {
                        innerSize: 200,
                        depth: 100,
                        // allowPointSelect: true,
                        cursor: 'pointer',
                        colors:[
                            "#418EFA","#18D5EE","#FA6600","#FF596D","#2EF783"
                        ],
                        dataLabels: {
                            enabled: true,
                            style: {
                                fontSize: "18px",
                            },
                            format: '{point.name}:{point.y}%'
                        },
                        slicedOffset: 20 //设置偏离中心点的距离
                    }
                },
                series: [{
                    data: this.chartData
                }]
            })
    },
    methods: {
    }
})
</script>
<style scoped>
</style>

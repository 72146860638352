<template>
        <div :id="id"></div>
</template>
<script>
import * as echarts from 'echarts/lib/echarts.js'
import formatCurrency from '../../assets/js/numberToCurrency'


export default ({
    props: [
        'chartData', 'id', 'year'
    ],
    data() {
        return {
            chartName: [],
            chartValue: [],
        }
    },
    mounted(){
        console.log(this.chartData)
        if(this.chartData){
            for(var i = 0;i < this.chartData.yearArray.length;i++){
                if(i >= 3){
                    this.chartName.push(this.chartData.yearArray[i] + 'E');
                } else {
                    this.chartName.push(this.chartData.yearArray[i]);
                }
            };
            if(this.id == "incomeForecast"){
                this.chartValue = this.chartData.netProfitArr;
            } else {
                this.chartValue = this.chartData.netArr;
            }
        } else {
            this.chartValue = [0,0,0,0,0,0,0,0];
            this.chartName = [this.year - 3, this.year - 2, this.year - 1, this.year, this.year + 1 + "E", this.year + 2 + "E", this.year + 3 + "E", this.year + 4 + "E", ]
        }

        this.initChart()
    },
    methods: {
        initChart: function(){
            this.chart = echarts.init(document.getElementById(this.id));
            this.setOptions(this.id, this.chartName, this.chartValue)
        },
        setOptions: function(id, dataxAxis, data){
            var option = {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    axisPointer: { type: 'none' },
                    textStyle: { fontSize: 12, fontWeight: 500 },
                    formatter: function(params) {
                        // 只展示柱子对应的内容，把顶部底部的 tooltip 过滤掉
                        return params.reduce((pre, i) => {
                            if (i.componentSubType === "line") {
                                i.marker = i.marker.replace(/\[object Object\]/, i.color);
                                i.value = `<span style="flex: 1; text-align: right; margin-left: 16px;">${formatCurrency.formateNum(i.value.toString(),2)}</span>`;
                                const current = `<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.axisValue} ${i.value}</div>`;
                                return `${pre}${current}`;
                            }
                            if (i.componentSubType === 'bar') {
                                i.marker = i.marker.replace(/\[object Object\]/, i.color.colorStops[1].color);
                                i.value = `<span style="flex: 1; text-align: right; margin-left: 16px;">${formatCurrency.formateNum(i.value.toString(),2)}</span>`;
                                const current = `<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.axisValue} ${i.value}</div>`;
                                return `${pre}${current}`;
                            }
                            return pre;
                        }, '');
                    },
                },
                grid: { left: '3%', right: '5%', bottom: '8%', containLabel: true },
                animation: false,
                xAxis: {
                    type: 'category',
                    data: dataxAxis,
                    axisLine: { lineStyle: { color: '#000' } },
                    axisTick: { show: false },
                    axisLabel: { color: '#585D63' },
                },
                yAxis: [{
                    axisLine: {
                        lineStyle: { color: '#111111' },
                        show: true
                    },
                    name: "（万元）",
                    axisTick: { show: true },
                    axisLabel: { color: '#111' },
                    splitLine: { lineStyle: { color: '#E1E1E1' } },

                }, ],
                series: [{
                        // name: incomeType,
                        type: 'bar',
                        barWidth: 44,
                        barCategoryGap: 12,
                        data: data,
                        itemStyle: {　　　　　　　　　 // 柱体渐变色
                            color: function(params) {
                                var colorList = [
                                    ['#49C9C9', '#74E7E7'],
                                    ['#398FF1', '#7CC1F5'],
                                    ['#8065FE', '#A18DFF'],
                                    ['#1750CB', '#4B84FF'],
                                    ['#1750CB', '#4B84FF'],
                                    ['#1750CB', '#4B84FF'],
                                    ['#1750CB', '#4B84FF'],
                                    ['#1750CB', '#4B84FF']
                                ]
                                var index = params.dataIndex;
                                if (params.dataIndex >= colorList.length) {
                                    index = params.dataIndex - colorList.length;
                                }
                                return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                                    { offset: 0, color: colorList[index][0] },
                                    { offset: 1, color: colorList[index][1] }
                                ])
                            }
                        },
                    },
                    {
                        // name: incomeType,
                        type: 'pictorialBar',
                        symbolSize: [44, 22],
                        // 这个属性很重要，直接决定了顶部跟柱子是否契合
                        // symbolOffset: [0, -12],
                        z: 12,
                        itemStyle: {

                            normal: {
                                label: {
                                    show: true,
                                    position: 'top', //在上方显示
                                    formatter: function(p) {
                                        return formatCurrency.formateNum(p.value.toString(),2);
                                    },
                                },
                                color: function(params) {
                                    var colorList = ['#74E7E7', '#7CC1F5', '#A18DFF', '#4B84FF', '#4B84FF', '#4B84FF', '#4B84FF', '#4B84FF'];
                                    var index = params.dataIndex;
                                    return params.color = colorList[index]
                                }
                            }
                        },
                        // symbolPosition: 'end',
                        // 要给成武汉这两个柱子的值，这是圆柱顶部，值不给对高度会对不上
                        data: this.pictorialBar2(data),
                    },

                ]
            }
			var myChart = echarts.getInstanceByDom(document.getElementById(id));
			if (myChart == undefined) {
				myChart = echarts.init(document.getElementById(id));
			}
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        },
        pictorialBar2: function(data) {
            var arr = [];
            data.forEach(function(ele, i) {
                if (ele < 0) {
                    arr[i] = {
                        value: ele,
                        symbolOffset: [0, 12],
                        symbolPosition: 'end'
                    }
                } else {
                    arr[i] = {
                        value: ele,
                        symbolOffset: [0, -12],
                        symbolPosition: 'end'
                    }
                }
            });
            return arr
        }
    }
})
</script>
<style scoped>
</style>

<template>
	<div>
		<div class="banner">
			<p>报告信息</p>
		</div>
		<div id="examineCon">
			<div class="examine-container">
				<span>
					<img src="../../assets/img/examine/icon_audit_title.png" alt="公司名-心流" />
					<span>{{info.createTime+'-'+info.companyName}}</span>
				</span>
				<span>
					<img src="../../assets/img/examine/icon_audit_valueType.png" alt="报告类型-心流" />
					<span>报告类型：{{this.$route.query.type==5?'价值追踪报告':this.$route.query.type==7?'企业价值诊断':'企业价值量化'}}</span>
				</span>
				<span>
					<img src="../../assets/img/examine/icon_audit_date.png" alt="支付日期-心流" />
					<span class="payTime">支付日期：{{info.payTime}}</span>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';


	export default {
		data() {
			return {
				info: {},
				moreBtn: false,
				seeMore: false,
				auditConent: '',
				completeAuditConent: ''

			}
		},
		mounted() {
			this.getPayBillInfo()
		},
		methods: {
			getPayBillInfo: function() {
				if (this.$route.query.type == 5) {
					var url = 'trace';
					var params = {
						reportId: this.$route.query.traceReportId
					}
				} else {
					var url = 'flow';
					var params = {
						reportId: this.$route.query.reportId
					}
				}

				this.$post(url + '/pay/getPayBillInfo', qs.stringify(params))
					.then((res) => {
						if (res.data.code == 200) {
							this.info = res.data.content;
						}
					})

				if (this.$route.query.auditStatus == 0) {
					this.$post(url + '/auditInfo/getAuditNotPassInfo', qs.stringify(params))
						.then((res) => {
							if (res.data.code == 200) {
								if (res.data.content.auditConent != null && res.data.content.auditConent.length >
									54) {
									this.moreBtn = true;
									this.completeAuditConent = res.data.content.auditConent;
									res.data.content.auditConent = res.data.content.auditConent.substring(0, 50);
								} else {
									res.data.content.auditConent = '';
								}
								this.auditConent = res.data.content.auditConent;
							}
						})
				}

			},
			readMore: function() {
				this.seeMore = true;
			}

		}
	}
</script>

<style scoped>
	.banner {
		width: 100%;
		height: 380px;
		background: url(../../assets/img/examine/examineBanner.jpg) center center no-repeat;
		background-size: cover;
		display: flex;
		align-items: center;
	}

	.banner>p {
		width: 1200px;
		margin: 0 auto;
		font-size: 41px;
		font-weight: bold;
		color: #282828;
	}

	.banner>p::after {
		content: '';
		width: 56px;
		height: 4px;
		background: #282828;
		margin-top: 29px;
		display: block;
	}

	#examineCon {
		height: auto;
	}

	.examine-container {
		width: 1200px;
		padding: 30px 92px 30px 30px;
		background: #fff;
		box-sizing: border-box;
		box-shadow: 0px 0px 13px 0px rgba(98, 136, 200, 0.23);
		margin: 75px auto 30px;
		display: flex;
		justify-content: space-between;
	}

	.examine-container>span img {
		height: 22px;
		margin-right: 10px;
		float: left;
	}

	.examine-container>span>span {
		height: 22px;
		line-height: 22px;
	}

	.examine-container>span:first-of-type>span {
		max-width: 340px;
		font-size: 16px;
		color: #282828;
		font-weight: bold;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.examine-container>span:nth-child(n+2)>span {
		font-size: 14px;
		color: #282828;
	}

	a.examing {
		color: #FFA14C;
		border: 1px solid #FFA14C;
		width: 90px;
		height: 30px;
		line-height: 30px;
		border-radius: 15px;
	}

	a.examing:hover {
		color: #FFA14C;
		border: 1px solid #FFA14C;
		width: 90px;
		height: 30px;
		line-height: 30px;
		border-radius: 15px;
		text-decoration: none;
	}



	a.examinedNo {
		color: #FF4343;
		border: 1px solid #FF4343;
		width: 90px;
		height: 30px;
		line-height: 30px;
		border-radius: 15px;
	}

	a.examinedNo:hover {
		color: #FF4343;
		border: 1px solid #FF4343;
		width: 90px;
		height: 30px;
		text-decoration: none;
		line-height: 30px;
		border-radius: 15px;
	}



	a.examined {
		width: 120px;
		height: 36px;
		text-align: center;
		line-height: 36px;
		border-radius: 6px;
		background: #0478fc;
		color: #fff;
	}

	a.examined :hover {
		width: 120px;
		height: 36px;
		text-align: center;
		line-height: 36px;
		text-decoration: none;
		border-radius: 6px;
		background: #0478fc;
		color: #fff;
	}

	.examine-status {
		/*height: 122px;*/
		background: #F0F6FF;
	}



	div.examined-status {
		height: 14px;
		background: #f1f1f1;
	}

	div.examined-status>span {
		display: none;
	}



	.examine-status>span {
		display: block;
		margin: 0 auto;
		width: 1200px;
		box-sizing: border-box;
		padding: 32px 0 32px 50px;
	}

	.examine-status>span>img {
		height: 58px;
		margin-right: 22px;
		float: left;
	}

	.examine-status>span>p {
		color: #282828;
		font-size: 16px;
	}

	.examine-status>span>p:first-of-type {
		font-size: 18px;
		line-height: 1;
		font-weight: bold;
	}

	.examine-status>span>div:last-of-type,
	.examine-status>span>p:last-of-type {
		font-size: 16px;
		margin-top: 16px;
		padding-left: 78px;
	}



	.examine-status>span.examinedNo-con>p:first-of-type {
		margin-top: -10px;
	}

	.examine-status>span.examinedNo-con>p:nth-of-type(2),
	.examine-status>span.examinedNo-con>.reason {
		font-size: 16px;
		margin-top: 12px;
		text-align: justify;
	}

	.examine-status button {
		display: block;
		width: 100px;
		height: 40px;
		margin-left: 78px;
		margin-top: 17px;
		background: #fff;
		border: 1px solid rgba(67, 147, 255, 1);
		border-radius: 6px;
		cursor: pointer;
		color: rgba(67, 147, 255, 1);
	}

	.examine-nav {
		box-sizing: border-box;
		width: 1200px;
		padding-left: 50px;
		padding-top: 46px;
		margin: 0 auto;
	}

	.examine-nav ul {
		border-bottom: 1px solid #4393FF;
		overflow: hidden;
	}

	.examine-nav li {
		box-sizing: border-box;
		width: 158px;
		height: 40px;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		border: 1px solid #0478fc;
		float: left;
		margin-right: 40px;
		border-bottom: 0;
		text-align: center;
		line-height: 40px;
	}

	.examine-nav li a {
		display: block;
		font-size: 16px;
		color: #0478fc;
	}

	.examine-nav li.active a {
		background: #0478fc;
		color: #fff;
		width: 155px;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		border: 1px solid #0478fc;
	}

	.examine-nav li a:hover {
		background: #0478fc;
		color: #fff;
	}

	.examine-main input:disabled {
		background: #fff;
	}



	.examine-trace-nav {
		box-sizing: border-box;
		width: 1200px;
		padding-left: 50px;
		padding-top: 38px;
		margin: 0 auto;
	}

	.examine-trace-nav>ul {
		width: 100%;
		border-top: 1px solid #CACACA;
		border-bottom: 1px solid #CACACA;
		height: 65px;
		box-sizing: border-box;
	}

	.examine-trace-nav>ul>li {
		float: left;
		font-size: 16px;
		line-height: 65px;
		margin-right: 98px;
		position: relative;
		cursor: pointer;
		box-sizing: border-box;
		height: 100%;
	}

	.examine-trace-nav>ul>li.active,
	.examine-trace-nav>ul>li:hover {
		color: #0478fc;
		border-bottom: 6px solid #0478fc;
	}

	pre {
		white-space: pre-wrap;
		word-wrap: break-word;
	}

	.more {
		cursor: pointer;
		color: #4393FF;
		margin-left: 35px;
	}

	@media screen and (max-width: 750px) {
		.banner {
			display: none;
		}

		.examine-container {
			width: 100%;
			padding-left: 20px;
			display: block;
			position: relative;
			box-shadow: none;
			margin: 0;
		}

		.examine-container>span {
			display: block;
			margin-bottom: 35px;
		}

		.examine-container>span:last-child {
			margin-bottom: 0;
		}

		.examine-container>span img {
			height: 36px;
		}

		.examine-container>span:first-of-type {
			border: none;
		}

		.examine-container>span:first-of-type>span {
			font-size: 16PX;
		}

		.examine-container>span:nth-child(2) {
			padding: 0;
		}

		.examine-container>span:nth-child(n+2)>span {
			font-size: 14PX;
		}

		.examine-container>a {
			position: absolute;
			right: 20px;
			top: 54%;
			font-size: 14PX;
			width: 140px;
			height: 50px;
			line-height: 50px;
			border-radius: 25px;
		}

		.examine-status>span {
			width: 100%;
			padding: 30px 20px;
		}

		.examine-status>span.examinedNo-con p {
			font-size: 14PX;
		}

		.examine-status>span>p:first-of-type,
		.examine-status>span.examinedNo-con>p:first-of-type {
			font-size: 16PX;
		}

		.examine-status>span>p:last-of-type,
		.examine-status>span.examinedNo-con>.reason {
			font-size: 14PX;
		}

		.more {
			margin-left: 70%;
		}

		.examine-top {
			border: none;
		}

	}
</style>
<template>
	<div>
		<div class="examine-nav" v-if="this.$route.query.type==5">
			<ul>
				<li v-for="(item, index) in traceList" :class="{active:index==yearCurrent}"
					@click="changeYear(item.year,index)">
					<a>{{item.year}}年</a>
				</li>
			</ul>
		</div>
		<div class="valuationTopNav">
			<ul v-if="this.$route.query.type==5">
				<li v-if="item.show" v-for="(item, index) in navList" :class="{active:index==current}"
					@click="categoryNav(index)">
					{{item.text=='审核状态'?item.text:traceYear+'年'+item.text}}
				</li>
			</ul>
			<ul v-if="this.$route.query.type!=5">
				<li v-if="item.show" v-for="(item, index) in navList" :class="{active:index==current}"
					@click="categoryNav(index)">
					{{item.text}}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import qs from 'qs';
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex'
	import '../../assets/libs/js/jquery-1.11.1.min.js';

	export default {
		data() {
			return {
				current: 0,
				versions: sessionStorage.versions,
				navList: [{
						text: '审核状态',
						show: true,
						path: '/auditStatus'
					}, {
						text: '基本信息',
						show: true,
						path: '/examineInformation'
					}, {
						text: '软性指标',
						show: true,
						path: '/examineSoftInformation'
					},
					{
						text: '财务指标',
						show: true,
						path: '/valuationProfessionationHinanQuato'
					}, {
						text: '财务分析',
						show: true,
						path: '/examineFinancialAnalysis'
					}
				],
				traceList: []
			}
		},
		computed: {
			...mapState(['currentPageModify', 'currentPageSave', 'traceYear', 'yearCurrent'])
		},
		mounted() {
			if (this.$route.query.type == 5) {
				this.initNavYear()
				this.changeTraceYear(this.$route.query.year)
			} else if (this.$route.query.type == 7 && sessionStorage.versions == 3) {
				this.navList[4].show = false;
			}


			if(this.$route.path == '/examineInformation'){
				this.current = 1;
			}else if (this.$route.path == '/examineSoftInformation') {
				this.current = 2;
			} else if (this.$route.path == '/valuationProfessionationHinanQuato') {
				this.current = 3;
			} else if (this.$route.path == '/examineFinancialAnalysis') {
				this.current = 4;
			}
			
			if(this.$route.query.auditStatus==1){ //审核通过不展示审核状态栏
				this.navList[0].show=false;
			}
			
			console.log(this.navList)

		},
		methods: {
			...mapMutations(['changeTraceYear', 'changeCurrentYearIdx']),
			initNavYear: function() {
				var this_ = this;
				this.$post('trace/traceRelation/getTraceListByTraceId', qs.stringify({
						traceReportId: this.$route.query.traceReportId
					}))
					.then((res) => {
						this.traceList = res.data.content;
						res.data.content.forEach((item, i) => {
							if (item.year == this.$route.query.year) {
								this.changeCurrentYearIdx(i)
							}
						})

					});
			},
			categoryNav: function(i) {
				this.current = i;
				if (this.$route.query.type == 5) {
					this.$router.push({
						path: this.navList[i].path,
						query: {
							type: 5,
							reportId: this.$route.query.reportId,
							traceReportId: this.$route.query.traceReportId,
							year: this.traceYear,
							auditStatus: this.$route.query.auditStatus
						}
					})
				} else {
					this.$router.push({
						path: this.navList[i].path,
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type,
							reportId: this.$route.query.reportId,
							auditStatus: this.$route.query.auditStatus
						}
					})
				}
			},
			changeYear: function(year, i) {
				this.changeTraceYear(year)
				this.changeCurrentYearIdx(i)
				this.$router.replace({ //跳转到空白的中转页
					path: '/refresh',
					query: {
						type: 5,
						reportId: this.$route.query.reportId,
						traceReportId: this.$route.query.traceReportId,
						year: year,
						auditStatus: this.$route.query.auditStatus
					}
				})
			}
		}



	}
</script>

<style scoped>
	.valuationTopNav {
		background: #fff;
		padding-left: 0;
	}

	.valuationTopNav>ul {
		width: 100%;
		height: 63px;
		line-height:63px;
		overflow: hidden;
		border-bottom: 1px solid #E0E0E0;
		padding: 0 40px;
	}

	.valuationTopNav>ul>li {
		float: left;
		font-size: 18PX;
		height: 63px;
		margin-right: 90px;
		color: #282828;
		cursor: pointer;
	}

	.valuationTopNav>ul>li:first-of-type {
		margin-left: 0;
	}

	.valuationTopNav>ul>li.active {
		color: #1459F4;
		border-bottom: 3px solid #1459F4;
	}

	.examine-nav {
		box-sizing: border-box;
		width: 1200px;
		padding: 30px 40px 40px;
		margin: 0 auto;
	}
	.examine-nav+.valuationTopNav{
		border-top: 1px solid #E0E0E0;
	}
	.examine-nav ul {
		border-bottom: 1px solid #1459F4;
		overflow: hidden;
	}

	.examine-nav li {
		box-sizing: border-box;
		width: 158px;
		height: 40px;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		border: 1px solid #1459F4;
		float: left;
		margin-right: 40px;
		border-bottom: 0;
		text-align: center;
		line-height: 40px;
	}

	.examine-nav li a {
		display: block;
		font-size: 16PX;
		color: #1459F4;
	}

	.examine-nav li.active a {
		background: #1459F4;
		color: #fff;
		width: 100%;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		border: 1px solid #1459F4;
	}

	.examine-nav li a:hover {
		background: #1459F4;
		color: #fff;
	}


	@media screen and (max-width: 750px) {

		.valuationTopNav,
		.examine-nav {
			width: 100%;
			height: 110px;
			overflow-x: scroll;
			position: relative;
		}
		.examine-nav{
			padding: 10px 0 15px;
		}
		
		.valuationTopNav>ul {
			width: 800px;
			height: 80px;
			line-height: 80px;
			border-top: 1px solid #E0E0E0;
			padding: 0;
			position: absolute;
			top: 0;
			left: 0;
		}

		.valuationTopNav>ul>li {
			height: 78px;
			line-height: 78px;
			margin-right: 50px;
		}

		.examine-nav ul {
			width: 1100px;
		}

		.examine-nav li {
			width: 180px;
			height: 60px;
			line-height: 60px;
		}
	}
</style>
<template>
	<div>
		<Header />
		<AuditStatusTitle />
		<div class="viewContent">
			<ExamineNav />
			<div id="isverify">
				<div class="audit_refuse">
					<div class="auditContain">
						<img src="../../assets/img/examine/audit_refuse.png" alt="线上估值-心流">
						<div>
							<h3 v-if="this.$route.query.auditStatus==0">
								您的{{this.$route.query.type==5?'价值追踪报告':this.$route.query.type==7?'价值诊断报告':'价值量化报告'}}审核未通过！
							</h3>
							<h3 v-else-if="this.$route.query.auditStatus==2" class="audit">
								您的{{this.$route.query.type==5?'价值追踪报告':this.$route.query.type==7?'价值诊断报告':'价值量化报告'}}审核中，请耐心等待！
							</h3>
							<span v-if="this.$route.query.auditStatus==0">您可修改信息，重新提交审核</span>
							<p>如有疑问请拨打心流客服电话：+86 (10)8444-6599</p>
							<button v-if="this.$route.query.auditStatus==0" @click="jumpTo">立即修改</button>
						</div>
					</div>
					<div class="reason" v-if="completeAuditConent">
						<span>审核未通过原因：</span>
						<pre>{{completeAuditConent}}</pre>
					</div>
				</div>
			</div>
		</div>
		<Footer />
		<router-view />
	</div>
</template>

<script>
	import Header from '../header.vue';
	import Footer from '../footer.vue';
	import AuditStatusTitle from './auditStatusTitle.vue';
	import ExamineNav from './examineNav.vue';
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';
	import '../../assets/css/common.css';
	export default {
		data() {
			return {
				completeAuditConent: ''
			}
		},
		components: {
			Header,
			Footer,
			AuditStatusTitle,
			ExamineNav
		},
		mounted() {
			this.getPayBillInfo()
		},
		methods: {
			getPayBillInfo: function() {
				if (this.$route.query.type == 5) {
					var url = 'trace';
					var params = {
						reportId: this.$route.query.traceReportId
					}
				} else {
					var url = 'flow';
					var params = {
						reportId: this.$route.query.reportId
					}
				}
				if (this.$route.query.auditStatus == 0) {
					this.$post(url + '/auditInfo/getAuditNotPassInfo', qs.stringify(params))
						.then((res) => {
							console.log(res.data)
							if (res.data.code == 200) {
								if (res.data.content.auditConent) {
									this.completeAuditConent = res.data.content.auditConent;
								}
							}
						})
				}

			},
			jumpTo: function() {
				if (this.$route.query.type == 5) {
					this.$router.push({
						path: '/basicInformation',
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type,
							reportId: this.$route.query.reportId,
							traceReportId: this.$route.query.traceReportId,
							year: this.$route.query.year
						}
					})
				} else {
					this.$router.push({
						path: '/basicInformation',
						query: {
							stage: sessionStorage.stage,
							type: sessionStorage.reportType,
							reportId: res.data.content.valuationReportId
						}
					});
				}
			}
		}



	}
</script>

<style scoped>
	#isverify {
		padding: 40px;
	}

	.auditContain img {
		width: 219px;
	}

	.auditContain {
		width: 576px;
		margin: 60px auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
	}

	.auditContain>div span {
		margin-top: 8px;
		color: #1459F4;
	}

	.auditContain>div h3 {
		color: #FF0000;
	}

	.auditContain>div p {
		color: #282828;
		margin-top: 19px;
	}

	.auditContain>div button {
		width: 81px;
		height: 29px;
		line-height: 29px;
		text-align: center;
		border: 1px solid #1459F4;
		color: #1459F4;
		outline: none;
		background: #fff;
		margin-top: 26px;
		margin-left: 82px;
		cursor: pointer;
	}

	.audit_refuse .reason {
		width: 912px;
		margin: 0 auto;
		font-size: 14px;
	}

	.auditContain>div h3.audit {
		color: #EE9016;
	}

	.soft-sort:nth-of-type(1) .grade {
		margin-bottom: 30px;
	}

	.reason pre {
		line-height: 27px;
		margin-top: 10px;
		white-space: pre-wrap;
		/* css-3 */
		white-space: -moz-pre-wrap;
		/* Mozilla, since1999 */
		white-space: -pre-wrap;
		/* Opera 4-6 */
		white-space: -o-pre-wrap;
		/*Opera 7 */
		word-wrap: break-word;
		/* Internet Explorer 5.5+ */
	}
	
	@media screen and (max-width: 750px) {
		#isverify{
			padding: 0;
		}
		.auditContain{
			width: 100%;
			padding: 0;
		}
		.audit_refuse .reason{
			width: 100%;
		}
	}
	
</style>
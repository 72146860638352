<template>
    <div>
        <div class="titleBox">
            <span>附件2</span>
            <div class="title">利润表</div>
            <span>单位:元</span>
        </div>
        <table class="table profitTableInfo" border="0" style="border-collapse: collapse;margin-bottom: 70px;" :class="versions == 1 ? 'active':''">
            <thead>
                <tr>
                    <th style="width: 37%;">项目</th>
                    <th v-for="(item,index) in year" :key="index">{{item + dateFormate}}</th>
                </tr>
            </thead>
            <tbody v-if="versions != 1">
                <tr class="odd">
                    <td class="four" style="padding-left: 95px;">(2)权益法下不能转损益的其他综合收益</td>
                    <td v-for="(item,index) in (profitTableInfo.notTransferOci ? profitTableInfo.notTransferOci : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="odd">
                    <td class="four" style="padding-left: 95px;">(3)其他权益工具投资公允价值变动</td>
                    <td v-for="(item,index) in (profitTableInfo.otherEquityFairValue ? profitTableInfo.otherEquityFairValue: ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="odd">
                    <td class="four" style="padding-left: 95px;">(4)企业自身信用风险公允价值变动</td>
                    <td v-for="(item,index) in (profitTableInfo.enterpriseCreditRiskFairValue ? profitTableInfo.enterpriseCreditRiskFairValue: ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="odd grey noInput reclassifiedOci">
                    <td class="three">2.将重分类进损益的其他综合收益</td>
                    <td v-for="(item,index) in (profitTableInfo.reclassifiedOci ? profitTableInfo.reclassifiedOci : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
            </tbody>
            <tbody>
                <tr class="odd">
                    <td class="four" style="padding-left: 95px;">(1)权益法下可转损益的其他综合收益</td>
                    <td v-for="(item,index) in (profitTableInfo.transferOci ? profitTableInfo.transferOci : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="odd">
                    <td class="four" style="padding-left: 95px;">(2)其他债权投资公允价值变动</td>
                    <td v-for="(item,index) in (profitTableInfo.otherClaimsFairValue ? profitTableInfo.otherClaimsFairValue : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="odd">
                    <td class="four" style="padding-left: 95px;">(3)金融资产重分类计入其他综合收益的金额</td>
                    <td v-for="(item,index) in (profitTableInfo.financialAssetsOciAmount ? profitTableInfo.financialAssetsOciAmount : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="odd">
                    <td class="four" style="padding-left: 95px;">(4)其他债权投资信用减值准备</td>
                    <td v-for="(item,index) in (profitTableInfo.otherClaimsCreditImpairment ? profitTableInfo.otherClaimsCreditImpairment : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="odd">
                    <td class="four" style="padding-left: 95px;">(5)现金流量套期储备</td>
                    <td v-for="(item,index) in (profitTableInfo.cashFlowHedgingReserve ? profitTableInfo.cashFlowHedgingReserve : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="odd">
                    <td class="four" style="padding-left: 95px;">(6)外币财务报表折算差额</td>
                    <td v-for="(item,index) in (profitTableInfo.foreignCurrencyBalance ? profitTableInfo.foreignCurrencyBalance : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="minorityStockOciNetTax">
                    <td class="two">(二)归属于少数股东的其他综合收益的税后净额</td>
                    <td v-for="(item,index) in (profitTableInfo.minorityStockOciNetTax ? profitTableInfo.minorityStockOciNetTax : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>

                <!--六：综合收益-->
                <tr class="bold grey noInput totalComprehensiveIncome">
                    <td>六、综合收益总额</td>
                    <td v-for="(item,index) in (profitTableInfo.totalComprehensiveIncome ? profitTableInfo.totalComprehensiveIncome : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="grey noInput parentCompanyTci">
                    <td class="two">(一)归属于母公司所有者的综合收益总额</td>
                    <td v-for="(item,index) in (profitTableInfo.parentCompanyTci ? profitTableInfo.parentCompanyTci : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="grey noInput minorityStockTci">
                    <td class="two">(二)归属于少数股东的综合收益总额</td>
                    <td v-for="(item,index) in (profitTableInfo.minorityStockTci ? profitTableInfo.minorityStockTci : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <!--七. 每股收益-->
                <tr class="bold grey noInput">
                    <td>七、每股收益</td>
                    <td v-for="(item,index) in profitTableInfo.income" :key="index + 1"></td>
                </tr>
                <tr class="odd">
                    <td class="two">(一)基本每股收益</td>
                    <td v-for="(item,index) in (profitTableInfo.basicEps ? profitTableInfo.basicEps : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr class="odd">
                    <td class="two">(二)稀释每股收益</td>
                    <td v-for="(item,index) in (profitTableInfo.dilutedEps ? profitTableInfo.dilutedEps : ['','',''])" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
            </tbody>
        </table>
        <div class="title" v-if="versions != 'trace'">财务比率表</div>
        <table  v-if="versions != 'trace'" class="table financeRatio" border="0" style="border-collapse: collapse;margin-top:47px;">
            <thead>
                <tr class="tableT">
                    <th style="width: 25%;">财务比率</th>
                    <th style="width: 25%;" v-for="(item,index) in year" :key="index">{{item}}</th>
                </tr>
            </thead>
            <tbody v-if="versions != 1">
                <tr>
                    <td>资产负债率（%）</td>
                    <td v-for="(item,index) in financeRatio.liabilitiesRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>流动比率</td>
                    <td v-for="(item,index) in financeRatio.curAssetsRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>EBITl利息保障倍数</td>
                    <td v-for="(item,index) in financeRatio.ebitMulRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>毛利率（%）</td>
                    <td v-for="(item,index) in financeRatio.grossProfitRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>净利润率（%）</td>
                    <td v-for="(item,index) in financeRatio.netProfitRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>主营业务收入增长率（%）</td>
                    <td v-for="(item,index) in financeRatio.incomeRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>净资产收益率（%）</td>
                    <td v-for="(item,index) in financeRatio.netAssetsRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>应收账款周转率</td>
                    <td v-for="(item,index) in financeRatio.receivableRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>存货周转率</td>
                    <td v-for="(item,index) in financeRatio.inventoryRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
            </tbody>
            <tbody class="tbodyNewEdtion" v-else>
                <tr>
                    <td>总资产收益率（%）</td>
                    <td v-for="(item,index) in financeRatio.totalAssetsReturnOn" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>销售费用率（%）</td>
                    <td v-for="(item,index) in financeRatio.saleCostRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>管理费用率（%）</td>
                    <td v-for="(item,index) in financeRatio.manageCostRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>研发费用率（%）</td>
                    <td v-for="(item,index) in financeRatio.researchCostRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>毛利率（%）</td>
                    <td v-for="(item,index) in financeRatio.grossProfitRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>净利润率（%）</td>
                    <td v-for="(item,index) in financeRatio.netProfitRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>主营业务收入增长率（%）</td>
                    <td v-for="(item,index) in financeRatio.incomeRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>利润总额增长率（%）</td>
                    <td v-for="(item,index) in financeRatio.totalProfitRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>净资产收益率（%）</td>
                    <td v-for="(item,index) in financeRatio.netAssetsRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>资产负债率（%）</td>
                    <td v-for="(item,index) in financeRatio.liabilitiesRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>流动比率</td>
                    <td v-for="(item,index) in financeRatio.curAssetsRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>速动比率</td>
                    <td v-for="(item,index) in financeRatio.quickRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>EBITl利息保障倍数</td>
                    <td v-for="(item,index) in financeRatio.ebitMulRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>应收账款周转率</td>
                    <td v-for="(item,index) in financeRatio.receivableRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>总资产周转率</td>
                    <td v-for="(item,index) in financeRatio.totalAssetsTurnover" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
                <tr>
                    <td>存货周转率</td>
                    <td v-for="(item,index) in financeRatio.inventoryRatio" :key="index + 1">{{formatCurrency.formateNum(item, 2)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import formatCurrency from '../../assets/js/numberToCurrency'
export default ({
    props: [
        'profitTableInfo', 'year', 'financeRatio', 'versions'
    ],
    data() {
        return {
            dateFormate: "度"
        }
    },
    mounted(){
        if(this.versions == 1){
            this.dateFormate = "";
        } else if(this.versions == 'trace'){
            this.dateFormate = "年度"
        }
    },
    methods: {

    }
})
</script>
<style scoped>
    .titleBox {
        margin: 130px 0 0 0;
        padding: 0px 110px;
        display: flex;
        justify-content: space-between;
    }
    .titleBox span {
        line-height: 100%;
        font-size: 20px;
    }
    
    .titleBox .title {
        text-align: center;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #282828;
        margin: 130px 0 0 0;
        line-height: 100%;
    }
    table {
        padding: 0 110px;
        width: calc(100% - 220px);
        text-align: center;
        border-collapse: collapse;
        margin: 10px auto 0;
        border: 1px solid #000;
    }
    table.active>tbody>tr {
        /* text-align: left; */
        height: 38px;
    }
    table>tbody>tr>td:first-of-type {
        padding-left: 30px;
        text-align: left;
    }
    .title {
        margin: 0 !important;
    }
    .title {
        text-align: center;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #282828;
        margin: 130px 0 0 0;
        line-height: 100%;
    }
    table tr td,
    table tr th {
        border: 1px solid #333;
        height: auto;
    }
    table>tbody>tr:first-of-type>td {
        border-top: none;
    }
    table tr th {
        border-bottom-color:#4393ff;
    }
    table>thead>tr>th {
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold;
        color: #fff;
        line-height: 44px;
    }
    tbody.tbodyNewEdtion>tr {
        height: 43px;
    }
</style>

<template>
	<div id="softInformation">
		<Header />
		<uploadData />
		<navigation />
		<div id="main" class="softInformation information">
			<p class="moduleName">
				<img src="../../assets/img/navIcon/softnessSele.png" alt="软性指标模块-心流">
				<span>{{this.$route.query.type==5?this.$route.query.year+'年':''}}软性指标</span>
			</p>
			<!-- 软性指标主要内容-->
			<div class="soft-container">
				<!-- 导航栏-->
				<div id="soft_type" :class="[scrollTop>700?'float-box-fixed':'']">
					<div :class="['float-box']">
						<ul>
							<li :class="{active:i==current}" v-for="(item, i) in questionBank" @click="indicator(i)"
								ref="guideLis">
								<a :href="`#${item.contentType}`">{{item.contentName}}</a>
							</li>
						</ul>
						<!-- 完成度-->
						<div class="completion">
							<span>已完成</span>
							<span><em id="num">{{selected}}</em><em>/</em><em id="total">{{total}}</em></span>
						</div>
					</div>

				</div>
				<!-- 软性指标：题目内容-->
				<div class="soft-main" :style="obj2">
					<div v-for="(item, i) in questionBank" class="soft-sort" :id="item.contentType" :style="obj"
						ref="softSort">
						<p class="sort-tit"> <span></span> <span>{{item.contentName}}</span> </p>
						<div class="section">
							<div v-for="(topic, t) in item.contentDetails" class="row" data-required="1" id="">
								<div :class="['position-tit-container',noChooseRed?'no-choose-red':'']">
									<span>{{topic.contval}}</span> <span class="postion-tit">{{topic.content}}</span>
								</div>
								<div class="options-container">
									<ul>
										<li v-if="status!=1" :ref="answer.chooseFlag==1?'active':''"
											:class="answer.chooseFlag==1?'active':''"
											v-for="(answer, a) in topic.options"
											@click.stop.prevent="selectTopic(i,t,a,answer,$event)">
											<input type="radio" :checked="answer.chooseFlag=='1'?'checked':''"
												:id="answer.id" :name="answer.questionId" :data-type="item.contentType">
											<label :for="answer.id">{{answer.content}}</label>
										</li>

										<li v-if="status==1" :ref="answer.chooseFlag==1?'active':''"
											:class="answer.chooseFlag==1?'active':''"
											v-for="(answer, a) in topic.options">
											<input type="radio" :checked="answer.chooseFlag=='1'?'checked':''"
												:id="answer.id" :name="answer.questionId" :data-type="item.contentType"
												disabled>
											<label :for="answer.id">{{answer.content}}</label>
										</li>

									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="saveBox">
				<span class="last lastStep" @click="lastStep()">上一步</span>
				<span class="save" id="saveButton" data-isClick="isClick" @click="saveButton()">保 存</span>
			</div>
		</div>

		<Modal v-model="myAlert" class-name="vertical-center-modal" :mask-closable="false">
			<img class='warning' src="../../assets/img/remind-01@2x.png">
			<span>您有必填项未填写，请填写完成后再保存</span>
			<div slot="footer" class="ivu-sure" @click="submitDialog">确定</div>
		</Modal>

		<!-- 缓存提示框 -->
		<Modal v-model="cacheAlert" class-name="vertical-center-modal" :mask-closable="false"  @on-cancel="clickCacheMaskJump">
				<img class='warning-cache' src="../../assets/img/caution.png">
				<span class="cache-title">您上次填写过估值报告，是否载入缓存信息？</span>
				<div slot="footer" class="ivu-no" @click="clickCacheTrueMaskJump">是</div>
				<div slot="footer" class="ivu-sure-cache" @click="clickCacheMaskJump">否</div>
		</Modal>
		<Footer />
		<router-view />
	</div>
</template>

<script>
	import Header from '../header.vue';
	import Footer from '../footer.vue';
	import navigation from './navigation.vue';
	import uploadData from './uploadData.vue';
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';
	import '../../assets/css/softInformation.css';

	export default {
		data() {
			return {
				current: 0, // 当前选中的是哪个指标
				questionBank: [],
				contentDetails: [],
				selected: 0, //当前选的几道题
				total: 0, //总题数
				scrollTop: 0, //滚动条距离顶部的距离
				obj: {
					marginTop: 0,
					paddingTop: 40
				},
				obj2: {
					paddingTop: 0
				},
				noChooseRed: false,
				myAlert: false,
				isClick: false,
				status: 2, // 判断当前页面是否可以修改  1代表不能修改   (1 审核通过  2 未填写完成  3 填写完成未支付)
				projectName:'',
				cacheAlert:false
			}
		},
		components: {
			Header,
			Footer,
			navigation,
			uploadData
		},
		mounted() {
			if (this.$route.query.type == 7) {
				this.projectName = this.domain.diagnoseProject;
			} else if (this.$route.query.type == 5) {
				this.projectName = this.domain.traceProject;
			} else if (this.$route.query.type == 2) {
				this.projectName = this.domain.valuationProject;
			}
			this.pageModify('false');
			if (this.$route.query.type == 5) {
				this.traceGetQuestion();
			} else {
				this.getQuestion();
			}
		},
		updated() {
			if (this.$refs.active) {
				this.selected = this.$refs.active.length;
			}
			// 判断需不需要缓存
			// console.log(this.selected)
			// console.log(this.total)
			if(this.selected != this.total){
				// 是缓存
				this.cacheOrNot = '0'
			}
		},
		computed: {
			...mapState(['channel', 'reportId', 'currentPageModify', 'currentPageSave'])
		},
		watch: {
			// 利用watch方法检测路由变化：
			'$route': function(to, from) {
				// 拿到目标参数 to.query.id 去再次请求数据接口
				if (this.$route.query.type == 5) {
					this.traceGetQuestion()
				}
			}
		},
		methods: {
			...mapMutations(['pageModify']),
			getQuestion: function(close) {
				this.$Spin.show(); //全局加载中
				var url = "";
				if (this.$route.query.type == 7 && sessionStorage.versions == 3) {
					url = this.projectName + 'question/getDiagnosticQuestion3';
				} else {
					url = this.projectName + 'question/getQuestion';
				}
				this.$post(url, qs.stringify({
						stage: sessionStorage.stage,
						reportType: sessionStorage.reportType,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						version: sessionStorage.versions
					}))
					.then((res) => {
						console.log(res.data)
						this.$Spin.hide();
						this.questionBank = res.data.content;

						// 缓存数据逻辑====================================
						this.isCache = res.data.content[0].contentDetails[0].options[0].isCache
						if(res.data.content[0].contentDetails[0].options[0].isCache == "0" && !sessionStorage.isHistoryCache){
							this.cacheAlert = true;

							// 默认不选中
							for (var i = 0; i < res.data.content.length; i++) {
									for (var k = 0; k < res.data.content[i].contentDetails.length; k++) {
										for (var j = 0; j < res.data.content[i].contentDetails[k].options.length; j++) {
												res.data.content[i].contentDetails[k].options[j].chooseFlag = '0'
										}
									}	
							}
						}

						
						// 关闭  调用清除缓存
						if(res.data.content[0].contentDetails[0].options[0].isCache  == '0' && close=='0'){
							this.method.clearCaching(this.$route.query.type,this.$route.query.stage,this)
						}

						// 总条数
						this.total = 0;
						for (var i = 0; i < this.questionBank.length; i++) {
							this.total += this.questionBank[i].contentDetails.length;
						}

						// 循环条数
						var cont = 0
						for (var i = 0; i < res.data.content.length; i++) {
								for (var k = 0; k < res.data.content[i].contentDetails.length; k++) {
										this.$set(res.data.content[i].contentDetails[k], 'contval', cont+=1)
								}	
                        }
						window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
					});

			},
			traceGetQuestion: function() {
				
				this.$post(this.projectName + 'question/getQuestion', qs.stringify({
						stage: sessionStorage.stage,
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						traceReportId: this.$route.query.traceReportId,
						year: this.$route.query.year
					}))
					.then((res) => {
						console.log(res.data)
						this.questionBank = res.data.content.question;
						this.status = res.data.content.status;
						if(this.status==1){ //禁止上传模板
							document.querySelector('.uploading').setAttribute("class", "forbidden");
						}
						// this.$set(this.questionBank,this.questionBank,res.data.content.question)
						for (var i = 0; i < this.questionBank.length; i++) {
							this.total += this.questionBank[i].contentDetails.length;
						}

						// 循环条数
						var cont = 0
						for (var i = 0; i < res.data.content.question.length; i++) {
								for (var k = 0; k < res.data.content.question[i].contentDetails.length; k++) {
										this.$set(res.data.content.question[i].contentDetails[k], 'contval', cont+=1)
								}	
                        }
						window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
					});
			},
			indicator: function(i, contentType) {
				this.current = i;
				// $('html,body').animate({ scrollTop: this.scrollTop }, 400);
			},
			selectTopic: function(i, t, a, item, e) {
				this.pageModify('true');
				e.currentTarget.parentElement.parentElement.previousElementSibling.className =
					"position-tit-container";
				var selectTopic1 = this.questionBank[i].contentDetails[t].options
				for (var j = 0; j < selectTopic1.length; j++) {
					if (j == a) {
						this.$set(this.questionBank[i].contentDetails[t].options[j], 'chooseFlag', 1)
					} else {
						this.$set(this.questionBank[i].contentDetails[t].options[j], 'chooseFlag', 3)
					}
				}

				// 缓存 ======================
				this.$nextTick(() => {
					var positionJson = ''; // 答案的json
					$(".options-container li>input[type='radio']:checked:not('.table_radio')").each(function() {
							positionJson = positionJson + "{'optionId':'" + $(this).attr("id") + "'," +
							"'questionId':'" + $(this).attr("name") + "'," + "'contentType':'" + $(this)
							.attr(
								"data-type") + "'},";
					});
					positionJson = "[" + positionJson.substring(0, positionJson.length - 1) + "]";
					//调用缓存共用方法
					// console.log(this.cacheOrNot)
					if(this.$route.query.type == 7 || this.$route.query.type == 2){
						if(this.cacheOrNot == '0'||this.isCache == '0'){
							this.method.caching("softJArray",positionJson,this.$route.query.type,this.$route.query.stage,this)
						}
					}
				})
				// 缓存 =======================
			},
			handleScroll: function() {
				var scrollTop = document.documentElement.scrollTop;
				this.scrollTop = scrollTop;
				// console.log(scrollTop)
				if (this.scrollTop > 700) {
					this.obj.marginTop = '-67px';
					this.obj.paddingTop = '107px';
					this.obj2.paddingTop = '96px';
				} else {
					this.obj.marginTop = '0';
					this.obj.paddingTop = '40px';
					this.obj2.paddingTop = '0';
				}
				var num = [];
				var $titles = this.$refs.softSort;
				var $guideLis = this.$refs.guideLis;
				for (var i = 0; i < $titles.length; i++) {
					var $currEle = $titles[i];
					if (scrollTop >= $currEle.offsetTop && scrollTop <= $currEle.offsetTop + $currEle.offsetHeight) {
						num.push(i);
					}
				}
				if (num.length > 0) {
					this.current = num[num.length - 1];
				}


			},
			lastStep: function() {
				if (this.$route.query.reportId) {
					if (this.$route.query.type == 5) {
						this.$router.push({
							path: '/basicInformation',
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId,
								traceReportId: this.$route.query.traceReportId,
								year: this.$route.query.year
							}
						})
					} else {
						this.$router.push({
							path: '/basicInformation',
							query: {
								stage: this.$route.query.stage,
								type: this.$route.query.type,
								reportId: this.$route.query.reportId
							}
						})
					}
				} else {
					this.$router.push({
						path: '/basicInformation',
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type
						}
					})
				}
			},
			saveButton: function() {
				if (this.status == 1) {
					this.$router.push({
						path: '/balanceSheet',
						query: {
							stage: this.$route.query.stage,
							type: this.$route.query.type,
							reportId: this.$route.query.reportId,
							traceReportId: this.$route.query.traceReportId,
							year: this.$route.query.year
						}
					})
				} else {
					if (this.selected < this.total) {
						this.myAlert = true;
					} else {
						var positionJson = ''; // 答案的json
						var params = {};
						var url = '';

						$(".options-container li>input[type='radio']:checked:not('.table_radio')").each(function() {
							positionJson = positionJson + "{'optionId':'" + $(this).attr("id") + "'," +
								"'questionId':'" + $(this).attr("name") + "'," + "'contentType':'" + $(this)
								.attr(
									"data-type") + "'},";
						});
						positionJson = "[" + positionJson.substring(0, positionJson.length - 1) + "]";

						params = {
							stage: sessionStorage.stage,
							softJArray: positionJson,
							reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						}
						if (sessionStorage.reportType == 5) {
							params.traceReportId = this.$route.query.traceReportId;
							params.year = this.$route.query.year;
							url = this.projectName + 'question/saveSoftIndex';
						} else {
							params.reportType = sessionStorage.reportType;
							params.version = sessionStorage.versions;
							if (this.$route.query.type == 7 && sessionStorage.versions == 3) {
								url = this.projectName + 'question/saveDiagnosticSoftIndex3';
							} else {
								url = this.projectName + 'question/saveSoftIndex';
							}
						}

						if (!this.isClick) {
							this.isClick = true; //已保存信息
							this.$post(url, qs.stringify(params))
								.then((res) => {
									if (this.$route.query.type == 5) {
										this.$router.push({
											path: '/balanceSheet',
											query: {
												stage: this.$route.query.stage,
												type: this.$route.query.type,
												reportId: this.$route.query.reportId,
												traceReportId: this.$route.query.traceReportId,
												year: this.$route.query.year
											}
										})
									} else {
										this.$router.push({
											path: '/balanceSheet',
											query: {
												stage: this.$route.query.stage,
												type: this.$route.query.type,
												reportId: res.data.content
											}
										})
									}

								});
						}
					}
				}

			},
			submitDialog: function() {
				this.myAlert = false;
				console.log(33)
				$(".row").each(function() {
					if ($(this).find(".options-container li>:radio:checked").length <= 0) {
						console.log($(this))
						var radioId = $(this).find(".options-container li>:radio:first").attr("id");
						document.documentElement.scrollTop = $(this).offset().top - "200";
						$("#" + radioId).parents(".options-container").siblings(".position-tit-container")
							.addClass("no-choose-red");
						return false;
					}

				})
			},


			//是  载入缓存
			clickCacheTrueMaskJump(){
				sessionStorage.isHistoryCache = 1;
				this.cacheAlert = false;
				this.getQuestion();
			},

			//否  载入缓存
			clickCacheMaskJump(){
				sessionStorage.isHistoryCache = 1;
				this.cacheAlert = false;
				this.method.clearCaching(this.$route.query.type,this.$route.query.stage,this,'getQuestion')
			},


		}


	}
</script>

<style scoped>
	#main{
		padding-bottom: 20px;
	}
</style>

<template>
	<div>
		<div class="tools">
			<button @click="pdf()">导出PDF</button>
		</div>
		<div class="" id="pageContent">
			<!-- 封面 -->
			<div class="homePage">
				<div class="content" id="homePage">
					<div class="logoBox">
						<h1 id="logo"></h1>
						<div class="stock-logo hide">
							<img src="" alt="股交所logo-心流" />
						</div>
					</div>
					<p class="companyName" v-text="pageInfo.baseInfo.companyName"></p>
					<div class="homePageLine"></div>
					<h2>价值诊断报告</h2>
					<img src="../../assets/img/specialization/artFont.png" alt="" class="artFont">
					<h3 class="reportId" v-text="pageInfo.reportNumber"></h3>
					<h4>（专精特新版）</h4>
					<i>北京心流慧估科技有限公司保留最终解释权</i>
				</div>
			</div>
			<!-- 目录 -->
			<div class="cataloguePage">
				<div class="content" id="cataloguePage">
					<h2>目录</h2>
					<div class="generalize">
						<div>
							<p>1</p>
							<div></div>
							<p>综合得分</p>
							<span class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive">01</span>
						</div>
						<ul>
							<li>
								<p>综合得分</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">01</span>
							</li>
							<li>
								<p>五大得分指标</p>
								<span style="width: 724px" class="dottedLine"></span>
								<span class="pageNumber">02</span>
							</li>
							<li>
								<p>相关建议</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">02</span>
							</li>
						</ul>
						<div>
							<p>2</p>
							<div></div>
							<p>基本信息</p>
							<span class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive">03</span>
						</div>
						<div>
							<p>3</p>
							<div></div>
							<p>诊断图谱</p>
							<span class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive">04</span>
						</div>
						<ul class="flowChart">
							<li>
								<p>专业化（主营业务专业专注）</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">04</span>
							</li>
							<li>
								<p>精细化（经营管理精细高效）</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">06</span>
							</li>
							<li>
								<p>特色化（产品服务独具特色）</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">07</span>
							</li>
							<li>
								<p>新颖化（创新能力成果显著）</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">08</span>
							</li>
						</ul>
						<div>
							<p>4</p>
							<div></div>
							<p>盈利预测</p>
							<span class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive">09</span>
						</div>
						<ul>
							<li>
								<p>营业收入预测</p>
								<span style="width: 724px" class="dottedLine"></span>
								<span class="pageNumber">09</span>
							</li>
							<li>
								<p>净利润预测</p>
								<span style="width: 742px" class="dottedLine"></span>
								<span class="pageNumber">09</span>
							</li>
						</ul>
						<div>
							<p>5</p>
							<div></div>
							<p>财务分析</p>
							<span style="width: 734px;" class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive">10</span>
						</div>
						<ul>
							<li>
								<p>偿债分析</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">10</span>
							</li>
							<li>
								<p>盈利分析</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">11</span>
							</li>
							<li>
								<p>成长分析</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">12</span>
							</li>
							<li>
								<p>营运分析</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">13</span>
							</li>
							<li>
								<p>杜邦分析</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">14</span>
							</li>
						</ul>
						<div>
							<p>6</p>
							<div></div>
							<p>体检结果</p>
							<span class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive">15</span>
						</div>
						<div>
							<p>7</p>
							<div></div>
							<p>附件</p>
							<span style="width: 784px;" class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive">16</span>
						</div>
						<div>
							<p>8</p>
							<div></div>
							<p>计算公式</p>
							<span class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive">20</span>
						</div>
						<div class="stockIntroduceCatalogue hide">
							<p>9</p>
							<div></div>
							<p class="stockName">股交所简介</p>
							<span style="width: 712px" class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive">21</span>
						</div>
						<div class="isAnd_catalogue">
							<p class="isAnd_">9</p>
							<div></div>
							<p>免责声明</p>
							<span class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive isAnd_">21</span>
						</div>
					</div>
					<div class="generalize">


					</div>
				</div>
			</div>
			<!-- 第一页 综合得分 -->
			<div class="synthesisScore">
				<div class="content" id="synthesisScore">
					<h3><span class="companyName" v-text="pageInfo.baseInfo.companyName"></span>健康体检综合得分</h3>
					<h4>体检结果分析以及建议</h4>
					<p>
						诊断图谱是以产品为核心，根据影响企业诊断的内外因素形成的企业评价体系，具体包括专业化（主营业务专业专注）、精细化（经营管理精细高效）、特色化（产品服务独具特色）、新颖化（创新能力成果显著）和财务五大指标。分别对应不同的二级指标，三级指标。根据企业所填数据，系统自动进行分析评价，得出五项指标的分值。
					</p>
					<div class="synthesisScorePic">
						<p v-text="Number(pageInfo.questionValue).toFixed(2)">00.00</p>
					</div>
					<div class="pageNum">
						<p>///</p><span>1</span>
					</div>
				</div>
			</div>
			<!-- 第二页 综合得分内容 -->
			<div class="synthesisScoreCont">
				<div class="content" id="synthesisScoreCont">
					<h3 style="margin-top: 124px;">五大指标得分</h3>
					<ul>
						<li class="synthesisScore_industry" v-for="(item,index) in pageInfo.question_" :key="index">
							<div class="scoreTop">
								<i-circle :stroke-width="6" :trail-width="6" trail-color="#CBE1F4" stroke-color="#0478fc" :size="70"
									class="circleComponent" :percent="Number(item.value)">
									▲
								</i-circle>
								<div class="scoreCont">
									<p>{{item.name}}</p>
									<span>{{formatCurrency.formateNum(item.value, 2)}}</span>
								</div>
							</div>
							<div class="scoreBottom">
								<div class="indexActive" :style="'width:' + Number(item.value) + '%'"></div>
								<div class="indexIcon0"></div>
								<div class="indexIcon1"></div>
								<div class="indexIcon2"></div>
								<div class="indexIcon3"></div>
							</div>
						</li>
					</ul>
					<h3>相关建议</h3>
					<p class="suggestion" v-text="pageInfo.baseInfo.companyName + pageInfo.suggestion"><span></span></p>
					<h3>行业研究</h3>
					<div class="industryDescLists">
						<p v-for="(item,index) in pageInfo.industryDes" :key="index + 1">{{item}}</p>
					</div>
					<div class="pageNum">
						<p>///</p><span>2</span>
					</div>
				</div>
			</div>
			<!-- 第三页 基本信息 -->
			<div class="baseInfo">
				<div class="content" id="baseInfo">
					<!-- 基本信息 -->
					<div class="basicInfo">
						<div class="basicInfoTitle">
							<img src="../../assets/img/startup/jibenxinxi.png" alt="" class="icon1">
							<h3>企业基本信息</h3>
						</div>
						<div class="basicInfoCont">
							<ul>
								<li>
									<span>企业名称：</span>
									<span class="companyName" v-text="pageInfo.baseInfo.companyName"></span>
								</li>

								<li>
									<span>企业所在地：</span>
									<span class="companyLocation" v-text="pageInfo.baseInfo.area ? pageInfo.baseInfo.area : '无数据'"></span>
								</li>
								<li>
									<span>注册资本：</span>
									<span class="registeredCapital" v-text="pageInfo.baseInfo.regCapital"></span>
								</li>
								<li>
									<span>统一社会信用代码：</span>
									<span class="socialCreditCode" v-text="pageInfo.baseInfo.creditCode"></span>
								</li>
								<li>
									<span>法定代表人：</span>
									<span class="corporateRepresentative"
										v-text="pageInfo.baseInfo.legalPeople ? pageInfo.baseInfo.legalPeople : '无数据'"></span>
								</li>
								<li>
									<span>邮箱：</span>
									<span class="Email" v-text="pageInfo.baseInfo.email"></span>
								</li>
								<li>
									<span>成立日期：</span>
									<span class="establishmentDate" v-text="dateFormate(pageInfo.baseInfo.establishDate)"></span>
								</li>
								<li>
									<span>企业价值（自评）：</span>
									<span class="companyValuation" v-text="pageInfo.baseInfo.selfValue"></span>
								</li>
								<li>
									<span>所属行业：</span>
									<span class="industry"
										v-text="pageInfo.baseInfo.industryName + '-' + pageInfo.baseInfo.industryChildName"></span>
								</li>
								<li>
									<span>诊断目的：</span>
									<span class="DiagnoseGoal"
										v-text="pageInfo.baseInfo.valuationPurpose ? pageInfo.baseInfo.valuationPurpose : '无数据'"></span>
								</li>
							</ul>
						</div>
					</div>
					<!-- 主营业务 -->
					<div class="mainBusiness">
						<div class="mainBusinessTitle">
							<img src="../../assets/img/startup/zhuyingyewu.png" alt="" class="icon2">
							<h3>主营业务</h3>
							<p class="bussinessDesc"
								v-text="pageInfo.baseInfo.bussinessDesc ? pageInfo.baseInfo.bussinessDesc : '无数据'"></p>
						</div>
						<div class="mainBusinessCont"></div>
					</div>
					<!-- 发展情况 + 企业家素质 -->
					<div class="productAndGroup">
						<!-- 发展情况 -->
						<div class="product">
							<div class="productTitle">
								<img src="../../assets/img/startup/chanpin.png" alt="" class="icon3">
								<h3>发展情况</h3>
							</div>
							<div class="productCont">
								<ul>
									<li>
										<span>所处周期：</span>
										<span class="productStage">成长期</span>
									</li>
									<li>
										<span>产品推广：</span>
										<span class="productType"
											v-text="pageInfo.baseInfo.extendMarketFlag == 1 ? '已推广到市场' : '未推广到市场'"></span>
									</li>
									<li>
										<span>盈亏情况：</span>
										<span class="clientFeelback" v-text="pageInfo.profitLoss ? pageInfo.profitLoss : '无数据'"></span>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<!-- 拟融资 -->
					<div class="financingSituation">
						<div class="situationTitle">
							<img src="../../assets/img/startup/rongzi.png" alt="" class="icon5">
							<h3>拟融资</h3>
						</div>
						<div class="situationCont">
							<ul>
								<li>
									<span>拟融资额度：</span>
									<span class="financingLimit"
										v-text="pageInfo.baseInfo.finance ? formatCurrency.formateNum(pageInfo.baseInfo.finance, 2) : '无数据'"></span>
								</li>
								<li>
									<span>计划出让股权：</span>
									<span class="concessionStock"
										v-text="pageInfo.baseInfo.ratio ? pageInfo.baseInfo.ratio.toFixed(2) + '%' : '无数据'"></span>
								</li>
							</ul>
						</div>
					</div>
					<!-- 股权结构 -->
					<div class="stockStructure">
						<div class="structureTitle">
							<img src="../../assets/img/startup/guquan.png" alt="" class="icon6">
							<h3>股权结构</h3>
						</div>
						<DrawPieCanvas :id="'stockStructurePie'" v-if="pageInfo.baseInfo.companyName"
							:chartData="pageInfo.baseInfo.holderRation" />
					</div>
					<div class="pageNum">
						<p>///</p><span>3</span>
					</div>
				</div>
			</div>
			<!-- 第四页 诊断图谱 + 行业得分图 -->
			<div class="flowAtlas">
				<div class="content" id="flowAtlas">
					<div style="height:450px;margin-top: 115px;margin-left: 110px;">
						<!-- 诊断图谱 -->
						<div class="flowDiagnose">
							<div class="flowDiagnoseTitle">
								<!-- <img src="../../assets/img/startup/xinliutupu.svg" alt="" class="icon1"> -->
								<img src="../../assets/img/guzhi/xinliuPic.png" alt="" class="icon1">
								<h3>诊断图谱</h3>
							</div>
							<DrawRadarCanvas2v :chartData="pageInfo.question" :id="'flowDiagnose_BaseInfo'"
								v-if="pageInfo.baseInfo.companyName" />
						</div>
						<!-- 财务分析 -->
						<div class="financeAnalyze">
							<div class="financeAnalyzeTitle">
								<img src="../../assets/img/guzhi/caiwufenxi.png" alt="" class="icon7">
								<h3>财务分析</h3>
							</div>
							<DrawRadarCanvas2v :chartData="pageInfo.financial" :id="'financeAnalyze_BaseInfo'"
								v-if="pageInfo.baseInfo.companyName" />
						</div>
					</div>
					<div class="industryScore">
						<div class="industryTitle" style="margin-left: 0;">
							<img src="../../assets/img/startup/hangyedefen.png" alt="" class="icon2">
							<h3>专业化（主营业务专业专注）<span v-text="scoreFormate('专业化（主营业务专业专注）')">0.00</span>分</h3>
						</div>
						<DrawBarCanvas :id="'industryScorePic'" v-if="pageInfo.baseInfo.companyName" :chartData="pageInfo.industry"
							:unit="''" />
						<div class="industryCont">
							<ul>
								<li v-for="(item, index) in industryStatements1" :key="index + 2">
									<h4>{{item.name}}</h4>
									<div v-for="(itemChild, indexChild) in item.description" :key="indexChild + 3">
										<p v-if="itemChild">{{itemChild}}</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="pageNum">
						<p>///</p><span>4</span>
					</div>
				</div>
			</div>
			<!-- 第五页 行业建议 + 行业散点图 -->
			<div class="industrySuggest">
				<div class="content" id="industrySuggest">
					<div class="industryCont" style='margin-top:115px;margin-left: 110px;'>
						<ul>
							<li v-for="(item, index) in industryStatements2" :key="index + 4">
								<h4>{{item.name}}</h4>
								<div v-for="(itemChild, indexChild) in item.description" :key="indexChild + 5">
									<p v-if="itemChild">{{itemChild}}</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="pageNum">
						<p>///</p><span>5</span>
					</div>
				</div>
			</div>
			<!-- 第六页 精细化得分图 -->
			<div class="teamScore">
				<div class="content" id="teamScore">
					<div class="teamTitle">
						<img src="../../assets/img/startup/tuanduidefen.png" alt="" class="icon">
						<h3>精细化（经营管理精细高效）<span v-text="scoreFormate('精细化（经营管理精细高效）')">0.00</span>分</h3>
					</div>
					<DrawBarCanvas :id="'teamScorePic'" v-if="pageInfo.baseInfo.companyName"
						:chartData="pageInfo.competitivePower" :unit="''" />
					<div class="teamCont">
						<ul>
							<li v-for="(item, index) in competitivePowerStatements" :key="index + 6">
								<h4>{{item.name}}</h4>
								<div v-for="(itemChild, indexChild) in item.description" :key="indexChild + 7">
									<p v-if="itemChild">{{itemChild}}</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="pageNum">
						<p>///</p><span>6</span>
					</div>
				</div>
			</div>
			<!-- 第七页 技术得分图 -->
			<div class="skillScore">
				<div class="content" id="skillScore">
					<div class="skillTitle">
						<img src="../../assets/img/startup/jishudefen.png" alt="" class="icon">
						<h3>特色化（产品服务独具特色） <span v-text="scoreFormate('特色化（产品服务独具特色）')">0.00</span>分</h3>
					</div>
					<DrawBarCanvas :id="'skillScorePic'" v-if="pageInfo.baseInfo.companyName" :chartData="pageInfo.teamManage"
						:unit="''" />
					<div class="skillCont">
						<ul>
							<li v-for="(item, index) in teamManageStatements" :key="index + 8">
								<h4>{{item.name}}</h4>
								<div v-for="(itemChild, indexChild) in item.description" :key="indexChild + 9">
									<p v-if="itemChild">{{itemChild}}</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="pageNum">
						<p>///</p><span>7</span>
					</div>
				</div>
			</div>
			<!-- 第八页 产品得分图 -->
			<div class="productScore">
				<div class="content" id="productScore">
					<div class="productTitle">
						<img src="../../assets/img/guzhi/gengduochanpin.png" alt="" class="icon">
						<h3>新颖化（创新能力成果显著）<span v-text="scoreFormate('新颖化（创新能力成果显著）')">0.00</span>分</h3>
					</div>
					<DrawBarCanvas :id="'productScorePic'" v-if="pageInfo.baseInfo.companyName" :chartData="pageInfo.technology"
						:unit="''" />
					<div class="product_Cont">
						<ul>
							<li v-for="(item, index) in technologyStatements" :key="index + 10">
								<h4>{{item.name}}</h4>
								<div v-for="(itemChild, indexChild) in item.description" :key="indexChild + 11">
									<p v-if="itemChild">{{itemChild}}</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="pageNum">
						<p>///</p><span>8</span>
					</div>
				</div>
			</div>
			<!-- 第十一页 盈利预测 -->
			<div class="earningsForecast">
				<div class="content" id="earningsForecast">
					<div class="expenditureForecast">
						<div class="expenditureTitle">
							<img src="../../assets/img/startup/shouru.png" alt="" class="icon">
							<h3>营业收入预测</h3>
						</div>
						<div class="expenditureCont">
							<DrawBarCanvas :id="'expenditurePic'" v-if="pageInfo.baseInfo.companyName"
								:chartData="pageInfo.incomeForecast" :unit="'（万元）'" />
						</div>
					</div>
					<div class="incomeForecast">
						<div class="incomeTitle">
							<img src="../../assets/img/guzhi/jinglirun.png" alt="" class="icon">
							<h3>净利润预测</h3>
						</div>
						<div class="incomeCont">
							<DrawBarCanvas :id="'incomePic'" v-if="pageInfo.baseInfo.companyName"
								:chartData="pageInfo.netProfitForecast" :unit="'（万元）'" />
							<p>说明：根据企业提供的历史财务数据，结合国际通用的财务预测方法及规则，对企业未来五年营业收入、净利润进行预测。</p>
						</div>
					</div>
					<div class="pageNum">
						<p>///</p><span>9</span>
					</div>
				</div>
			</div>
			<!-- 第十二页 偿债分析 -->
			<div class="debtPayAnalyze">
				<div class="content" id="debtPayAnalyze">
					<div class="debtPayTitle">
						<img src="../../assets/img/startup/zhichu.png" alt="" class="icon">
						<h3>偿债分析</h3>
					</div>
					<!--偿债能力表格和文字说明部分-->
					<div class="table_explain sovency asset-liability-ratio">
						<div class="left sovency_table">
							<!--资产负债率-->
							<DrawBarFinancialAnalysis :id="'liabilitiesRatio'" v-if="pageInfo.baseInfo.companyName"
								:chartData="pageInfo.liabilitiesRatio" style="width:500px;height:450px;" />
						</div>
						<div class="right sovency_explain">
							<div class="grade">
								<img class="battery" :src="liabilitiesRatioStatementSrc" alt="诊断报告中的偿债分析之资产负债表-心流" />
								<!-- <ul>
                                </ul> -->
								<p v-if="pageInfo.baseInfo.companyName" v-text="pageInfo.liabilitiesRatio.batteryLevel"
									:style="pageInfo.liabilitiesRatio.batteryLevel == '20%'?'color:#FF383D;':''"></p>
							</div>
							<h4 v-text="liabilitiesRatioStatement"></h4>
							<div v-for="(item,index) in liabilitiesRatioStatementArr" :key="index + 10">
								<p>{{item}}</p>
							</div>
						</div>
					</div>
					<div class="table_explain sovency liquidity-ratio">
						<div class="left sovency_table">
							<!--流动比率-->
							<DrawBarFinancialAnalysis :id="'flowRation'" v-if="pageInfo.baseInfo.companyName"
								:chartData="pageInfo.flowRation" style="width:500px;height:450px;" />
						</div>
						<div class="right sovency_explain">
							<div class="grade">
								<img class="battery" :src="flowRationStatementSrc" alt="诊断报告中的偿债分析之流动比率-心流" />
								<!-- <ul></ul> -->
								<p v-if="pageInfo.baseInfo.companyName" v-text="pageInfo.flowRation.batteryLevel"
									:style="pageInfo.flowRation.batteryLevel=='20%'?'color:#FF383D;':''"></p>
							</div>
							<h4 v-text="flowRationStatement"></h4>
							<div v-for="(item,index) in flowRationStatementArr" :key="index + 11">
								<p>{{item}}</p>
							</div>
						</div>
					</div>
					<div class="table_explain sovency interest-cover">
						<div class="left sovency_table ebit">
							<DrawBarFinancialAnalysis :id="'ebitMultiple'" v-if="pageInfo.baseInfo.companyName"
								:chartData="pageInfo.ebitMultiple" style="width:500px;height:450px;" />
							<!-- EBIT利息保障倍数-->
						</div>
						<div class="right sovency_explain ebitr">
							<div class="grade">
								<img class="battery" :src="ebitMultipleStatementSrc" alt="诊断报告中的偿债分析之EBIT利息保障倍数-心流" />
								<!-- <ul></ul> -->
								<p v-if="pageInfo.baseInfo.companyName" v-text="pageInfo.ebitMultiple.batteryLevel"
									:style="pageInfo.ebitMultiple.batteryLevel=='20%'?'color:#FF383D;':''"></p>
							</div>
							<h4 v-text="ebitMultipleStatement"></h4>
							<div v-for="(item,index) in ebitMultipleStatementArr" :key="index + 12">
								<p>{{item}}</p>
							</div>
						</div>
					</div>
					<div class="pageNum">
						<p>///</p><span>10</span>
					</div>
				</div>
			</div>
			<!-- 第十三页 盈利分析 -->
			<div class="profitAnalyze">
				<div class="content" id="profitAnalyze">
					<div class="profitTitle">
						<img src="../../assets/img/guzhi/yinglifenxi.png" alt="" class="icon">
						<h3>盈利分析</h3>
					</div>
					<div class="table_explain profitability gross-profit-ratio">
						<div class="left profitability_table">
							<DrawBarFinancialAnalysis :id="'grossProfitRatio'" v-if="pageInfo.baseInfo.companyName"
								:chartData="pageInfo.grossProfitRatio" style="width:500px;height:450px;" />
							<!--毛利率-->
						</div>
						<div class="right profitability_explain">
							<div class="grade">
								<img class="battery" :src="grossProfitRatioStatementSrc" alt="诊断报告中的盈利分析之毛利率-心流" />
								<!-- <ul></ul> -->
								<p v-if="pageInfo.baseInfo.companyName" v-text="pageInfo.grossProfitRatio.batteryLevel"
									:style="pageInfo.grossProfitRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
							</div>
							<h4 v-text="grossProfitRatioStatement"></h4>
							<div v-for="(item,index) in grossProfitRatioStatementArr" :key="index + 12">
								<p>{{item}}</p>
							</div>
						</div>
					</div>
					<div class="table_explain profitability net-profit-rate">
						<div class="left profitability_table">
							<!--净利润率-->
							<DrawBarFinancialAnalysis :id="'netProfiltRatio'" v-if="pageInfo.baseInfo.companyName"
								:chartData="pageInfo.netProfiltRatio" style="width:500px;height:450px;" />
						</div>
						<div class="right profitability_explain">
							<div class="grade">
								<img class="battery" :src="netProfiltRatioStatementSrc" alt="诊断报告中的盈利分析之净利润率-心流" />
								<!-- <ul></ul> -->
								<p v-if="pageInfo.baseInfo.companyName" v-text="pageInfo.netProfiltRatio.batteryLevel"
									:style="pageInfo.netProfiltRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
							</div>
							<h4 v-text="netProfiltRatioStatement"></h4>
							<div v-for="(item,index) in netProfiltRatioStatementArr" :key="index + 12">
								<p>{{item}}</p>
							</div>
						</div>
					</div>
					<div class="pageNum">
						<p>///</p><span>11</span>
					</div>
				</div>
			</div>
			<!-- 第十四页 成长分析 -->
			<div class="growthAnalyze">
				<div class="content" id="growthAnalyze">
					<div class="growthTitle">
						<img src="../../assets/img/guzhi/chengzhangfenxi.png" alt="" class="icon">
						<h3>成长分析</h3>
					</div>
					<div class="table_explain growUp main-business">
						<div class="left growUp_table">
							<!--主营业务收入增长率-->
							<DrawBarFinancialAnalysis :id="'mainIncomeRatio'" v-if="pageInfo.baseInfo.companyName"
								:chartData="pageInfo.mainIncomeRatio" style="width:500px;height:450px;" />
						</div>
						<div class="right growUp_explain">
							<div class="grade">
								<img class="battery" :src="mainIncomeRatioStatementSrc" alt="诊断报告中的成长分析之主营业务收入增长率-心流" />
								<!-- <ul></ul> -->
								<p v-if="pageInfo.baseInfo.companyName" v-text="pageInfo.mainIncomeRatio.batteryLevel"
									:style="pageInfo.mainIncomeRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
							</div>
							<h4 v-text="mainIncomeRatioStatement"></h4>
							<div v-for="(item,index) in mainIncomeRatioStatementArr" :key="index + 12">
								<p>{{item}}</p>
							</div>
						</div>
					</div>
					<div class="table_explain growUp  net-asset-ratio">
						<div class="left growUp_table">
							<!--净资产收益率-->
							<DrawBarFinancialAnalysis :id="'netAssetRatio'" v-if="pageInfo.baseInfo.companyName"
								:chartData="pageInfo.netAssetRatio" style="width:500px;height:450px;" />
						</div>
						<div class="right growUp_explain">
							<div class="grade">
								<img class="battery" :src="netAssetRatioStatementSrc" alt="诊断报告中的成长分析之净资产收益率-心流" />
								<!-- <ul></ul> -->
								<p v-if="pageInfo.baseInfo.companyName" v-text="pageInfo.netAssetRatio.batteryLevel"
									:style="pageInfo.netAssetRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
							</div>
							<h4 v-text="netAssetRatioStatement"></h4>
							<div v-for="(item,index) in netAssetRatioStatementArr" :key="index + 12">
								<p>{{item}}</p>
							</div>
						</div>
					</div>
					<div class="pageNum">
						<p>///</p><span>12</span>
					</div>
				</div>
			</div>
			<!-- 第十五页 营运分析 -->
			<div class="operateAnalyze">
				<div class="content" id="operateAnalyze">
					<div class="operateTitle">
						<img src="../../assets/img/guzhi/yunyingfenxi.png" alt="" class="icon">
						<h3>营运分析</h3>
					</div>
					<!--运营能力表格和文字说明部分-->
					<div class="table_explain operational account-turnover-rate">
						<div class="left operational_table">
							<!--应收账款周转率-->
							<DrawBarFinancialAnalysis :id="'receivablesTurnoverRatio'" v-if="pageInfo.baseInfo.companyName"
								:chartData="pageInfo.receivablesTurnoverRatio" style="width:500px;height:450px;" />
						</div>
						<div class="right operational_explain">
							<div class="grade">
								<img class="battery" :src="receivablesTurnoverRatioStatementSrc" alt="诊断报告中的营运分析之应收账款周转率-心流" />
								<!-- <ul></ul> -->
								<p v-if="pageInfo.baseInfo.companyName" v-text="pageInfo.receivablesTurnoverRatio.batteryLevel"
									:style="pageInfo.receivablesTurnoverRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
							</div>
							<h4 v-text="receivablesTurnoverRatioStatement"></h4>
							<div v-for="(item,index) in receivablesTurnoverRatioStatementArr" :key="index + 12">
								<p>{{item}}</p>
							</div>
						</div>
					</div>
					<div class="table_explain operational  inventory-turnover-ratio">
						<div class="left operational_table">
							<!--存货周转率-->
							<DrawBarFinancialAnalysis :id="'inventoryTurnoverRatio'" v-if="pageInfo.baseInfo.companyName"
								:chartData="pageInfo.inventoryTurnoverRatio" style="width:500px;height:450px;" />
						</div>
						<div class="right operational_explain">
							<div class="grade">
								<img class="battery" :src="inventoryTurnoverRatioStatementSrc" alt="诊断报告中的营运分析之存货周转率-心流" />
								<!-- <ul></ul> -->
								<p v-if="pageInfo.baseInfo.companyName" v-text="pageInfo.inventoryTurnoverRatio.batteryLevel"
									:style="pageInfo.inventoryTurnoverRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
							</div>
							<h4 v-text="inventoryTurnoverRatioStatement"></h4>
							<div v-for="(item,index) in inventoryTurnoverRatioStatementArr" :key="index + 12">
								<p>{{item}}</p>
							</div>
						</div>
					</div>
					<div class="pageNum">
						<p>///</p><span>13</span>
					</div>
				</div>
			</div>
			<!-- 第十六页 杜邦分析 -->
			<div class="dupontAnalyze">
				<div class="content" id="dupontAnalyze">
					<DupontAnalyze :dupontData="pageInfo.dubangAnalysis" v-if="pageInfo.baseInfo.companyName" :version="0" />
					<div class="pageNum">
						<p>///</p><span>14</span>
					</div>
				</div>
			</div>
			<!-- 第十七页 财务状况 -->
			<div class="financialCondition">
				<div class="content" id="financialCondition">
					<div class="financialConditionTitle">
						<img src="../../assets/img/guzhi/caiwuzhuangkuang.png" alt="" class="icon">
						<h3>财务状况体检报告</h3>
					</div>
					<!--圆环内容-->
					<ul class="imgUl">
						<li v-for="(item,index) in pageInfo.financial" :key="index + 12">
							<div class="liDivImg" :style="'border-color:' + item.color">
								<i-circle :style="'font-weight:bold;color:' + item.color" :stroke-width="10" :trail-width="10"
									trail-color="#fff" :stroke-color="item.color" :size="150" class="circleComponent"
									:percent="Number(item.value)">
									{{item.value}}%
								</i-circle>
							</div>
							<h3 id="indicatorContainer1_title" :style="'color:' + item.color">{{item.name}}</h3>
							<p id="indicatorContainer1_content">{{item.description}}</p>
						</li>
						<div class="clear"></div>
					</ul>
					<div class="pageNum">
						<p>///</p><span>15</span>
					</div>
				</div>
			</div>
			<!-- 第十八页 附件1 -->
			<div class="accessory_1">
				<div class="content" id="accessory_1">
					<LibTableComponent1 :liaTableInfo="pageInfo.liaTableInfo" v-if="pageInfo.baseInfo.companyName"
						:year="pageInfo.year" :versions="0" />
					<div class="pageNum">
						<p>///</p><span>16</span>
					</div>
				</div>
			</div>
			<!-- 第十九页 附件1 -->
			<div class="accessory_2">
				<div class="content" id="accessory_2">
					<LibTableComponent2 :liaTableInfo="pageInfo.liaTableInfo" v-if="pageInfo.baseInfo.companyName"
						:year="pageInfo.year" :versions="0" />
					<div class="pageNum">
						<p>///</p><span>17</span>
					</div>
				</div>
			</div>
			<!-- 第二十页 附件2 -->
			<div class="accessory_3">
				<div class="content" id="accessory_3">
					<ProfitTableComponent1 :profitTableInfo="pageInfo.profitTableInfo" v-if="pageInfo.baseInfo.companyName"
						:year="pageInfo.year" :versions="0" />
					<div class="pageNum">
						<p>///</p><span>18</span>
					</div>
				</div>
			</div>
			<!-- 第二十一页 附件2 + 财务比率表 -->
			<div class="accessory_4">
				<div class="content" id="accessory_4">
					<ProfitTableComponent2 :profitTableInfo="pageInfo.profitTableInfo" v-if="pageInfo.baseInfo.companyName"
						:year="pageInfo.year" :financeRatio="pageInfo.financeRatio" :versions="0" />
					<div class="pageNum">
						<p>///</p><span>19</span>
					</div>
				</div>
			</div>
			<!-- 第二十二页 计算公式 -->
			<div class="computational">
				<div class="content" id="computational">
					<span>附件3</span>
					<div class="title">计算公式</div>
					<div class="formula-container">
						<h3>1、财务指标公式</h3>
						<ul>
							<li>
								<!-- <img src="../../assets/img/report/enclosure-01@2x.png" alt="资产负债率=负债合计/资产总计×100%-心流" /> -->
								<p class="dian">◆</p>
								<p>资产负债率=负债合计/资产总计×100%</p>
							</li>
							<li>
								<!-- <img src="../../assets/img/report/enclosure-01@2x.png" alt="流动比率=流动资产合计/流动负债合计-心流" /> -->
								<p class="dian">◆</p>
								<p>流动比率=流动资产合计/流动负债合计</p>
							</li>
							<li>
								<!-- <img src="../../assets/img/report/enclosure-01@2x.png" alt="EBIT利息保障倍数=EBIT/利息费用-心流" /> -->
								<p class="dian">◆</p>
								<p>EBIT利息保障倍数=EBIT/利息费用</p>
							</li>
							<li>
								<!-- <img src="../../assets/img/report/enclosure-01@2x.png" alt="毛利率=（营业收入-营业成本）/营业收入×100%-心流" /> -->
								<p class="dian">◆</p>
								<p>毛利率=（营业收入-营业成本）/营业收入×100%</p>
							</li>
							<li>
								<!-- <img src="../../assets/img/report/enclosure-01@2x.png" alt="净利润率=净利润/营业收入×100%-心流" /> -->
								<p class="dian">◆</p>
								<p>净利润率=净利润/营业收入×100%</p>
							</li>
							<li>
								<!-- <img src="../../assets/img/report/enclosure-01@2x.png" alt="主营业务收入增长率=（本年度营业收入-上年度营业收入）/上年度营业收入×100%-心流" /> -->
								<p class="dian">◆</p>
								<p>主营业务收入增长率=（本年度营业收入-上年度营业收入）/上年度营业收入×100%</p>
							</li>
							<li>
								<!-- <img src="../../assets/img/report/enclosure-01@2x.png" alt="净资产收益率=净利润×2/（本年度期初净资产+本年度期末净资产）×100%-心流" /> -->
								<p class="dian">◆</p>
								<p>净资产收益率=净利润×2/（本年度期初净资产+本年度期末净资产）×100%</p>
							</li>
							<li>
								<!-- <img src="../../assets/img/report/enclosure-01@2x.png" alt="应收账款周转率=营业收入/平均应收账款-心流" /> -->
								<p class="dian">◆</p>
								<p>应收账款周转率=营业收入/平均应收账款</p>
							</li>
							<li>
								<!-- <img src="../../assets/img/report/enclosure-01@2x.png" alt="存货周转率=营业成本/平均存货-心流" /> -->
								<p class="dian">◆</p>
								<p>存货周转率=营业成本/平均存货</p>
							</li>
						</ul>
					</div>
					<div class="pageNum">
						<p>///</p><span>20</span>
					</div>
				</div>
			</div>
			<!-- 第二十三页 免责声明 -->
			<div class="disclaimer">
				<div class="content" id="disclaimer">
					<div class="page-content page-last">

						<img src="../../assets/img/report/map@2x.png" alt="线上估值_初创期-心流" />

						<div class="flow-statement">
							<img src="../../assets/img/logo-08.svg" alt="线上估值_初创期-心流" />
							<ul>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<p class="dian">◆</p>
										<!-- <img src="../../assets/img/report/statement-01@2x.png" alt="线上估值_初创期-心流" /> -->
										<p>诊断声明</p>
										<!-- <img src="../../assets/img/report/statement-01@2x.png" alt="线上估值_初创期-心流" /> -->
										<p class="dian dian_">◆</p>
										<span class="xian"></span>
									</div>
									<div class="statement-main">
										本报告由系统所自动生成。该系统依据企业提供的财务数据和软性指标，结合具备可靠性水平的数
										据库，根据通用的行业业绩及财务预测逻辑和规则，对企业诊断所需要的必要参数进行预测和分析。企业提供资料的完整度和准确性对最终 诊断结果有重要影响。请本报告使用者知悉。
									</div>
								</li>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<p class="dian">◆</p>
										<!-- <img src="../../assets/img/report/statement-01@2x.png" alt="线上估值_初创期-心流" /> -->
										<p>免责声明</p>
										<!-- <img src="../../assets/img/report/statement-01@2x.png" alt="线上估值_初创期-心流" /> -->
										<p class="dian dian_">◆</p>
										<span class="xian"></span>
									</div>
									<div class="statement-main">
										系统所采用的数据信息来源于企业自行提供的资料和公开渠道可获取的行业信息和数据，并基于前述资料属实、
										合法的假设出具本报告，网站亦不对前述资料的准确性、完整性负有实质审核的义务，本报告仅作为对企业整体价值衡量的参考，非并
										购、转让等交易的直接证据，网站及其运营方不承担因信赖本报告信息而采取行动所导致的任何损失及一切法律责任。
									</div>
								</li>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<p class="dian">◆</p>
										<!-- <img src="../../assets/img/report/statement-01@2x.png" alt="线上估值_初创期-心流" /> -->
										<p>心流慧估</p>
										<!-- <img src="../../assets/img/report/statement-01@2x.png" alt="线上估值_初创期-心流" /> -->
										<p class="dian dian_">◆</p>
										<span class="xian"></span>
									</div>
									<div class="statement-main lastStatement-main">
										<p>
											北京心流慧估科技有限公司是国内外领先的投行AIGC公司，专注于用AI技术为中国众多中小企业提供“估值+组合式金融服务”，通过自主研发的曼殊AI（Manju
											AI）系列软件产品，从发现、培育优质股权，再到资本对接，引金融之水精确“滴灌”，低成本、高效率地为中小企业走进资本市场提供定制化解决方案
										</p>
										<p>
											心流慧估运用人工智能技术和多种统计分析方法，将国际通用估值技术结合心流慧估独创软性指标体系，搭建出完善的股权估值模型和算法模型。采用AIGC技术将估值模型、对标企业、指标权重、语句分析自动匹配并实时在线生成完整专业报告，不仅能科学、客观、公允地评估企业价值，还能帮助企业更好地进行融资规划、财务系统建设、企业管理规范，提高企业决策和融资效率。
										</p>
										<div>
											<img src="../../assets/img/wechartCode.jpg" alt="">
											<ul>
												<li><span>网址</span>：www.flcccc.com</li>
												<li><span>客服电话</span>：17610076778 &nbsp; 010-8444-6599</li>
												<li><span>微信号</span>：flcccc888</li>
												<li><span>地址</span>：北京市朝阳区亮马桥路甲40号二十一世纪大厦B座3层303-305</li>
											</ul>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="pageNum">
						<p>///</p><span class="isAnd_">21</span>
					</div>
				</div>
			</div>
		</div>
		<div id="loading" style="display: none;">
			<div class='base'>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
			</div>
		</div>
	</div>
</template>
<script>
	import qs from 'qs'
	import '../../assets/css/loading.css'
	import PdfOnload from '../../utils/pdf'
	import DrawRadarCanvas2v from '../../common/echarts/drawRadarCanvas2v.vue'
	import DrawPieCanvas from '../../common/echarts/drawPieCanvas.vue'
	import DrawBarCanvas from '../../common/echarts/drawBarCanvas.vue'
	import DrawBarFinancialAnalysis from '../../common/echarts/drawBarFinancialAnalysis.vue'
	import DupontAnalyze from '../../common/reportComponent/dupontAnalyze.vue'
	import LibTableComponent1 from '../../common/reportComponent/liaTableComponent1.vue'
	import LibTableComponent2 from '../../common/reportComponent/liaTableComponent2.vue'
	import ProfitTableComponent1 from '../../common/reportComponent/profitTableComponent1.vue'
	import ProfitTableComponent2 from '../../common/reportComponent/profitTableComponent2.vue'
	export default ({
		components: {
			DrawPieCanvas,
			DrawRadarCanvas2v,
			DrawBarCanvas,
			DrawBarFinancialAnalysis,
			DupontAnalyze,
			LibTableComponent1,
			LibTableComponent2,
			ProfitTableComponent1,
			ProfitTableComponent2
		},
		name: "diagnoseReport",
		data() {
			return {
				// 4ccc2f3f2bc5439d8cb1afb8cf468ba7 
				reportId: this.$route.query.reportId ? this.$route.query.reportId : "",
				pageInfo: {
					baseInfo: {
						companyName: "",
						establishDate: ""
					}
				},
				// 专业化语句
				industryStatements1: [],
				industryStatements2: [],
				// 精细化语句
				competitivePowerStatements: [],
				// 特色化语句
				teamManageStatements: [],
				// 新颖化语句
				technologyStatements: [],
				// 资产负债率
				liabilitiesRatioStatement: "",
				liabilitiesRatioStatementArr: [],
				liabilitiesRatioStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				// 流动比率
				flowRationStatement: "",
				flowRationStatementArr: [],
				flowRationStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				// EBIT利息保障倍数
				ebitMultipleStatement: "",
				ebitMultipleStatementArr: [],
				ebitMultipleStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				// 毛利率
				grossProfitRatioStatement: "",
				grossProfitRatioStatementArr: [],
				grossProfitRatioStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				// 净利润率
				netProfiltRatioStatement: "",
				netProfiltRatioStatementArr: [],
				netProfiltRatioStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				// 主营业务收入增长率
				mainIncomeRatioStatement: "",
				mainIncomeRatioStatementArr: [],
				mainIncomeRatioStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				// 净资产收益率
				netAssetRatioStatement: "",
				netAssetRatioStatementArr: [],
				netAssetRatioStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				// 应收账款周转率
				receivablesTurnoverRatioStatement: "",
				receivablesTurnoverRatioStatementArr: [],
				receivablesTurnoverRatioStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				// 存货周转率
				inventoryTurnoverRatioStatement: "",
				inventoryTurnoverRatioStatementArr: [],
				inventoryTurnoverRatioStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				//
				pdfArr: [],
				pdfIndex: 0,
				projectName: ''
			}
		},
		mounted() {
			this.projectName = this.domain.diagnoseProject
			if (this.reportId) {
				this.initPage();
			}
		},
		methods: {
			initPage: function() {
				var that = this;
				var queryObj = {
					reportId: that.reportId
				}
				that.$post(this.projectName + "valuationReport/qryValuationReport", qs.stringify(queryObj)).then((res) => {
					var data = res.data;
					if (data.code == 200 && data.content) {
						console.log(data.content);
						that.pageInfo = data.content;
						that.pageInfo.question_ = JSON.parse(that.pageInfo.question);
						that.pageInfo.question_.push({
							name: "财务指标",
							value: that.pageInfo.financialValue
						});
						that.pageInfo.industryDes = JSON.parse(that.pageInfo.matrix).industryDesc.split("|");
						// 注册资本
						that.pageInfo.baseInfo.regCapital = that.valueFormate(that.pageInfo.baseInfo.regCapital);
						// 企业价值自评
						that.pageInfo.baseInfo.selfValue = that.valueFormate(that.pageInfo.baseInfo.selfValue);
						// 股权结构
						var holderArr = [];
						for (var i in JSON.parse(that.pageInfo.baseInfo.holderRation)) {
							holderArr.push({
								name: i,
								value: JSON.parse(that.pageInfo.baseInfo.holderRation)[i]
							})
						}
						that.pageInfo.baseInfo.holderRation = holderArr;
						that.pageInfo.question = JSON.parse(that.pageInfo.question);
						that.pageInfo.financial = JSON.parse(that.pageInfo.financial);
						// 专业化
						that.pageInfo.industry = JSON.parse(that.pageInfo.industry);
						for (var i = 0; i < 2; i++) {
							var industryDescriptionArr1 = that.pageInfo.industry[i].description.split("/n");
							var industryObj1 = {
								name: that.pageInfo.industry[i].name,
								description: industryDescriptionArr1
							};
							that.industryStatements1.push(industryObj1);
						}
						// 专业化语句-- 换页
						for (var i = 2; i < that.pageInfo.industry.length; i++) {
							var industryDescriptionArr2 = that.pageInfo.industry[i].description.split("/n");
							var industryObj2 = {
								name: that.pageInfo.industry[i].name,
								description: industryDescriptionArr2
							};
							that.industryStatements2.push(industryObj2);
						}
						// 精细化
						that.pageInfo.competitivePower = JSON.parse(that.pageInfo.competitivePower);
						for (var i = 0; i < that.pageInfo.competitivePower.length; i++) {
							var competitivePowerDescriptionArr = that.pageInfo.competitivePower[i].description.split("/n");
							var competitivePowerObj = {
								name: that.pageInfo.competitivePower[i].name,
								description: competitivePowerDescriptionArr
							};
							that.competitivePowerStatements.push(competitivePowerObj);
						}
						// 特色化
						that.pageInfo.teamManage = JSON.parse(that.pageInfo.teamManage);
						for (var i = 0; i < that.pageInfo.teamManage.length; i++) {
							var teamManageDescriptionArr = that.pageInfo.teamManage[i].description.split("/n");
							var teamManageObj = {
								name: that.pageInfo.teamManage[i].name,
								description: teamManageDescriptionArr
							};
							that.teamManageStatements.push(teamManageObj);
						}
						// 新颖化
						that.pageInfo.technology = JSON.parse(that.pageInfo.technology);
						for (var i = 0; i < that.pageInfo.technology.length; i++) {
							var technologyDescriptionArr = that.pageInfo.technology[i].description.split("/n");
							var technologyObj = {
								name: that.pageInfo.technology[i].name,
								description: technologyDescriptionArr
							};
							that.technologyStatements.push(technologyObj);
						}
						// 营业收入预测
						that.pageInfo.incomeForecast = JSON.parse(that.pageInfo.incomeForecast);
						// 净利润预测
						that.pageInfo.netProfitForecast = JSON.parse(that.pageInfo.netProfitForecast);
						// 资产负债率
						if (that.pageInfo.liabilitiesRatio) {
							that.pageInfo.liabilitiesRatio = JSON.parse(that.pageInfo.liabilitiesRatio);
							that.pageInfo.liabilitiesRatio.name.push("");
							// 语句
							var liabilitiesRatioAssetArr = that.pageInfo.liabilitiesRatio.description.split("|");
							that.liabilitiesRatioStatement = liabilitiesRatioAssetArr[0];
							that.liabilitiesRatioStatementArr = liabilitiesRatioAssetArr.splice(1);
							that.liabilitiesRatioStatementSrc = that.srcFormate(that.pageInfo.liabilitiesRatio.batteryLevel);
						}
						// 流动比率
						if (that.pageInfo.flowRation) {
							that.pageInfo.flowRation = JSON.parse(that.pageInfo.flowRation);
							that.pageInfo.flowRation.name.push("");
							// 语句
							var flowRationAssetArr = that.pageInfo.flowRation.description.split("|");
							that.flowRationStatement = flowRationAssetArr[0];
							that.flowRationStatementArr = flowRationAssetArr.splice(1);
							that.flowRationStatementSrc = that.srcFormate(that.pageInfo.flowRation.batteryLevel);
						}
						// EBIT利息保障倍数
						if (that.pageInfo.ebitMultiple) {
							that.pageInfo.ebitMultiple = JSON.parse(that.pageInfo.ebitMultiple);
							that.pageInfo.ebitMultiple.name.push("");
							// 语句
							var ebitMultipleAssetArr = that.pageInfo.ebitMultiple.description.split("|");
							that.ebitMultipleStatement = ebitMultipleAssetArr[0];
							that.ebitMultipleStatementArr = ebitMultipleAssetArr.splice(1);
							that.ebitMultipleStatementSrc = that.srcFormate(that.pageInfo.ebitMultiple.batteryLevel);
						}
						// 毛利率
						if (that.pageInfo.grossProfitRatio) {
							that.pageInfo.grossProfitRatio = JSON.parse(that.pageInfo.grossProfitRatio);
							that.pageInfo.grossProfitRatio.name.push("");
							// 语句
							var grossProfitRatioAssetArr = that.pageInfo.grossProfitRatio.description.split("|");
							that.grossProfitRatioStatement = grossProfitRatioAssetArr[0];
							that.grossProfitRatioStatementArr = grossProfitRatioAssetArr.splice(1);
							that.grossProfitRatioStatementSrc = that.srcFormate(that.pageInfo.grossProfitRatio.batteryLevel);
						}
						// 净利润率
						if (that.pageInfo.netProfiltRatio) {
							that.pageInfo.netProfiltRatio = JSON.parse(that.pageInfo.netProfiltRatio);
							that.pageInfo.netProfiltRatio.name.push("");
							// 语句
							var netProfiltRatioAssetArr = that.pageInfo.netProfiltRatio.description.split("|");
							that.netProfiltRatioStatement = netProfiltRatioAssetArr[0];
							that.netProfiltRatioStatementArr = netProfiltRatioAssetArr.splice(1);
							that.netProfiltRatioStatementSrc = that.srcFormate(that.pageInfo.netProfiltRatio.batteryLevel);
						}
						// 主营业务收入增长率
						if (that.pageInfo.mainIncomeRatio) {
							that.pageInfo.mainIncomeRatio = JSON.parse(that.pageInfo.mainIncomeRatio);
							that.pageInfo.mainIncomeRatio.name.push("");
							// 语句
							var mainIncomeRatioAssetArr = that.pageInfo.mainIncomeRatio.description.split("|");
							that.mainIncomeRatioStatement = mainIncomeRatioAssetArr[0];
							that.mainIncomeRatioStatementArr = mainIncomeRatioAssetArr.splice(1);
							that.mainIncomeRatioStatementSrc = that.srcFormate(that.pageInfo.mainIncomeRatio.batteryLevel);
						}
						// 净资产收益率
						if (that.pageInfo.netAssetRatio) {
							that.pageInfo.netAssetRatio = JSON.parse(that.pageInfo.netAssetRatio);
							that.pageInfo.netAssetRatio.name.push("");
							// 语句
							var netAssetRatioAssetArr = that.pageInfo.netAssetRatio.description.split("|");
							that.netAssetRatioStatement = netAssetRatioAssetArr[0];
							that.netAssetRatioStatementArr = netAssetRatioAssetArr.splice(1);
							that.netAssetRatioStatementSrc = that.srcFormate(that.pageInfo.netAssetRatio.batteryLevel);
						}
						// 应收账款周转率
						if (that.pageInfo.receivablesTurnoverRatio) {
							that.pageInfo.receivablesTurnoverRatio = JSON.parse(that.pageInfo.receivablesTurnoverRatio);
							that.pageInfo.receivablesTurnoverRatio.name.push("");
							// 语句
							var receivablesTurnoverRatioAssetArr = that.pageInfo.receivablesTurnoverRatio.description.split(
								"|");
							that.receivablesTurnoverRatioStatement = receivablesTurnoverRatioAssetArr[0];
							that.receivablesTurnoverRatioStatementArr = receivablesTurnoverRatioAssetArr.splice(1);
							that.receivablesTurnoverRatioStatementSrc = that.srcFormate(that.pageInfo.receivablesTurnoverRatio
								.batteryLevel);
						}
						// 存货周转率
						if (that.pageInfo.inventoryTurnoverRatio) {
							that.pageInfo.inventoryTurnoverRatio = JSON.parse(that.pageInfo.inventoryTurnoverRatio);
							that.pageInfo.inventoryTurnoverRatio.name.push("");
							// 语句
							var inventoryTurnoverRatioAssetArr = that.pageInfo.inventoryTurnoverRatio.description.split("|");
							that.inventoryTurnoverRatioStatement = inventoryTurnoverRatioAssetArr[0];
							that.inventoryTurnoverRatioStatementArr = inventoryTurnoverRatioAssetArr.splice(1);
							that.inventoryTurnoverRatioStatementSrc = that.srcFormate(that.pageInfo.inventoryTurnoverRatio
								.batteryLevel);
						}
						// 杜邦分析
						that.pageInfo.dubangAnalysis = JSON.parse(that.pageInfo.dubangAnalysis);
						// 财务分析
						that.pageInfo.financial[0].color = "#0478fc";
						that.pageInfo.financial[1].color = "#FD865A";
						that.pageInfo.financial[2].color = "#775BFF";
						that.pageInfo.financial[3].color = "#00BF8C";
						// 资产负债表
						that.pageInfo.liaTableInfo = JSON.parse(that.pageInfo.liaTableInfo);
						// 利润表
						that.pageInfo.profitTableInfo = JSON.parse(that.pageInfo.profitTableInfo);
						// 财务比率表
						that.pageInfo.financeRatio = JSON.parse(that.pageInfo.financeRatio);

					}
				})
			},
			dateFormate: function(date) {
				var dataStr = date.split("-");
				var newDataStr = dataStr[0] + "年" + dataStr[1] + "月" + dataStr[2] + "日";
				return newDataStr;
			},
			valueFormate: function(val) {
				if (val) {
					if (val >= 10000) {
						val = (val / 10000).toFixed(2) + '亿元';
					} else {
						val = val.toFixed(2) + '万元';
					}
					return val
				} else {
					return '无数据'
				}
			},
			scoreFormate: function(score) {
				if (this.pageInfo.baseInfo.companyName) {
					for (var i = 0; i < this.pageInfo.question.length; i++) {
						if (this.pageInfo.question[i].name == score) {
							return this.pageInfo.question[i].value;
						}
					};
				}
			},
			srcFormate: function(val) {
				if (val == "20%") {
					return require("../../assets/img/report/dianchi-20.svg")
				} else if (val == "40%") {
					return require("../../assets/img/report/dianchi-40.svg")
				} else if (val == "60%") {
					return require("../../assets/img/report/dianchi-60.svg")
				} else if (val == "80%") {
					return require("../../assets/img/report/dianchi-80.svg")
				} else if (val == "100%") {
					return require("../../assets/img/report/dianchi-100.svg")
				}
			},
			pdf: function() {
				document.getElementById("loading").style.display = "block";
				var title = this.pageInfo.baseInfo.companyName + '价值诊断报告.pdf'
				var pageArr = document.getElementsByClassName("content");
				for (var i = 0; i < pageArr.length; i++) {
					this.pdfArr.push(pageArr[i].id)
				}
				PdfOnload.getPdf(this.pdfArr, this.pdfIndex, title);
			}
		}
	})
</script>
<style scoped lang="less">
	.tools {
		width: 1160px;
		height: 60px;
		margin: 0 auto;
		padding-top: 10px;
		box-sizing: border-box;
	}

	.tools button {
		float: right;
		width: 120px;
		padding: 8px;
		border: 1px #aaa solid;
		border-radius: 4px;
		outline: none;
		font-size: 16px;
		transition: background-color 0.3s;
		background: #B1781E;
		color: #fff;
		margin-right: 20px;
		cursor: pointer;
	}

	.homePage,
	.cataloguePage,
	.synthesisScore,
	.synthesisScoreCont,
	.baseInfo,
	.flowAtlas,
	.industrySuggest,
	.industryPic,
	.teamScore,
	.skillScore,
	.productScore,
	.productSuggest,
	.productPic,
	.riskManagementScore,
	.earningsForecast,
	.accessory,
	.accessory_1,
	.accessory_2,
	.accessory_3,
	.disclaimer,
	.debtPayAnalyze,
	.profitAnalyze,
	.growthAnalyze,
	.operateAnalyze,
	.dupontAnalyze,
	.financialCondition,
	.computational,
	.accessory_4,
	.stockIntroduce {
		width: 100%;
		height: 1754px;
	}

	.content {
		width: 1240px;
		height: 100%;
		background: #fff;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		border-top: 1px solid #ccc;
	}


	/* 封面 */

	#homePage {
		background: url(https://flowmp4.oss-cn-beijing.aliyuncs.com/img/startup/diagnosticBasisBanner.jpg?versionId=CAEQIRiBgIDWuOSq3RciIDlkMWIwNWExNzdjMTRiMzk5YzE1NjQxNjE3NzllNjU3)no-repeat center center;
		overflow: hidden;
	}

	.homePage h1 {
		width: 370px;
		height: 96px;
		background: url(../../assets/img/report/logo-0606@2x.png)no-repeat center center;
		background-size: cover;
		margin: 105px 0 0 107px;
	}

	.homePage p {
		font-size: 51px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 100%;
		text-align: center;
		margin: 180px 0 42px 0;
	}

	.homePage>div>div.homePageLine {
		width: 914px;
		height: 2px;
		background: #FFFFFF;
		margin: 0 auto;
	}

	.homePage h2 {
		font-size: 116px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #FFFFFF;
		margin: 46px 0 44px 0;
		text-align: center;
		line-height: 100%;
	}

	.homePage .reportId,
	.homePage h4 {
		font-size: 33px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 100%;
		color: #F7F7F7;
		text-align: center;
	}

	.homePage h4 {
		font-size: 30px;
		margin: 32px 0 0 0;
	}

	.homePage i {
		display: block;
		height: 21px;
		font-size: 21px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #FFf;
		margin-left: -200px;
		line-height: 100%;
		position: absolute;
		bottom: 59px;
		left: 50%;
	}


	/* 目录 */

	.cataloguePage h2 {
		font-size: 48px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #0F060B;
		margin: 172px auto 69px;
		text-align: center;
		line-height: 1;
	}

	.cataloguePage .generalize>div {
		width: 980px;
		height: 54px;
		margin: 23px auto 0px;
	}

	.cataloguePage .generalize>div p:first-child {
		font-size: 32px;
	}

	.cataloguePage .generalize p {
		float: left;
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #0F060B;
		line-height: 54px;
		margin-right: 11px;
	}

	.cataloguePage .generalize>div>div {
		width: 6px;
		height: 54px;
		background: #0478FC;
		float: left;
		margin: 0 28px 0 0;
	}

	.cataloguePage .generalize .dottedLine {
		float: left;
		height: 2px;
		width: 736px;
		/* padding: 20px; */
		margin: 14px 20px 0;
		background: url(../../assets/img/report/catalogue_.png)left center repeat;
		/* border-top: 0.2px dotted #ccc; */
	}

	.cataloguePage .generalize .pageNumber {
		float: left;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #282828;
		line-height: 30px;
	}

	.cataloguePage .generalize ul {
		margin: 0 0 0 194px;
	}

	.cataloguePage .generalize ul li {
		height: 30px;
		margin: 9px 0 13px 0;
		width: calc(980px - 64px);
	}

	.cataloguePage .generalize ul li p {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #423838;
		line-height: 30px;
	}

	.cataloguePage .generalize ul li .dottedLine {
		width: 760px;
	}

	.cataloguePage .generalize ul.flowChart li .dottedLine {
		width: 599px;
	}


	/* 第一页 综合得分 */

	.synthesisScore {
		margin-top: 0px;
	}

	.synthesisScore h3,
	.synthesisScore h3 span.companyName {
		font-size: 48px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #010000;
		line-height: 100%;
		margin: 128px 0 0 110px;
	}

	.synthesisScore h3 span.companyName {
		margin: -12px 0 0 0;
	}

	.synthesisScore h4 {
		font-size: 29px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #010000;
		line-height: 100%;
		margin: 82px 0 0 111px;
	}

	.synthesisScore .content>p {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 34px;
		padding: 0 128px 0 110px;
		margin: 42px 0 0 0;
	}

	.synthesisScore .synthesisScorePic {
		background: url(https://flowmp4.oss-cn-beijing.aliyuncs.com/img/startup/zongdefen.jpg?versionId=CAEQIRiBgMC7ueSq3RciIDBiMmNiMTNlNTMwMDQwZTI5YzNiOTczODhjZWVkODVm)no-repeat center center;
		width: 910px;
		height: 813px;
		background-size: cover;
		margin: 110px auto;
		overflow: hidden;
	}

	.synthesisScore .synthesisScorePic p {
		font-size: 83px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #0478FC;
		line-height: 100%;
		margin: 296px 0 0 343px;
	}


	/* 第二页 综合得分内容 */
	.circleComponent {
		float: left;
		margin-right: 20px;
		font-size: 24px;
		color: #0478fc;
	}

	.synthesisScoreCont h3 {
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #010000;
		margin: 60px 0 30px 114px;
		line-height: 100%;
	}

	.earningsForecast ul {
		height: 240px;
		margin: 40px 0 0 0;
	}

	.synthesisScoreCont ul {
		height: 270px;
		padding: 30px 0 0 110px;
	}

	.earningsForecast .spendingForecast,
	.synthesisScoreCont .spendingForecast {
		width: 70px;
		height: 70px;
		float: left;
		margin: 0 20px 0 0;
	}

	.earningsForecast .scoreCont,
	.synthesisScoreCont .scoreCont {
		float: left;
	}

	.earningsForecast .scoreCont p,
	.synthesisScoreCont .scoreCont p {
		width: auto;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #060001;
		line-height: 28px;
	}

	.earningsForecast .scoreCont p {
		width: 72px;
	}

	.earningsForecast ul li,
	.synthesisScoreCont ul li {
		float: left;
		width: 324px;
		margin: 0 52px 30px 0;
	}

	.earningsForecast .incomeCont>p,
	.earningsForecast .expenditureCont>p {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #111111;
		line-height: 100%;
		margin: 28px 0 0 0;
		text-indent: 75px;
	}

	.earningsForecast .scoreCont span,
	.synthesisScoreCont .scoreCont span {
		font-size: 26px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #0487FC;
	}

	.earningsForecast .scoreTop,
	.synthesisScoreCont .scoreTop {
		height: 88px;
	}

	.earningsForecast .scoreBottom,
	.synthesisScoreCont .scoreBottom {
		width: 194px;
		height: 4px;
		background: #cbe1f4;
		position: relative;
	}

	.earningsForecast .indexActive,
	.scoreBottom .indexActive {
		position: absolute;
		width: 100%;
		height: 4px;
		top: 0;
		left: 0;
		background: #0487Fc;
		z-index: 998;
	}

	.earningsForecast .indexIcon0,
	.earningsForecast .indexIcon1,
	.earningsForecast .indexIcon2,
	.earningsForecast .indexIcon3,
	.scoreBottom .indexIcon0,
	.scoreBottom .indexIcon1,
	.scoreBottom .indexIcon2,
	.scoreBottom .indexIcon3 {
		background: #fff;
		width: 6px;
		height: 4px;
		position: absolute;
		top: 0;
		z-index: 1000;
	}

	.indexIcon0 {
		left: 34px;
	}

	.indexIcon1 {
		left: 74px;
	}

	.indexIcon2 {
		left: 114px;
	}

	.indexIcon3 {
		left: 154px;
	}

	.synthesisScoreCont>div>p {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 34px;
		padding: 0 110px;
		text-indent: 36px;
	}

	.synthesisScoreCont>div>.industryDescLists>p {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 34px;
		padding: 0 110px;
		text-indent: 36px;
	}

	.teamScore h3 span,
	.productScore h3 span,
	.skillScore h3 span,
	.riskManagementScore h3 span,
	.industryScore h3 span,
	.synthesisScoreCont p span {
		text-indent: 0px;
		line-height: 100%;
		margin-top: -4px;
	}


	/* 第三页 基本信息 */

	.baseInfo {
		margin-top: 0px;
	}

	.flowAtlas .flowDiagnose,
	.baseInfo .basicInfo {
		margin: 115px 0 0 110px;
	}

	.incomeForecast,
	.flowAtlas .industryScore,
	.baseInfo .mainBusiness {
		margin: 40px 0 0 110px;
	}

	.synthesisScoreCont .stockStructure,
	.baseInfo .stockStructure {
		margin: 30px 0 0 110px;
	}

	.skillScore img,
	.riskManagementScore img,
	.productScore img,
	.earningsForecast img,
	.debtPayAnalyze img,
	.profitAnalyze img,
	.growthAnalyze img,
	.operateAnalyze img,
	.financialCondition img,
	.teamScore img,
	.flowAtlas img,
	.baseInfo img {
		float: left;
		margin: 0 26px 0 0;
	}

	.flowAtlas h3,
	.teamScore h3,
	.earningsForecast h3,
	.debtPayAnalyze h3,
	.profitAnalyze h3,
	.growthAnalyze h3,
	.operateAnalyze h3,
	.financialCondition h3,
	.productScore h3,
	.skillScore h3,
	.riskManagementScore h3,
	.riskManagementScore h3 span,
	.skillScore h3 span,
	.industryScore h3 span,
	.teamScore h3 span,
	.productScore h3 span,
	.baseInfo h3 {
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #121212;
		line-height: 100%;
	}

	.industryScore h3,
	.skillScore h3,
	.stockStructure h3,
	.financeAnalyze h3,
	.basicInfo h3 {
		line-height: 50px;
	}

	.flowDiagnose h3,
	.earningsForecast h3,
	.debtPayAnalyze h3,
	.profitAnalyze h3,
	.growthAnalyze h3,
	.operateAnalyze h3,
	.financialCondition h3,
	.mainBusiness h3 {
		line-height: 48px;
	}

	.baseInfo .basicInfoCont li span {
		vertical-align: top;
	}

	.industry {
		width: calc(100% - 120px);
	}

	.product h3 {
		line-height: 51px;
		margin-bottom: 20px;
	}

	.productScore h3 {
		line-height: 44px;
	}

	.riskManagementScore h3 {
		line-height: 53px;
	}

	.teamScore h3,
	.group h3 {
		margin-bottom: 20px;
		line-height: 43px;
	}

	.financingSituation h3 {
		line-height: 46px;
		margin: 0 0 20px 0;
	}

	.baseInfo .basicInfoCont {
		height: 200px;
		margin: 30px 0 0 0;
	}

	.financingSituation ul,
	.productAndGroup ul,
	.baseInfo .basicInfoCont ul {
		padding: 0 73px;
	}

	.financingSituation li,
	.productAndGroup li,
	.baseInfo .basicInfoCont li {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 34px;
	}

	.baseInfo .basicInfoCont li {
		float: left;
		width: 50%;
	}

	.mainBusiness p {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 34px;
		padding: 0 110px 0 73px;
		margin: 20px 0 0 0;
	}

	.productAndGroup {
		height: 200px;
		padding: 0 110px;
		margin: 50px 0 0 0;
	}

	.product,
	.group {
		float: left;
		width: 50%;
	}

	.financingSituation {
		margin: 20px 0 0 110px;
	}

	.riskTitle,
	.productScore .productTitle,
	.expenditureForecast,
	.skillTitle,
	.teamTitle {
		margin: 117px 0 0 110px;
	}

	#riskManagementScorePic,
	#skillScorePic,
	#teamScorePic,
	#industryScorePic {
		width: 900px;
		height: 400px;
		margin: 20px auto;
	}

	#productScorePic,
	#stockStructurePieNew,
	#stockStructurePie {
		width: 800px;
		height: 500px;
		margin: 20px auto;
	}

	#flowDiagnose {
		width: 800px;
		height: 400px;
		margin: 20px auto;
	}

	#expenditurePic,
	#incomePic {
		width: 1000px;
		height: 500px;
		margin: 20px;
	}

	#productPicDetail,
	#industryPicDetail {
		width: 800px;
		height: 600px;
		/* margin: 110px auto; */
		/* z-index: 800; */
	}

	.picBox {
		position: relative;
		width: 800px;
		height: 600px;
		margin: 40px auto;
	}

	.leftTitle {
		position: absolute;
		top: 167px;
		width: 27px;
		height: 242px;
		font-size: 26px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #121212;
		line-height: 36px;
		;
		left: -36px;
	}

	.bottomTitle {
		position: absolute;
		bottom: -28px;
		width: 156px;
		/* height: 242px; */
		font-size: 26px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #121212;
		line-height: 36px;
		;
		left: 50%;
		margin-left: -78px;
	}

	.degree_x {
		position: absolute;
		bottom: 24px;
		left: 50%;
		margin-left: -167px;
		display: flex;
		width: 334px;
		justify-content: space-between;
	}

	.degree_y {
		position: absolute;
		top: 154px;
		left: 12px;
	}

	.degree_y>div,
	.degree_x>div {
		font-size: 22px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #121212;
		line-height: 100%;
	}

	.degree_y>div {
		margin: 0 0 108px 0;
	}

	.areaTitle_1,
	.areaTitle_2,
	.areaTitle_3,
	.areaTitle_4,
	.areaTitle_5,
	.areaTitle_6 {
		position: absolute;
		font-size: 25px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		line-height: 100%;
	}

	.areaTitle_1 {
		top: 22px;
		left: 238px
	}

	.areaTitle_2 {
		top: 22px;
		right: 78px
	}

	.areaTitle_3 {
		top: 304px;
		right: 78px
	}

	.areaTitle_4 {
		top: 500px;
		left: 212px
	}

	.areaTitle_5 {
		top: 22px;
		right: 80px
	}

	.areaTitle_6 {
		top: 500px;
		right: 80px
	}

	.synthesisScoreCont .icon1,
	.baseInfo .icon1 {
		width: 48px;
		height: 50px;
	}

	.baseInfo .icon2 {
		width: 54px;
		height: 48px;
	}

	.baseInfo .icon3 {
		width: 48px;
		height: 51px;
	}

	.teamScore .icon,
	.baseInfo .icon4 {
		width: 49px;
		height: 43px;
	}

	.synthesisScoreCont .icon5,
	.baseInfo .icon5 {
		width: 46px;
		height: 46px;
	}

	.skillScore .icon,
	.synthesisScoreCont .icon6,
	.baseInfo .icon6 {
		width: 50px;
		height: 50px;
	}

	.flowAtlas .icon1 {
		width: 50ox;
		height: 48px;
	}

	.earningsForecast .icon,
	.debtPayAnalyze .icon,
	.flowAtlas .icon2 {
		width: 49px;
		height: 49px;
	}

	.productScore .icon {
		width: 50px;
		height: 44px;
	}

	.riskManagementScore .icon {
		width: 46px;
		height: 53px;
	}


	/* 附件 */
	table tr td {
		border: 1px solid #333;
	}

	table>tbody>tr:first-of-type>td {
		border-top: none;
	}

	table>tbody>tr>td:nth-child(n+2) {
		padding-right: 50px;
		text-align: right;
	}

	.computational div.content>span,
	.accessory div.content>span {
		position: absolute;
		left: 110px;
		line-height: 100%;
		top: 130px;
		font-size: 20px;
		color: #3b3b3b;
	}

	.accessory_1 .title,
	.accessory_2 .title,
	.accessory_3 .title,
	.accessory_4 .title,
	.computational .title,
	.accessory .title {
		text-align: center;
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #282828;
		margin: 130px 0 0 0;
		line-height: 100%;
	}

	.accessory_1 table,
	.accessory_2 table,
	.accessory_3 table,
	.accessory_4 table,
	.accessory table {
		padding: 0 110px;
		width: calc(100% - 220px);
		text-align: center;
		border-collapse: collapse;
		margin: 47px auto 0;
		border: 1px solid #000;
	}

	.accessory table td,
	.accessory table th {
		/* width: 50%; */
		height: 54px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #282828;
		line-height: 54px;
		border: 1px solid #282828;
	}

	.accessory table th {
		background: #0487FC;
		color: #fff;
		border-right: 1px solid #282828;
		border-bottom: 1px solid #0487fc;
	}

	.accessory table.expenditureTable tbody tr:last-child>td {
		font-weight: 800;
	}

	.accessory table.expenditureTable td,
	.accessory table.expenditureTable th {
		width: 50%;
	}

	.accessory table.incomeTable td,
	.accessory table.incomeTable th {
		width: 33%;
	}


	/*  */

	.productSuggest .picIntroduce,
	.industrySuggest .picIntroduce {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #121212;
		line-height: 34px;
		margin: 0 0 0 148px;
	}

	.productSuggest h3,
	.industrySuggest h3 {
		font-size: 22px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #121212;
		line-height: 100%;
		margin: 33px 0 0 111px;
	}

	.productSuggest>div.content>p,
	.industrySuggest>div.content>p {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #121212;
		line-height: 34px;
		padding: 27px 110px 0px 111px;
		text-indent: 36px;
	}

	.page-content {
		padding: 116px 110px 0 110px;
	}

	.productSuggest>div.content>p>span,
	.industrySuggest>div.content>p>span {
		line-height: 100%;
		margin-top: -4px;
		text-indent: 0px;
	}


	/* 免责声明 */

	.page-last>img {
		width: 900px;
		height: 376px;
		margin: 0px auto 50px;
		display: block;
	}

	.flow-statement {
		width: 1008px;
		margin: 0 auto;
	}

	.flow-statement>img {
		display: block;
		width: 349px;
		height: 114px;
		margin: 0 auto 47px;
	}

	.flow-statement>ul>li>div.statement-tit {
		height: 20px;
		width: 428px;
		margin: 54px auto 35px;
		font-size: 0;
		position: relative;
	}

	.statement-tit>span.xian {
		/*width: 100%;*/
		width: 135px;
		height: 1px;
		display: block;
		/*float: left;*/
		position: absolute;
		top: 0;
		margin-top: 9.5px;
		background: #4393FF;
	}

	.statement-tit>span.xian:first-of-type {
		left: 2px;
	}

	.statement-tit>span.xian:last-of-type {
		right: 0;
	}

	.statement-tit>img {
		width: 12px;
		height: 12px;
		float: left;
		margin-top: 3px;
	}

	.statement-tit>img:first-of-type {
		margin-left: 135px;
	}

	.statement-tit>p {
		width: 136px;
		height: 20px;
		line-height: 20px;
		font-size: 20px;
		color: #282828;
		float: left;
		background: #FFF;
		text-align: center;
	}

	.statement-main {
		width: 977px;
		padding: 17px 20px 16px 20px;
		background: #E8F4FF;
		font-size: 16px;
		color: #282828;
		line-height: 30px;
		text-indent: 2em;
		text-align: justify;
	}


	/* 五项得分建议 */

	.riskCont p,
	.skillCont p,
	.teamCont p {
		padding: 0 119px 5px 0;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #121212;
		line-height: 34px;
		text-indent: 36px;
	}

	.productCont p,
	.product_Cont p,
	.industryCont p {
		padding: 0 119px 0px 0;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #121212;
		line-height: 32px;
		text-indent: 32px;
	}

	.riskCont,
	.product_Cont,
	.skillCont,
	.teamCont {
		margin: 0 0 0 110px;
	}

	.homePage h1 {
		float: left;
		margin: 0;
	}

	.homePage .logoBox {
		height: 96px;
		margin: 105px 0 0 107px;
		position: relative;
	}

	.homePage .logoBox .stock-logo {
		float: left;
		position: relative;
		padding-left: 48px;
	}

	.homePage .logoBox .stock-logo:before {
		content: '';
		width: 2px;
		height: 80px;
		position: absolute;
		/* background: #7F7F7F; */
		background: #feffff;
		margin: 0 23px;
		left: 0;
	}

	.stock-logo img {
		max-height: 72px;
	}

	p.secondaryaCategory {
		font-size: 20px !important;
		font-weight: bold !important;
		line-height: 2 !important;
		font-family: "Microsoft YaHei" !important;
	}

	.synthesisScore,
	.baseInfo {
		margin: 0;
	}

	.product {
		width: 100%;
	}

	.productCont ul li {
		float: left;
		width: 50%;
	}

	.situationCont {
		padding: 0 110px 0 0;
	}

	.page-stamp .artFont {
		width: 72px;
		height: 98px;
		position: absolute;
		top: 655px;
		left: 332px;
	}

	.homePage .artFont {
		/* width: 64px; */
		height: 45px;
		margin: 0;
		position: absolute;
		top: 670px;
		left: 278px;
	}

	.productScore .product_Cont h4,
	.skillScore .skillCont h4,
	.teamScore .teamCont h4,
	.industrySuggest .industryCont h4,
	.industryScore .industryCont h4 {
		font-size: 18px;
		font-weight: bold;
		color: #121212;
		font-family: Microsoft YaHei;
		line-height: 100%;
		margin: 30px 0 15px;
	}

	.synthesisScore .synthesisScorePic {
		background: url(../../assets/img/guzhi/zongdefen.jpg)no-repeat center center;
		height: 828px;
		width: 926px;
	}

	.synthesisScore .synthesisScorePic p {
		margin: 308px 0 0 344px;
	}

	.situationCont {
		margin: 10px 0 0 0;
	}

	.synthesisScoreCont h4 {
		font-size: 18px;
		color: #0478fc;
		font-family: Microsoft YaHei;
		font-weight: bold;
		margin: 0 0 0 110px;
		line-height: 50px;

	}

	.synthesisScoreCont h3 {
		margin: 30px 0 30px 114px;
	}

	.icon6 {
		margin: 0 23px 0 0 !important;
	}

	.icon7 {
		width: 50px;
		height: 50px;
	}

	.flowDiagnose,
	.financeAnalyze {
		float: left;
		margin: 0 40px 0 0 !important;
	}

	#flowDiagnose_BaseInfo,
	#financeAnalyze_BaseInfo,
	#flowDiagnose,
	#financeAnalyze {
		width: 500px;
		height: 350px;
		margin: 20px auto;
	}

	.financialConditionTitle,
	.profitTitle,
	.growthTitle,
	.debtPayTitle,
	.operateTitle {
		margin: 115px 0 0 110px;
	}

	.financialConditionTitle .icon,
	.profitTitle .icon,
	.operateTitle .icon,
	.growthTitle .icon {
		width: 49px;
		height: 49px;
	}

	.financialCondition .imgUl {
		width: 100%;
		height: auto;
		margin: 8% auto 0;
	}

	.financialCondition .imgUl>li {
		width: 1008px;
		height: auto;
		list-style-type: none;
		margin: 0 auto;
		margin-bottom: 6%;
		overflow: hidden;
	}

	.financialCondition .imgUl>li>.liDivImg {
		float: left;
		width: 170px;
		height: 170px;
		margin-left: 4%;
		margin-right: 56px;
		text-align: center;
		border: 3px solid #0478fc;
		border-radius: 90px;
	}

	.financialCondition .imgUl>li>h3 {
		width: 70%;
		float: left;
		font-size: 18px;
		text-align: left;
		margin-top: 2%;
		cursor: default;
	}

	.financialCondition .imgUl>li>p {
		float: left;
		width: 70%;
		margin-top: 1%;
		font-size: 18px;
		color: #282828;
		line-height: 2;
		text-indent: 2em;
		cursor: Default;
		text-align: justify;
	}

	.accessory_1 .titleBox,
	.accessory_2 .titleBox,
	.accessory_3 .titleBox,
	.accessory_4 .titleBox {
		margin: 130px 0 0 0;
		padding: 0px 110px;
		display: flex;
		justify-content: space-between;
	}

	.accessory_1 .titleBox span,
	.accessory_2 .titleBox span,
	.accessory_3 .titleBox span,
	.accessory_4 .titleBox span {
		line-height: 100%;
		font-size: 20px;
	}

	.accessory_1 .titleBox .title,
	.accessory_2 .titleBox .title,
	.accessory_3 .titleBox .title,
	.accessory_4 .titleBox .title {
		margin: 0;
	}

	.formula-container {
		padding: 40px 110px 0;
	}

	.product_Cont p {
		padding: 0 119px 5px 0;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #121212;
		line-height: 34px;
		text-indent: 36px;
	}

	table>tbody>tr.trTwo>td:first-of-type,
	table>tbody>tr.trThree>td:first-of-type {
		font-size: 12px;
	}

	table.profitTableInfo>tbody>tr.trThree>td:first-of-type {
		font-size: 14px;
	}

	table>tbody>tr.trOne {
		font-size: 14px;
	}

	.accessory_1 table,
	.accessory_2 table,
	.accessory_3 table,
	.accessory_3 table {
		margin: 15px auto 0;
	}

	table.liaTableInfo tr {
		height: 34px;
	}

	.accessory_3 .title {
		margin: 50px 0 0 0;
	}

	table.profitTableInfo tr {
		height: 40px;
	}

	table>tbody>tr.trThree>td:first-of-type {
		padding-left: 70px;
	}

	.baseInfo .icon2 {
		width: 50px;
		height: 48px;
	}

	table>thead>tr {
		background: #0487Fc;
		border: 1px solid #000;
	}

	table>thead>tr>th {
		border: 1px solid #000;
		border-bottom-color: #0487fc;
	}

	.table_explain {
		width: 1008px;
		margin: 0 auto;
		overflow: hidden;
		margin-top: 52px;
	}

	.table_explain>.right {
		float: right;
		width: 420px;
		/* height: 380px; */
		height: 396px;
		padding: 0 24px;
		background: rgba(247, 247, 247, 1);
	}

	.table_explain>.right>h4 {
		font-weight: bold;
	}

	.table_explain>.right>p {
		margin: 12px 0 0 0;
	}

	.table_explain>.right>p:nth-of-type(2),
	.table_explain>.right>p:nth-of-type(3) {
		margin: 6px 0 0 0;
	}

	.table_explain>.right>h4 {
		margin-top: 44px;
		font-size: 18px;
		font-family: MicrosoftYaHei;
		color: rgba(40, 40, 40, 1);
		line-height: 24px;
		text-indent: 36px;
		font-weight: bold;
	}

	.table_explain>.right>div:not(.grade)>p {
		font-size: 17px;
		font-family: MicrosoftYaHei;
		color: rgba(51, 51, 51, 1);
		line-height: 26px;
		text-align: justify;
		text-indent: 36px;
		margin: 12px 0;
	}

	.grade {
		position: relative;
		float: right;
		margin-top: 20px;
		overflow: hidden;
		height: 22px;
		height: 22px;
		width: 92px;
	}

	.grade img {
		margin-right: 6px;
		height: 22px;
		width: 40px;
	}

	.table_explain>.right>div.grade>p {
		line-height: 22px;
		font-weight: bold;
		display: inline-block;
		float: right;
		height: 20px;
		font-size: 16px;
		font-family: MicrosoftYaHei;
		color: #000;
		/* width: 44px; */
		margin: 0;
	}

	.statement-tit>p.dian {
		font-size: 23px;
		width: 12px;
		height: 16px;
		float: left;
		color: #0487Fc;
		margin: -2px 0 0 135px;
		line-height: 100%;
	}

	.statement-tit>p.dian_ {
		margin: -2px 0 0 0;
	}

	.sovency_table,
	.profitability_table,
	.growUp_table,
	.operational_table {
		height: 450px;
		width: 500px;
	}

	.areaTitle_3 {
		top: 508px;
		right: 78px;
	}

	.computational .formula-container ul li p.dian {
		font-size: 23px;
		width: 12px;
		height: 16px;
		float: left;
		color: #0487Fc;
		margin: 2px 13px 0 0px;
		line-height: 100%;
	}

	.homePage h1 {
		float: left;
		margin: 0;
	}

	.homePage .logoBox {
		height: 96px;
		margin: 105px 0 0 107px;
		position: relative;
	}

	.homePage .logoBox .stock-logo {
		float: left;
		position: relative;
		padding-left: 48px;
	}

	.homePage .logoBox .stock-logo:before {
		content: '';
		width: 2px;
		height: 80px;
		position: absolute;
		/* background: #7F7F7F; */
		background: #feffff;
		margin: 0 23px;
		left: 0;
	}

	.stock-logo img {
		max-height: 72px;
	}

	.pageNum {
		width: 57px;
		position: absolute;
		color: #000;
		line-height: 100%;
		font-size: 22px;
		left: 50%;
		margin-left: -27px;
		bottom: 37px;
	}

	.pageNum p {
		color: #0478fc;
		display: inline;
		margin: 0px 8px 0 0;
		font-size: 24px;
		line-height: 100%;
	}

	.pageNum span {
		margin-top: -3px;
	}

	.statement-main div {
		margin: 30px 0 0 118px;
		overflow: hidden;
	}

	.statement-main div img {
		float: left;
		width: 138px;
		height: 138px;
		margin: 0 36px 20px 0;
	}

	.statement-main div ul {
		margin: 4px 0 0 0;
	}

	.statement-main div ul li {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #282828;
		line-height: 30px;
	}

	.statement-main div ul li span {
		width: 72px;
		text-indent: 0;
		text-align-last: justify;
		text-align: justify;
		text-justify: distribute-all-lines;
	}

	.formula-container {
		padding: 40px 110px 0;
	}

	.formula-container>h3 {
		font-size: 20px;
		line-height: 1;
		color: #282828;
		margin-bottom: 20px;
		margin-top: 26px;
	}

	.formula-container>ul>li {
		overflow: hidden;
		margin-bottom: 6px;
	}

	.formula-container>ul>li>img {
		width: 9px;
		height: 9px;
		float: left;
		margin-right: 13px;
		margin-top: 6px;
	}

	.formula-container>ul>li>.formula-tit {
		width: 984px;
		float: left;
		margin-bottom: 4px;
	}

	.formula-tit img {
		margin-top: 8px;
		margin-bottom: 10px;
	}

	.formula-container>ul>li>.formula-tit>p {
		line-height: 34px;
		color: #282828;
		font-size: 18px;
	}

	.formula-container>ul>li>.formula-tit>h4 {
		line-height: 34px;
		color: #282828;
		font-size: 20px;
	}

	.formula-container>ul>li>.formula-tit>h4>span {
		display: inline-block;
	}

	.formula-container>ul>li>p {
		font-size: 18px;
		line-height: 34px;
		color: #282828;
	}

	.sovency_table,
	.profitability_table,
	.growUp_table,
	.operational_table {
		float: left;
		width: 500px;
		height: 450px;
	}

	/deep/ .circleComponent {
		margin: 8px;
	}
</style>
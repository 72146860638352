<template>
        <div :id="id"></div>
</template>
<script>
import * as Highcharts from 'highcharts'
import * as Exporting from 'highcharts/modules/exporting'
import Highcharts3D from 'highcharts/highcharts-3d'
Exporting(Highcharts)
Highcharts3D(Highcharts)
// import formatCurrency from '../../assets/js/numberToCurrency'

export default ({
    props: [
        'chartData','id'
    ],
    data() {
        return {
            chartName: [],
            chartValue1: [],
            chartValue2: [],
        }
    },
    mounted(){
        var that = this;
        if(that.chartData){
            that.chartName = that.chartData.years;
            if(that.id == "operatingReceipt"){
                that.chartValue1 = {
                    name: '营业收入（首次估值）',
                    data: this.datanull(JSON.parse(that.chartData.netArr[0]))
                };
                that.chartValue2 = {
                    name: '营业收入（本次估值）',
                    data: this.datanull(JSON.parse(that.chartData.netArr[1]))
                };
            } else {
                that.chartValue1 = {
                    name: '净利润（首次估值）',
                    data: this.datanull(JSON.parse(that.chartData.netArr[0]))
                };
                that.chartValue2 = {
                    name: '净利润（本次估值）',
                    data: this.datanull(JSON.parse(that.chartData.netArr[1]))
                };
            }
            that.setOptions(that.id, that.chartName, that.chartValue1, that.chartValue2);
        }
    },
    methods: {
        setOptions: function(id, datax, dataone, datatwo){
            dataone.data.forEach(function(e, i) {
                dataone.data[i] = Number(dataone.data[i])
            });
            datatwo.data.forEach(function(e, i) {
                datatwo.data[i] = Number(datatwo.data[i])
            });

            if (dataone.data[8] == 0) {
                dataone.data.splice(8, 1)
            }
            if (dataone.data[7] == 0) {
                dataone.data.splice(7, 1)
            }

            Highcharts.chart(id, {
                chart: {
                    type: 'area',
                    options3d: {
                        enabled: true,
                        alpha: 15,
                        beta: 30,
                        depth: 200
                    },
                    margin: [0, 0, 70, 0]
                },
                credits: {
                    enabled: false // 禁用版权信息
                },
                exporting: { //禁用导出模块  菜单按钮
                    enabled: false
                },
                title: {
                    text: ''
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    labels: {
                        format: '{value:,.0f}'
                    }
                },
                xAxis: [{
                    visible: false,
                    categories: datax
                }, {
                    visible: true,
                    categories: datax,
                    gridLineColor: 'transparent',
                    labels: {
                        y: 40
                    }
                }],
                plotOptions: {
                    area: {
                        depth: 100,
                        marker: {
                            enabled: false
                        },
                        states: {
                            inactive: {
                                enabled: false
                            }
                        }
                    }
                },
                tooltip: {
                    show: false
                },
                series: [{
                    name: dataone.name,
                    lineColor: '#0478FC',
                    color: '#0478FC',
                    fillColor: 'rgb(4,120,252,.7)',
                    data: dataone.data,
                    dataLabels: {
                        enabled: true
                    }
                }, {
                    xAxis: 1,
                    lineColor: '#0BC9CB',
                    color: '#0BC9CB',
                    fillColor: 'rgb(11,201,203,.7)',
                    name: datatwo.name,
                    data: datatwo.data,
                    dataLabels: {
                        enabled: true
                    }
                }]
            });
        },
        datanull: function (data) {
            var arr = []
            data.forEach(function(ele, i) {
                if (!ele) {
                    ele = 0;
                }
                arr[i] = ele
            });
            return arr
        }
    }
})
</script>
<style scoped>
</style>

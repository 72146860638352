$(function() {
	sessionStorage.stage = 7;
	$(document).on("mouseover", ".service_type li", function() {
		$(this).addClass("active").siblings().removeClass("active");
		$(".service_content li").eq($(this).index()).removeClass("hide").siblings().addClass("hide");
	});
	
	$(document).on("click", ".mobile_service_type li", function() { //移动端的精选服务
		$(this).addClass("active").siblings().removeClass("active");
		$(".mobile_service_content li").eq($(this).index()).removeClass("hide").siblings().addClass("hide");
	});
	
	$(document).on("mouseover", ".swiper-slide", function() {
		$(this).find("div").eq(0).css("background", "rgba(0, 0, 0, .4)");
		$(this).find("div>p").addClass("hide").siblings().removeClass("hide");
		$(this).find("div .advisory_introduce").addClass("animated fadeInUp");
		console.log($(this))
	})

	$(document).on("mouseleave", ".swiper-slide", function() {
		$(this).find("div").eq(0).css("background", "none");
		$(this).find("div>p").removeClass("hide").siblings().addClass("hide");
		$(this).find("div>p").addClass("animated fadeInDown");
	})


})

<template>
	<div :id="id"></div>
</template>
<script>
	import * as echarts from 'echarts/lib/echarts.js'

	export default ({
		props: [
			'chartData', 'id'
		],
		data() {
			return {
				radarStyle: {
					fontSize: 18,
					radius: "60%",
					lineHeight: 34,
					nameGap: 10,
					flag: false,
				}
			}
		},
		mounted() {

			if (this._isMobile()) {
				// 移动端
				this.radarStyle = {
					fontSize: 12,
					radius: "50%",
					lineHeight: 20,
					nameGap: 4
				}
				this.flag = true;
				this.initChart();
			} else {
				// pc端
				this.radarStyle = {
					fontSize: 16,
					radius: "60%",
					lineHeight: 34,
					nameGap: 10
				}
				this.flag = false;
				this.initChart();
			}
		},
		methods: {
			//App.vue
			_isMobile: function() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag;
			},
			initChart: function() {
				this.chart = echarts.init(document.getElementById(this.id));
				this.setOptions(this.id, this.chartData, this.radarStyle, this.flag)
			},
			setOptions: function(id, data, style, flag) {
				var radarMap = echarts.getInstanceByDom(document.getElementById(id));
				if (radarMap == undefined) {
					radarMap = echarts.init(document.getElementById(id));
				}
				var indicatorArr = [],
					valueObbj = {
						valuearrone: [],
						valuearrtwo: [],
						valuearrthree: [],
					};
				for (var i = 0; i < data.questions.length; i++) {
					var obj = {};
					obj.max = 100;

					for (var j = 0; j < data.questions[0].value.length; j++) {
						//          data.questions[i].value[j] = parseInt(data.questions[i].value[j])
						data.questions[i].value[j] = Number(data.questions[i].value[j]).toFixed(2);
					}

					obj.name = data.questions[i].name + data.questions[i].value.join('/');
					indicatorArr.push(obj);
					valueObbj.valuearrone.push(data.questions[i].value[0]);
					valueObbj.valuearrtwo.push(data.questions[i].value[1]);
					valueObbj.valuearrthree.push(data.questions[i].value[2]);
				}
				var option = {
					tooltip: {},
					animation: false,
					// grid: { // 控制图的大小，调整下面这些值就可以，
					//     top: '23%',
					//     left: '200',
					//     right: '18%',
					//     bottom: '10%',
					//     containLabel: true
					// },
					legend: {
						itemHeight: 6,
						left: 'right',
						bottom: 10
					},
					radar: {
						name: {
							textStyle: {
								color: '#000',
								borderRadius: 3,
							},
							formatter: function(value) {
								// console.log(value)
								let result = "";
								if (id == 'flowDiagnosePic') {
									var twowordage = value.substring(0, 8);
									var twonumber = value.substring(8, value.length);
									result = twowordage + '\n' + twonumber
								} else {
									var fourwordage = value.substring(0, 4);
									var fournumber = value.substring(4, value.length);
									result = fourwordage
								}
								return result;
							}
						},
						center: ['50%', '50%'],
						radius: '50%',
						nameGap: 10, // 名称和轴的距离
						shape: 'polygon',
						axisLine: {
							show: false, // 坐标轴线的显示与否，默认显示，属性show控制显示与否
							symbol: 'none',
							fontSize: 20,
							lineStyle: {
								color: '#333',
								width: 1,
								type: 'solid', //可选值：solid，dashed,dotted
							}
						},
						name: {
							textStyle: {
								fontSize: style.fontSize,
								lineHeight: style.lineHeight
							},
							formatter: function(value) {
								// console.log(value)
								let result = "";
								if (id == 'flowDiagnosePic' || id == 'flowValuation') {
									if (flag) {
										var twowordage = value.substring(0, 6);
										var twowordage_ = value.substring(6, 13);
										var twonumber = value.substring(13, value.length);
										result = twowordage + '\n' + twowordage_ + '\n' + twonumber
									} else {
										var twowordage = value.substring(0, 10);
										var twonumber = value.substring(10, value.length);
										result = twowordage + '\n' + twonumber
									}
								} else {
									var fourwordage = value.substring(0, 4);
									var fournumber = value.substring(4, value.length);
									result = fourwordage + '\n' + fournumber
								}
								return result;
							}
						},
						indicator: indicatorArr,
					},
					series: [{
						type: 'radar',
						data: valueObbj.valuearrthree[0] ? [{
								value: valueObbj.valuearrone,
								name: data.years[0],
								lineStyle: {
									color: '#0478FC',
									type: 'solid',
								},
								areaStyle: {
									color: 'rgba(4, 120, 252, 0.3)',
								},
								itemStyle: {
									color: '#0478FC',
								}
							},
							{
								value: valueObbj.valuearrtwo,
								name: data.years[1],
								lineStyle: {
									color: '#FBBA2E',
									type: 'solid',
								},
								areaStyle: {
									color: '#EFCD10FF',
								},
								itemStyle: {
									color: '#FBBA2E',
								}
							},
							{
								value: valueObbj.valuearrthree,
								name: data.years[2],
								lineStyle: {
									color: '#FA8949',
									type: 'solid',
								},
								areaStyle: {
									color: '#FA8949FF',
								},
								itemStyle: {
									color: '#FA8949',
								}
							}
						] : [{
								value: valueObbj.valuearrone,
								name: data.years[0],
								lineStyle: {
									color: '#0478FC',
									type: 'solid',
								},
								areaStyle: {
									color: 'rgba(4, 120, 252, 0.3)',
								},
								itemStyle: {
									color: '#0478FC',
								}
							},
							{
								value: valueObbj.valuearrtwo,
								name: data.years[1],
								lineStyle: {
									color: '#FBBA2E',
									type: 'solid',
								},
								areaStyle: {
									color: '#EFCD10FF',
								},
								itemStyle: {
									color: '#FBBA2E',
								}
							}
						]
					}]
				};
				radarMap.setOption(option);
			}
		}
	})
</script>
<style scoped>
	#financialAnalysisPic,
	#flowDiagnosePic {
		width: 560px;
		height: 400px;
		margin: 60px auto;
	}

	@media screen and (max-width: 765px) {

		#financialAnalysisPic,
		#flowDiagnosePic {
			width: 100%;
			height: auto;
			margin: 60px auto;
		}

		.financialAnalysis,
		.flowDiagnose {
			float: inherit;
			margin: 0 0 20px 0;
		}
	}
</style>

<template>
	<div id="details">
		<Header />
		<div style="padding-bottom:100px">
			<ul class="breadcrumb">
				<li>
					<img src="../../assets/img/mobileClient/weizhi.svg" alt="">
				</li>
				<li><a>当前位置：</a></li>
				<li><a href="/">首页</a></li>
				<li v-if="type!=3">
					<router-link tag="a" :to="{path:'/policyService',query:{serviceType:1,current:1}}">
						{{type!=3?'政策服务':''}}
					</router-link>
				</li>
				<li>
					<a @click="skip">{{type==1?'制度法规':type==2?'政策补贴':'助企培训'}}</a>
				</li>
				<li class="active">{{particulars.caseName}}</li>
			</ul>
			<div class="clear"></div>
			<div class="policyDetails">
				<div class="mainHeading">
					<p class="headline">{{particulars.caseName}}</p>
					<p class="time">发布时间：{{particulars.createTime}}</p>
				</div>
				<div class="policy" v-html="serviceContent">{{serviceContent}}</div>
			</div>
		</div>
		<Footer />
		<router-view />
	</div>
</template>

<script>
	import Header from '../../common/header.vue';
	import Footer from '../../common/footer.vue';
	import qs from 'qs';
	import axios from 'axios';
	import '../../assets/css/common.css'
	export default {
		data() {
			return {
				particulars: [],
				serviceContent: '',
				fromPath: '',
				type: 1 //1（制度法规）2（政策补贴）3（助企培训）
			}
		},
		components: {
			Header,
			Footer
		},
		mounted() {
			this.type = this.$route.query.serviceType;
			this.$post('news/policyService/getDetailService', qs.stringify({
					serviceId: this.$route.query.id
				}))
				.then((res) => {
					this.particulars = res.data.content.policyService;
					this.serviceContent = res.data.content.serviceContent;
					document.title = res.data.content.policyService.caseName;
				});
		},
		methods: {
			skip: function() {
				this.$router.push({
					path: '/policyService',
					query: {
						serviceType: this.type,
						current: 1
					}
				});
			}
		}

	}
</script>

<style scoped>
	.mainHeading {
		width: 1200px;
		border-bottom: 1px solid #D8D8D8;
		padding-bottom: 25px;
		margin: 0 auto;
	}

	.headline {
		font-size: 22px;
		font-weight: bold;
		color: #282828;
		line-height: 14px;
		text-align: center;
	}

	.time {
		font-size: 14px;
		color: #999999;
		line-height: 28px;
		text-align: center;
		margin-top: 23px;
	}

	.policy {
		width: 1200px;
		padding-top: 23px;
		font-size: 14px;
		font-weight: 400;
		color: #282828;
		line-height: 24px;
		text-align: justify;
		margin: 0 auto;
	}

	.policy img {
		max-width: 1200px;
	}
	
	.policyDetails{
		margin-top: 50px;
	}

	@media screen and (max-width: 750px) {
		#details {
			overflow: hidden;
		}

		.mainHeading {
			width: 100%;
		}

		.policy {
			width: 100%;
			padding: 0 20px;
		}

		.headline {
			line-height: 30px;
			padding: 0 20px;
			margin-top: 20px;
		}

		.time {
			font-size: 14PX;
		}
	}
</style>

<template>
	<div>
		<Header />
		<AuditStatusTitle />
		<div class="information viewContent">
			<ExamineNav />
			<div>
				<div class="finanAnalysis-main">
					<div class="row" v-for="(topic, idx) in list">
						<div class="position-tit-container">
							<span>{{idx+1}}</span>
							<span class="postion-tit">{{topic.content}}</span>
						</div>
						<div class="options-container">
							<ul>
								<li v-for="(item, i) in topic.options" :class="item.chooseFlag?'active':''">
									<input v-if="item.chooseFlag" type="radio" disabled="disabled" checked="checked"
										:name="item.id">
									<input v-if="!item.chooseFlag" type="radio" disabled="disabled" :name="item.id">
									<label :for="item.id">{{item.content}}</label>
									<span class="back"></span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

		<Footer />
		<router-view />
	</div>
</template>

<script>
	import Header from '../header.vue';
	import Footer from '../footer.vue';
	import AuditStatusTitle from './auditStatusTitle.vue';
	import ExamineNav from './examineNav.vue';
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex';

	export default {
		data() {
			return {
				list: [],
				projectName:''
			}
		},
		components: {
			Header,
			Footer,
			AuditStatusTitle,
			ExamineNav
		},
		mounted() {
			if (this.$route.query.type == 7) {
				this.projectName = this.domain.diagnoseProject;
			} else if (this.$route.query.type == 5) {
				this.projectName = this.domain.traceProject;
			} else if (this.$route.query.type == 2) {
				this.projectName = this.domain.valuationProject;
			}
			this.getFinancialAnalysis()
		},
		methods: {
			getFinancialAnalysis:function() {
				var params = {
					reportId: this.$route.query.reportId,
				};
				if (this.$route.query.type == 5) {
					params.traceReportId = this.$route.query.traceReportId;
					params.reportType = this.$route.query.type;
					params.year = this.$route.query.year;
				}

				this.$post(this.projectName+'auditInfoShow/qryFinanceQuestion', qs.stringify(params))
					.then((res) => {
						if (res.data.code == 200) {
							var data = res.data;
							if (this.$route.query.type == 5) {
								if (data.content.financeQuestionList) {
									this.list = data.content.financeQuestionList;
								}
							} else {
								this.list = data.content;
							}
						}
					})
			}
		}


	}
</script>

<style scoped>
	.finanAnalysis-main{
		padding: 20px 40px 40px;
	}
</style>

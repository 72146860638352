// 配置全局接口域名
const domain = 'http://test.flcccc.com/';
const dVAjaxUrl = 'http://tdv.flcccc.com/'

const ajaxUrl='flow/';

// const valuationProject = 'flow/valuation/'; //估值项目
// const diagnoseProject = 'flow/diagnose/'; //诊断项目
const traceProject = 'trace/'; //价值追踪项目
const serviceTraining = 'news/'; //助企培训、政策服务

const valuationProject = 'flow/'; //估值项目
const diagnoseProject = 'flow/'; //诊断项目


// const domain = 'http://192.168.12.213:8089/';
// const valuationProject = ''; //估值项目
// const ajaxUrl='';

export default {
	testUrl: domain,
	valuationProject: valuationProject,
	diagnoseProject: diagnoseProject,
	traceProject: traceProject,
	serviceTraining:serviceTraining,
	ajaxUrl:ajaxUrl,
	dVAjaxUrl:dVAjaxUrl
}

<template>
	<div>
		<footer>
			<div class="footer-container">
				<div class="footer-logo">
					<img src="../../assets/img/login/logo_06.png" alt="">
					<ul>
						<li><a :href="flowUrl">首页</a></li>
					</ul>
				</div>
				<div class="customerServiceInformation">
					<div class="serviceTel">
						<p>
							<img src="../../assets/img/serviceTel.png" alt="客服电话-心流">
							<span>客服电话：010-8444-6599</span>
						</p>
						<p><span>17610076778</span></p>
						<p>
							<img src="../../assets/img/companyAddress.png" alt="公司地址-心流">
							<span>地址：北京市朝阳区亮马桥路甲40号二十一世纪大厦B座303～305</span>
						</p>
						<div class="friendlyLink">
							<a href="https://www.v-next.cn/index.do" target="_blank">友情链接：科融通</a>
							<a href="https://tbwjw.com" target="_blank">投标方案</a>
							<a href="http://www.zgzhiqi.com" target="_blank">国家工商总局核名</a>
							<a href="https://www.okcis.cn/zbjg/" target="_blank">中标结果查询</a>
						</div>
					</div>
					<div class="QR_code">
						<div class="wechartCode">
							<img src="../../assets/img/wechartCode.png" alt="">
							<p>关注公众号</p>
						</div>
						<div class="onlineConsult">
							<img src="../../assets/img/onlineConsult.png" alt="">
							<p>在线咨询</p>
						</div>
					</div>
				</div>
			</div>
			<div class="footer-floor2">
				<p>©2018心流网 <a target="_blank"
						href="https://beian.miit.gov.cn">京ICP备18036155号-2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;增值电信业务经营许可证:京B2-20182113</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a
						target="_blank"
						href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502037130">京公网安备
						11010502037130号</a></p>
			</div>
		</footer>
		<div id="mobile_footeer">
			<div class="QR_code">
				<div class="wechartCode">
					<img src="../../assets/img/wechartCode.png" alt="">
					<p>关注公众号</p>
				</div>
				<div class="onlineConsult">
					<img src="../../assets/img/onlineConsult.png" alt="">
					<p>在线咨询</p>
				</div>
			</div>
			<div class="copyright">Copyright © 2012-{{year}} flcccc.com </br>版权所有 增值电信业务经营许可证:京B2-20182113</br> 京公网安备
				11010502037130号</div>
		</div>

	</div>
</template>

<script>
	import '../../assets/css/common.css';
	import API_CONFIG from "../../../vue.config.js"
	export default {
		data() {
			return {
				year: '',
				flowUrl:''
			}
		},
		mounted() {
			var nowYear = new Date();
			this.year = nowYear.getFullYear();
			this.flowUrl = API_CONFIG.devServer.proxy['/api'].target;
		}
	}
</script>

<style scoped>
	footer {
		min-width: 1200px;
		width: 100%;
		height: auto;
		background: #F5F7FA;
		overflow: hidden;
	}

	.footer-container {
		width: 1200px;
		margin: 0 auto;
		color: #d6d6d6;
		overflow: hidden;
		position: relative;
		padding-top: 30px;
	}

	.QR-code {
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -77px;
	}

	.QR-code>div {
		float: left;
		width: 124px;
	}

	.app-code {
		margin-right: 42px;
	}

	.QR-code>div>img {
		width: 100%;
	}

	.footer-container .QR-code>div>p {
		width: 100%;
		margin: 12px 0 0 0;
		padding: 0;
		text-align: center;
	}

	.footer-logo {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 5px;
		border-bottom: 1px solid #D4D7DC;
	}

	.footer-logo>img {
		width: 238px;
	}

	.footer-logo>ul {
		padding-right: 80px;
	}

	.footer-logo>ul li a {
		font-size: 14px;
		color: #282828;
	}

	.customerServiceInformation {
		padding: 0 64px 19px 75px;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #D4D7DC;
	}

	.serviceTel p img {
		vertical-align: middle;
		margin-right: 8px;
	}

	.serviceTel p {
		font-size: 14px;
		color: #282828;
	}

	.serviceTel p:first-child {
		margin-top: 29px;
	}

	.serviceTel p:nth-of-type(2) span {
		margin: 10px 0 20px 100px;
	}

	.QR_code {
		overflow: hidden;
	}

	.QR_code p {
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #282828;
		line-height: 14px;
		text-align: center;
	}

	.QR_code>div {
		width: 146px;
		float: left;
	}

	.footer-floor2 {
		width: 1168px;
		margin: 0 auto;
		border-top: 1px solid rgb(255, 255, 255, .4);
		text-align: center;
	}

	.footer-floor2 p {
		text-align: center;
		font-size: 12px;
		line-height: 66px;
		color: #1f1f1f;
		opacity: 0.7;
	}

	.footer-floor2 a {
		cursor: pointer;
		color: #1f1f1f;
	}

	.friendlyLink {
		margin: 20px auto;
	}

	.friendlyLink a {
		color: #000;
		margin-right: 20px;
		font-size: 12px;
	}

	#mobile_footeer {
		display: none;
	}

	@media screen and (max-width: 750px) {
		footer {
			display: none;
		}

		#mobile_footeer {
			display: block;
			background: #3c4860;
		}

		.QR_code {
			width: 80%;
			padding: 20px 0;
			display: flex;
			justify-content: space-between;
			margin: 0 auto;
		}
		
		.QR_code>div img{
			width: 76px;
		}
		.QR_code p {
			font-size: 14PX;
			padding-top: 10px;
			color: #fff;
		}

		.wechartCode,
		.onlineConsult {
			text-align: center;
		}

		.copyright {
			font-size: 12PX;
			color: #fff;
			text-align: center;
			padding: 20px;
		}
	}
</style>

<template>
        <div :id="id"></div>
</template>
<script>
import * as echarts from 'echarts/lib/echarts.js'
import formatCurrency from '../../assets/js/numberToCurrency'


export default ({
    props: [
        'chartData','id'
    ],
    data() {
        return {
            chartName:[],
            chartValue:[]
        }
    },
    mounted(){
        for(var i in this.chartData){
            this.chartName.push(this.chartData[i].name);
            this.chartValue.push(this.chartData[i].value);
        };
        this.initChart()
    },
    methods: {
        initChart: function(){
				this.chart = echarts.init(document.getElementById(this.id));
                this.setOptions(this.id, this.chartName, this.chartValue)
        },
        setOptions: function(id, dataxAxis, datayAxis){
            var fontSize = 14;
            var labelObj = {},
                normalObj = {};
            switch (id) {
                case "barOne":
                    var yAxisName = "万元";
                    break;
                case "barThree":
                    var yAxisName = "万元";
                    break;
                case "barTwo":
                    var yAxisName = "倍";
                    break;
            }

            var xAxis = [],
                yAxis = [],
                position;
            var xObj = {},
                yObj = {
                    axisLabel: {
                        textStyle: {
                            fontSize: fontSize
                        },
                        color: '#282828',
                    }
                },
                splitLine = {},
                axisTick = {},
                data = [],
                nameTextStyle = {};
            xObj.type = "value";
            xObj.name = yAxisName;
            xObj.position = "top";
            xObj.splitLine = splitLine;
            xObj.axisLine = {
                show: true
            }
            xObj.axisTick = {
                show: true
            }
            splitLine.show = false;
            xObj.nameTextStyle = nameTextStyle;
            nameTextStyle.lineHeight = 16;
            xAxis.push(xObj);

            yObj.type = "category";
            yObj.data = dataxAxis;
            yObj.inverse = true;
            yObj.axisTick = axisTick;
            axisTick.show = true;
            yAxis.push(yObj);

            position = "right";
            normalObj.show = true;
            normalObj.position = position;
            var textObj = {};
            textObj.color = "black";
            normalObj.textStyle = textObj;
            labelObj.normal = normalObj;
            var option = {
                title: {
                    show: false,
                },
                tooltip: {

                    trigger: 'axis',
                    confine: true,
                    axisPointer: {
                        type: 'none',
                        showContent: true,
                    },
                    textStyle: { fontSize: 12, fontWeight: 500 },
                    formatter(params) {
                        // 只展示柱子对应的内容，把顶部底部的 tooltip 过滤掉
                        return params.reduce((pre, i) => {
                            if (i.componentSubType === "line") {
                                i.marker = i.marker.replace(/\[object Object\]/, i.color);
                                i.value = `<span style="flex: 1; text-align: right; margin-left: 16px;">${i.value}</span>`;
                                const current = `<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.axisValue} ${i.value}</div>`;
                                return `${pre}${current}`;
                            }
                            if (i.componentSubType === 'bar') {
                                i.marker = i.marker.replace(/\[object Object\]/, i.color.colorStops[1].color);
                                i.value = `<span style="flex: 1; text-align: right; margin-left: 16px;">${i.value}</span>`;
                                const current = `<div style="display: flex; align-items: center; height: 26px;">${i.marker}${i.axisValue} ${i.value}</div>`;
                                return `${pre}${current}`;
                            }
                            return pre;
                        }, '');
                    },
                },
                grid: { // 控制图的大小，调整下面这些值就可以，
                    left: '2%',
                    right: '10%',
                    bottom: '10%',
                    top: '10%',
                    containLabel: true
                },
                animation: false,
                xAxis: xAxis,
                yAxis: yAxis,

                series: [{
                        // name: incomeType,
                        type: 'bar',
                        barWidth: 25,
                        barCategoryGap: 12,
                        z: 12,
                        data: datayAxis,
                        itemStyle: {　　　　　　　　　 // 柱体渐变色
                            color: function(params) {
                                var colorList = [
                                    ['#336DD2', '#6094EC'],
                                    ['#49C9C9', '#74E7E7'],
                                    ['#9BB4C4', '#B6CFE0'],
                                    ['#398FF1', '#7CC1F5'],
                                ]
                                var index = params.dataIndex;
                                if (params.dataIndex >= colorList.length) {
                                    index = params.dataIndex - colorList.length;
                                }
                                return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                    { offset: 0, color: colorList[index][0] },
                                    { offset: 1, color: colorList[index][1] }
                                ])
                            },

                        },
                    },
                    {
                        // name: incomeType,
                        type: 'pictorialBar',
                        symbolSize: [12, 25],
                        // 这个属性很重要，直接决定了顶部跟柱子是否契合
                        // symbolOffset: [8, 0],
                        z: 13,
                        itemStyle: {

                            normal: {
                                label: {
                                    show: true,
                                    position: ['50%', '40%'], //在上方显示
                                    formatter: function(p) {
                                        return formatCurrency.formateNum(p.value.toString(),2);
                                    }
                                },
                                color: function(params) {
                                    var colorList = ['#6094EC', '#74E7E7', '#B6CFE0', '#7CC1F5'];
                                    // console.log(params)
                                    var index = params.dataIndex;
                                    return params.color = colorList[index]
                                }
                            }
                        },
                        // symbolPosition: 'end',
                        // 要给成武汉这两个柱子的值，这是圆柱顶部，值不给对高度会对不上
                        data: this.pictorialBar0(datayAxis),
                    },
                ]
            };
			var myChart = echarts.getInstanceByDom(document.getElementById(id));
			if (myChart == undefined) {
				myChart = echarts.init(document.getElementById(id));
			}
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        },
        pictorialBar0: function(data) {
            var arr = [];
            data.forEach(function(ele, i) {
                if (ele < 0) {
                    arr[i] = {
                        value: ele,
                        symbolOffset: [-8, 0],
                        symbolPosition: 'end'
                    }
                } else {
                    arr[i] = {
                        value: ele,
                        symbolOffset: [8, 0],
                        symbolPosition: 'end'
                    }
                }
            });
            return arr
        }
    }
})
</script>
<style scoped>
</style>

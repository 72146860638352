<template>
        <div :id="id"></div>
</template>
<script>
import * as echarts from 'echarts/lib/echarts.js'
import formatCurrency from '../../assets/js/numberToCurrency'

export default ({
    props: [
        'chartData','id'
    ],
    data() {
        return {
            radarStyle: {
                fontSize: 18,
                radius: "60%",
                lineHeight: 34,
                nameGap: 10
            }
        }
    },
    mounted(){
        
        if(this._isMobile()){
            // 移动端
            this.radarStyle = {
                fontSize: 12,
                radius: "50%",
                lineHeight: 24,
                nameGap: 4
            }
            this.initChart();
        } else {
            // pc端
            this.radarStyle = {
                fontSize: 18,
                radius: "60%",
                lineHeight: 34,
                nameGap: 10
            }
            this.initChart();
        }
    },
    methods: {
        _isMobile: function() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        initChart: function(){
            this.chart = echarts.init(document.getElementById(this.id));
            this.setOptions(this.id, this.chartData, this.radarStyle)
        },
        setOptions: function(id, data, style){
            var ValuationResultChart = {
                xdata: data.years,
                bottomtitle: [],
                color: [],
                percentageleft: '',
                data: [{
                        data: data.maxValues,
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: '#b4d4ff',
                            origin: 'start',
                            opacity: 0.5
                        },
                        lineStyle: {
                            color: '#4393FFFF'
                        },
                        itemStyle: {
                            normal: {
                                color: ['#EFCD10FF']
                            }
                        },
                        label: {
                            normal: {
                                show: true,
                                position: 'top',
                                textStyle: {
                                    color: '#000'
                                },
                                formatter: function(p) {
                                    return formatCurrency.formateNum(p.value.toString(),2);
                                }
                            }
                        }
                    },
                    {
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        data: data.averageValues,
                        lineStyle: {
                            color: '#4393FFFF'
                        }
                    },
                    {
                        data: data.minValues,
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: '#fff',
                            origin: 'start',
                            shadowColor: '#fff',
                            shadowOffsetX: 1
                        },
                        lineStyle: {
                            color: '#4393FFFF'
                        },
                        label: {
                            normal: {
                                show: true,
                                position: 'bottom',
                                textStyle: {
                                    color: '#000'
                                },
                                formatter: function(p) {
                                    return formatCurrency.formateNum(p.value.toString(),2);
                                }
                            }
                        }
                    },
                ]
            };
			var polyline = echarts.getInstanceByDom(document.getElementById(id));
			if (polyline == undefined) {
				polyline = echarts.init(document.getElementById(id));
			}
            var option = {
                color: ValuationResultChart.color,
                legend: {
                    bottom: 10,
                    left: 'center',
                    data: ValuationResultChart.bottomtitle,
                    icon: 'roundRect',
                    itemWidth: 25,
                    itemHeight: 10, // 粗细
                },
                grid: {
                    left: '15%'
                },
                animation: false,
                xAxis: {
                    type: 'category',
                    data: ValuationResultChart.xdata,
                    axisTick: {
                        show: false
                    }
                    // boundaryGap: false,
                },
                yAxis: {
                    type: 'value',
                    name: ValuationResultChart.percentageleft,
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: true
                    },
                    axisTick: {
                        show: true
                    }
                },
                series: ValuationResultChart.data
            };
            polyline.setOption(option);
        }
    }
})
</script>
<style scoped>
    #financialAnalysisPic,
    #flowDiagnosePic {
        width: 560px;
        height: 400px;
        margin: 60px auto;
    }
    @media screen and (max-width: 765px) {
        #financialAnalysisPic,
        #flowDiagnosePic {
            width: 100%;
            height: auto;
            margin: 60px auto;
        }
        .financialAnalysis,
        .flowDiagnose {
            float: inherit;
            margin: 0 0 20px 0;
        }
    }
</style>

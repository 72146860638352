<template>
	<div>
		<flowFooter />
		<router-view></router-view>
	</div>
</template>

<script>
	import flowFooter from '../common/footer/flow_footer.vue';
	export default {
		components: {
			flowFooter
		}
	}
</script>

<style scoped>

</style>

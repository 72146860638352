<template>
	<div id="">
		
	</div>
</template>

<script>
	export default {
	  beforeRouteEnter(to, from, next) {
	    next(vm => {
			console.log(to,from)
	      vm.$router.replace({
			  path:from.path,
			  query:to.query
		  })
	    })
	  }
	}
</script>

<style>
</style>
